<template>
    <CenteredContainer>
        <div v-if="pairingMethod !== null">
            <img src="@/assets/skytab-logo.svg" alt="SkyTab Logo" class="logo" />
            <h3><SvgIcon name="terminal" class="icon" width="1.5em" height="1.5em" /> Terminal Details</h3>
            <br />
            <p >
                <strong>Pairing Type:</strong> <span class="text-capitalize">{{ titleCasedPairingMethod }}</span>
            </p>
            <p v-if="terminalDetails?.locationName" >
                <strong>Location:</strong> {{ terminalDetails.locationName }}
            </p>
            <p v-if="terminalDetails?.name" >
                <strong>Name:</strong> {{ terminalDetails.name }}
            </p>
            <p v-if="previewMid" >
                <strong>MID:</strong> {{ previewMid }}
            </p>

            <button v-if="pairingMethod !== 'serial'" class="btn btn-lg btn-danger" @click="confirmUnpair"
                :disabled="loading">
                <span v-if="loading">Unpairing...</span>
                <span v-else>
                    <SvgIcon name="terminal" width="1.5rem" height="1.5rem" class="icon" />Unpair Terminal
                </span>
            </button>
            <button class="btn btn-lg btn-outline-primary" @click="closeAndReturnToStart" :disabled="realodingPage">
                <SvgIcon name="no" width="2rem" height="2rem" class="icon" /> Close
            </button>
        </div>
        <div v-else>
            <img src="@/assets/skytab-logo.svg" alt="SkyTab Logo" class="logo" />
            <h2 class="text-center">
                <SvgIcon name="terminal" class="icon" width="1.5em" height="1.5em" /> Pair Terminal
            </h2>
            <br />
            <div class="pairing-layout">
                <div v-if="!serial.startsWith('DEMO')" class="qr-container">
                    <div class="qr-instructions">
                        <img src="@/assets/qr-code-launch-control.png" alt="QR Code" class="qr-code" />
                        <p>If you ordered a kiosk through Lighthouse, you need to complete the setup. Scan this code and finish the Set Up Kiosk module.</p>
                        <p><strong>Note:</strong> After completing the setup on Lighthouse, press the <strong class="text-primary">"Serial Number"</strong> button to link the terminal.</p>
                    </div>
                </div>
                <div class="button-container">
                    <template v-if="!showPinRegister && !showMidRegister">
                        <button class="btn btn-lg btn-outline-primary" @click="registerTerminalSerial" :disabled="loading">
                            <span v-if="!loading">Serial Number: {{ serial }}</span>
                            <span v-else>
                                <img src="@/assets/spinner-sm.png" alt="Loading" class="spinner-sm rotating" />
                            </span>
                        </button>
                        <button class="btn btn-lg btn-outline-primary" @click="manuallyRegisterPin" v-if="!loading">
                            PIN Code (Override)
                        </button>
                        <button class="btn btn-lg btn-outline-primary" @click="registerByMid" v-if="!loading">
                            Merchant ID (Demo Mode)
                        </button>
                    </template>
                    <template v-else-if="showMidRegister">
                        <div class="mid-input">
                            <input type="text" v-model="mid" placeholder="Enter MID" class="form-control" />
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <button class="btn btn-lg btn-outline-primary" @click="cancelMidRegister">
                                    Cancel
                                </button>
                            </div>
                            <div class="col-6">
                                <button class="btn btn-lg btn-primary" @click="registerTerminalMid" :disabled="!isMidValid">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="pin-inputs">
                            <input v-for="(digit, index) in pinDigits" :key="index" type="text" maxlength="1"
                                inputmode="numeric" pattern="[0-9]*" v-model="pinDigits[index]"
                                @input="onPinInput(index, $event)" @keydown="onPinKeydown(index, $event)"
                                @paste="onPinPaste($event)" ref="pinInput" class="pin-input" aria-label="PIN Digit" />
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <button class="btn btn-lg btn-outline-primary" @click="cancelPinRegister">
                                    Cancel
                                </button>
                            </div>
                            <div class="col-6">
                                <button class="btn btn-lg btn-primary" @click="registerTerminalPin" :disabled="!isPinValid">
                                    Link
                                </button>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="version-number">{{ feVersion }}</div>
        <div class="kiosk-serial" v-if="serial">{{ serial }}</div>
    </CenteredContainer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CenteredContainer from "@/components/global/CenteredContainer.vue";

export default {
    name: "PairingView",
    components: {
        CenteredContainer,
    },
    emits: ['pairingComplete', 'close'],
    data() {
        return {
            loading: false,
            realodingPage: false,
            showPinRegister: false,
            pinDigits: Array(6).fill(''),
            showMidRegister: false,
            mid: '',
        };
    },
    computed: {
        ...mapGetters('terminal', ['serial', 'terminalPaired', 'terminalDetails', 'errorMessage', 'pairingMethod', 'previewMid']),
        ...mapGetters(["feVersion"]),
        isPinValid() {
            return this.pinDigits.every(digit => /^\d$/.test(digit));
        },
        titleCasedPairingMethod() {
            return this.pairingMethod?.replace(/\b\w/g, char => char.toUpperCase());
        },
        isMidValid() {
            return this.mid.trim().length > 0;
        },
    },
    watch: {
        errorMessage(newVal) {
            if (newVal) {
                this.$store.dispatch("dialog/openDialog", {
                    title: "Error",
                    message: newVal,
                    showCancel: false,
                    onConfirm: async () => { }
                });
            }
        },
        showPinRegister(newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    if (this.$refs.pinInput && this.$refs.pinInput[0]) {
                        this.$refs.pinInput[0].focus();
                    }
                });
            }
        },
    },
    methods: {
        ...mapActions('terminal', ['registerTerminalSerial', 'deregisterTerminal']),
        onPinKeydown(index, event) {
            if (event.key === 'Backspace') {
                event.preventDefault();
                this.pinDigits[index] = '';
                if (index > 0) {
                    this.$nextTick(() => {
                        this.$refs.pinInput[index - 1].focus();
                    });
                }
            }
        },
        onPinInput(index, event) {
            const value = event.target.value.replace(/\D/g, '');
            this.pinDigits[index] = value.slice(-1);

            if (value && index < 5) {
                this.$nextTick(() => {
                    this.$refs.pinInput[index + 1].focus();
                });
            }

            if (this.pinDigits.every(digit => /^\d$/.test(digit))) {
                this.registerTerminalPin();
            }
        },
        onPinPaste(event) {
            event.preventDefault();
            const pastedText = event.clipboardData.getData('text');
            const digits = pastedText.replace(/\D/g, '').slice(0, 6).split('');

            digits.forEach((digit, i) => {
                this.pinDigits[i] = digit;
            });

            const nextEmptyIndex = this.pinDigits.findIndex(d => d === '');
            if (nextEmptyIndex !== -1) {
                this.$nextTick(() => {
                    this.$refs.pinInput[nextEmptyIndex].focus();
                });
            } else {
                this.$nextTick(() => {
                    this.$refs.pinInput[5].blur();
                });
            }
        },
        async registerTerminalPin() {
            this.loading = true;
            try {
                const pinCode = this.pinDigits.join('');
                await this.$store.dispatch('terminal/registerTerminal', { pin: pinCode });
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
                this.pinDigits = Array(6).fill('');
            }
        },
        async registerTerminalSerial() {
            this.loading = true;
            try {
                await this.$store.dispatch('terminal/registerTerminalSerial');
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },
        manuallyRegisterPin() {
            this.showPinRegister = true;
        },
        async confirmUnpair() {
            let message = "Are you sure you want to unpair the terminal? This can not be undone.";
            if (this.pairingMethod === 'pin') {
                // they will need to get a new pin from support to re-pair this terminal
                message += " You will need to get a new PIN from support to re-pair this terminal.";
            }

            this.$store.dispatch("dialog/openDialog", {
                title: "Unlink?",
                message,
                showCancel: true,
                onConfirm: async () => {
                    this.loading = true;
                    try {
                        await this.deregisterTerminal();
                    } catch (error) {
                        console.error(error);
                    } finally {
                        this.loading = false;
                    }
                }
            });
        },
        closeAndReturnToStart() {
            this.realodingPage = true;
            this.$emit('close');
        },
        cancelPinRegister() {
            this.showPinRegister = false;
            this.pinDigits = Array(6).fill('');
        },
        registerByMid() {
            this.showMidRegister = true;
        },
        cancelMidRegister() {
            this.showMidRegister = false;
            this.mid = '';
        },
        async registerTerminalMid() {
            this.loading = true;
            try {
                // Dispatch the action with the MID
                const response = await this.$store.dispatch('terminal/registerTerminalByMid', this.mid);
                if (response.success) {
                    this.$emit('close');
                } else {
                    this.$store.dispatch("dialog/openDialog", {
                        title: "Error",
                        message: response.error,
                        showCancel: false,
                        onConfirm: async () => { }
                    });
                }

            } catch (error) {
                console.error(error);
                this.$store.dispatch("dialog/openDialog", {
                    title: "Error",
                    message: error.message,
                    showCancel: false,
                    onConfirm: async () => { }
                });
            } finally {
                this.loading = false;
                this.mid = '';
            }
        },
    },
};
</script>

<style scoped>
.logo {
    display: block;
    margin: 0 auto 2rem;
    width: 10rem;
    height: 10rem;
}

.pairing-details {
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.card-text {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.btn {
    padding: 1rem 2rem;
    font-size: 1.5rem;
    margin-top: 1rem;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn:hover:not(:disabled) {
    transform: translateY(-2px);
}

.btn:disabled {
    background-color: #ccc !important;
    cursor: not-allowed;
}

.icon {
    margin-right: 0.75rem;
    position: relative;
    top: -0.25rem;
}

.text-center {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.pin-inputs {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.pin-input {
    width: 5rem;
    height: 5rem;
    text-align: center;
    margin: 0 0.5rem;
    font-size: 2rem;
    border: 2px solid #ccc;
    border-radius: 8px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.pin-input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.mid-input {
    margin-bottom: 1rem;
}

.version-number {
    font-size: 1.2rem;
    color: #666;
    text-align: center;
    margin-top: 2rem;
}

.kiosk-serial {
    font-size: 1.2rem;
    color: #333;
    text-align: center;
    margin-top: 1rem;
}

.qr-container {
    border: 2px solid #ccc;
    border-radius: 8px;
    padding: 1.5rem;
    background-color: #f9f9f9;
    flex: 1;
    margin-right: 1rem;
    text-align: center;
}

.qr-instructions {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 1rem;
}

.qr-code {
    display: block;
    margin: 0 auto;
    width: 10rem;
    height: 10rem;
}

.pairing-layout {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 auto;
    max-width: 800px;
}

.button-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.btn {
    margin-top: 1rem;
}
</style>
<template>
    <div class="location-picker" @click.stop="toggleDropdown">
        <div class="picker-label">
            <div class="location-info">
                <div class="location-name">{{ activeLocation.name }}</div>
                <div class="location-id">{{ activeLocation.merchantId }}</div>
            </div>
            <button class="location-dropdown" aria-haspopup="listbox" aria-expanded="dropdownOpen">
                <SvgIcon name="down" />
            </button>
        </div>
        <ul v-if="dropdownOpen" class="location-list" role="listbox" @click.stop>
            <li v-if="locations.length > 5" class="search-container">
                <input
                    type="text"
                    v-model="searchQuery"
                    placeholder="Search locations..."
                    class="location-search"
                    ref="searchInput"
                />
                <SvgIcon name="search" class="search-icon" width="1.5rem" height="1.5rem" />
            </li>
            <li
                v-for="location in filteredLocations"
                :key="location.id"
                @click="selectLocation(location)"
                :class="{ selected: location.id === activeLocation.id }"
                role="option"
                :aria-selected="location.id === activeLocation.id"
            >
                {{ location.name }} ({{ location.merchantId }})
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'LocationPicker',
    props: {
        locations: {
            type: Array,
            required: true
        },
        activeLocation: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            dropdownOpen: false,
            searchQuery: ''
        };
    },
    computed: {
        filteredLocations() {
            if (this.searchQuery) {
                const query = this.searchQuery.toLowerCase();
                return this.locations.filter(location =>
                    location.name.toLowerCase().includes(query) ||
                    location.merchantId.toLowerCase().includes(query)
                );
            }
            return this.locations;
        }
    },
    methods: {
        toggleDropdown() {
            this.dropdownOpen = !this.dropdownOpen;
            if (this.dropdownOpen) {
                document.addEventListener('click', this.handleClickOutside);
                this.$nextTick(() => {
                    if (this.locations.length > 5) {
                        this.$refs.searchInput.focus();
                    }
                });
            } else {
                this.searchQuery = '';
                document.removeEventListener('click', this.handleClickOutside);
            }
        },
        selectLocation(location) {
            this.$emit('select-location', location.id);
            this.dropdownOpen = false;
            this.searchQuery = '';
            document.removeEventListener('click', this.handleClickOutside);
        },
        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.dropdownOpen = false;
                document.removeEventListener('click', this.handleClickOutside);
            }
        }
    },
    unmounted() {
        document.removeEventListener('click', this.handleClickOutside);
    }
};
</script>

<style scoped>
.location-picker {
    position: relative;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    background: #fff;
    cursor: pointer;
    border-bottom: 0.125rem solid #d6d6d6;
}

.picker-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.location-info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.location-name {
    line-height: 2rem;
    font-size: 1.2rem;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 12.5rem;
}

.location-id {
    color: #666;
    font-size: 1rem;
}

.location-dropdown {
    border: none;
    background: transparent;
    margin-left: 0.5rem;
    cursor: pointer;
    color: #333;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s;
}

.location-dropdown[aria-expanded="true"] {
    transform: rotate(180deg);
}

.location-list {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    background: #fff;
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 1000;
    border: 0.125rem solid #d6d6d6;
    border-radius: 0.25rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.location-list li {
    padding: 1rem 2rem;
    transition: background-color 0.3s;
}

.location-list li.selected {
    background-color: #f0f0f0;
}

.location-list li:hover {
    background-color: #f0f0f0;
}

.search-container {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 !important;
    border-bottom: 0.125rem solid #d6d6d6;
}

.search-icon {
    position: absolute;
    left: 0.5rem;
    color: #666;
    pointer-events: none;
}

.location-search {
    flex-grow: 1;
    padding: 0.5rem 0.5rem 0.5rem 2.5rem;
    border: none;
    outline: none;
    font-size: 1rem;
    color: #333;
}
</style>

<template>
    <div v-if="initialized">
        <div v-if="loading" class="loading-overlay">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div v-if="activeCount" class="active-users-banner">
            <span class="count">System in use: {{ activeCount }}</span> {{ activeCount === 1 ? 'user is' : 'users are'
            }}
            placing an order
        </div>
        <div v-if="isShift4User && activeLocation" class="red-banner">
            <strong>{{ activeLocation?.name}} [{{ activeLocation?.merchantId }}]</strong>
        </div>
        <header v-else class="navbar navbar-expand-lg navbar-dark bg-black sticky">
            <div class="container-fluid">
                <!-- Hamburger toggle always on the left -->
                <button class="navbar-toggler" type="button" @click="toggleSidebar">
                    <SvgIcon name="hamburger" width="2.5rem" height="2.5rem" />
                </button>
                <a class="navbar-brand" href="#">{{ config?.locationName }}</a>
                <div class="d-flex">
                    <button>
                        <SvgIcon name="user" width="2.5rem" height="2.5rem" />
                    </button>
                </div>
            </div>
        </header>
        <div class="container-fluid p-0 d-flex">
            <nav class="sidebar" :class="{ 'show': showSidebar }">
                <div class="sidebar-sticky sticky">
                    <LocationPicker v-if="activeLocation" :locations="locations" :active-location="activeLocation"
                        @select-location="handleSelectLocation" />
                    <ul class="nav flex-column">
                        <li class="nav-item" v-for="(item, index) in filteredNavItems" :key="item.key">
                            <a class="nav-link" :class="{ active: currentSection === index }"
                                @click="selectSection(index)">
                                <SvgIcon v-if="item.icon" :name="item.icon" width="1.5rem" /> {{ item.name }}
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" @click="logout">
                                <SvgIcon name="navLogout" width="1.5rem" /> Logout
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
            <main class="content bg-light flex-fill">
                <component :is="currentComponent" />
            </main>
        </div>
    </div>
</template>

<script>
import DashboardComponent from './admin/DashboardComponent.vue';
import SettingsComponent from './admin/SettingsComponent.vue';
import OrderTypeComponent from './admin/OrderTypeComponent.vue';
import CouponsComponent from './admin/CouponsComponent.vue';
import PaymentsComponent from './admin/PaymentsComponent.vue';
import LocationPicker from '@/components/features/admin/LocationPicker.vue';
import TerminalsComponent from './admin/TerminalsComponent.vue';
import GuidesComponent from './admin/GuidesComponent.vue';
import { mapState, mapGetters } from 'vuex';
import { markRaw } from 'vue';

export default {
    name: 'AdminView',
    computed: {
        ...mapState("admin", ['activeCount', 'isLoggedIn', 'config', 'locations', 'activeLocation', 'initialized', 'accessList']),
        ...mapGetters("admin", ['isAdmin', 'activeLocation']),
        filteredNavItems() {
            let items = this.navItems;
            if (this.config?.rails === 'stm') {
                items = items.filter(item => item.key !== 'coupons');
            }

            if (!this.accessList.includes('all')) {
                items = items.filter(item => this.accessList.includes(item.accessKey));
            }

            return items;
        },
        isShift4User() {
            return this.$store.state.admin.user.email.includes('@shift4.com');
        }
    },
    data() {
        return {
            title: 'Dashboard',
            navItems: [
                { key: 'dashboard', name: 'Dashboard', icon: 'navDashboard', accessKey: 'all' },
                { key: 'settings', name: 'General Settings', icon: 'navSettings', accessKey: 'all' },
                { key: 'orderFlows', name: 'Order Flows', icon: 'navOrderFlows', accessKey: 'all' },
                { key: 'terminals', name: 'Terminals', icon: 'terminal', accessKey: 'all' },
                { key: 'payments', name: 'Tip Configuration', icon: 'navPayments', accessKey: 'all' },
                { key: 'coupons', name: 'Coupons', icon: 'coupon', accessKey: 'coupons' },
            ],
            currentSection: 0,
            currentComponent: markRaw(SettingsComponent),
            showSidebar: false,
            loading: false
        };
    },
    components: {
        LocationPicker,
        DashboardComponent,
        SettingsComponent,
        OrderTypeComponent,
        CouponsComponent,
        PaymentsComponent,
        TerminalsComponent,
        GuidesComponent
    },
    mounted() {
    
        //this.$store.dispatch('admin/fetchLocations');
        this.loadSavedLocation();
        document.title = `Admin - ${this.filteredNavItems[this.currentSection]?.name || 'Dashboard'}`;
        this.currentComponent = markRaw(this.getComponent(this.filteredNavItems[this.currentSection]?.name));
        this.selectDefaultSection();
    },
    methods: {
        handleSelectLocation(location) {
            this.loading = true; // Start loading
            localStorage.setItem('activeLocation', JSON.stringify(location));
            this.$store.dispatch('admin/setActiveLocation', location)
                .finally(() => {
                    this.loading = false; // End loading
                });
        },
        selectSection(index) {
            if (this.loading) return;
            this.title = this.filteredNavItems[index].name;
            this.$store.dispatch('admin/setSelectedTab', this.filteredNavItems[index].key);
            this.currentSection = index;
            localStorage.setItem('currentSection', index);
            this.showSidebar = false;
            const components = {
                'Dashboard': DashboardComponent,
                'Payments and Tips': PaymentsComponent,
                'General Settings': SettingsComponent,
                'Order Flows': OrderTypeComponent,
                'Terminals': TerminalsComponent,
                'Coupons': CouponsComponent,
                'Tip Configuration': PaymentsComponent
            };
            this.currentComponent = markRaw(components[this.filteredNavItems[index].name]);
        },
        getComponent(title) {
            switch (title) {
                case 'Dashboard':
                    return DashboardComponent;
                case 'Payments and Tips':
                    return PaymentsComponent;
                case 'General Settings':
                    return SettingsComponent;
                case 'Order Flows':
                    return OrderTypeComponent;
                case 'Coupons':
                    return CouponsComponent;
                case 'Terminals':
                    return TerminalsComponent;
                case 'Tip Configuration':
                    return PaymentsComponent;
                default:
                    return SettingsComponent;
            }
        },
        logout() {
            const currentRoute = this.$route.fullPath;
            this.$store.dispatch('admin/logout');
            this.$router.push({ 
                name: 'admin-login', 
                query: { redirect: currentRoute } 
            });
        },
        toggleSidebar() {
            this.showSidebar = !this.showSidebar;
        },
        async loadSavedLocation() {
            const savedLocation = localStorage.getItem('activeLocation');
            
            if (savedLocation && !this.activeLocation) {
                this.$store.dispatch('admin/setActiveLocation', JSON.parse(savedLocation));
            } else {
                console.log("No saved location found, fetching locations");
                await this.$store.dispatch('admin/getLocations');
                this.$store.dispatch('admin/setActiveLocation', this.locations[0]);
            }
        },
        selectDefaultSection() {
            const savedSection = parseInt(localStorage.getItem('currentSection')) || 0;
            if (this.filteredNavItems[savedSection]) {
                this.selectSection(savedSection);
            } else if (this.filteredNavItems.length > 0) {
                this.selectSection(0);
            }
        },
    },
    watch: {
        title(newTitle) {
            document.title = `Admin - ${newTitle}`;
        }
    },
};
</script>
<style scoped>
.container-fluid.p-0.d-flex {
    height: 96.5vh;
    overflow: hidden;
}


.sidebar {
    height: 100vh;
    overflow-y: auto;
}

.content {
    height: 100vh;
    overflow-y: auto;
}

.active-users-banner {
    position: fixed;
    width: 110vw;
    background-color: var(--primary-color);
    box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.5);
    color: white;
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    z-index: 9999;
}

.content {
    padding: 2rem;
    background-color: #fff !important;
}

.navbar {
    align-items: center;
    justify-content: flex-start;
    position: sticky !important;
    top: 0;
    z-index: 1020;
}

.navbar-toggler {
    /* Ensures some spacing between the toggler and the logo */
    display: block !important;
    /* Ensures it's always visible */
}

.navbar-brand {
    flex-grow: 1;
    /* Allows the logo to take necessary space while aligning left */
    margin-left: 0;
    /* Removes any automatic margin */
    height: 1.5rem;
    width: 7.1875rem;
    background-image: url('@/assets/s4-dark-bg.svg');
    background-repeat: no-repeat;
}

.navbar button {
    background-color: transparent;
    /* Default state */
    border: none;
    /* Remove borders */
    border-radius: 50%;
    /* Full rounded corners */
    margin: none;
    padding: none;
    width: auto;
}

/* Ensure visibility and aesthetics */
.navbar button svg {
    fill: white;
    /* Change SVG color if needed */
}

/* Ensure these styles have higher specificity than Bootstrap's defaults */
.navbar .navbar button {
    padding: 0.25rem 0.5rem;
    /* Adjust padding as needed */
}

@media (max-width: 767px) {
    .navbar-brand {
        width: 1.5rem;
        overflow: hidden;
    }
}

.sidebar {
    border-right: 0.125rem solid rgba(0, 0, 0, 0.12);
    min-width: 300px;
}

ul.nav {
    margin-top: 2rem;
}

.nav-item {
    margin: 0 1rem;
}

.nav-item a {
    cursor: pointer;
    color: black;
    font-weight: 700;
    font-size: 1.2rem;
    border-radius: 20rem;
    width: 15rem;
    padding: 1rem;
    align-items: center;
    margin: 0rem 0rem 0.5rem 1rem;
    color: #333;
    line-height: 1.428573em;
}

.nav-item a.active {
    background-color: rgba(14, 91, 243, 0.08);
    color: var(--primary-color);
}

.sidebar.show {
    background-color: white;
    transform: translateX(0);
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}



/* Adjustments for desktop */
@media (min-width: 768px) {
    .sidebar {
        transform: translateX(0);
    }
}



/* Adjustments for mobile */
@media (max-width: 767px) {
    .sidebar {
        position: fixed;
        width: 100vw;
        transform: translateX(-100%);
        z-index: 8000;
    }

    .hamburger-btn {
        display: block;
        position: absolute;
        top: 10px;
        left: 10px;
    }
}

.red-banner {
    background-color: red;
    color: white;
    text-align: center;
    padding: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
}
</style>

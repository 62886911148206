<template>
  <transition name="fade" key="1">
    <div class="modal-overlay" @click="close" v-if="item" role="dialog" aria-labelledby="modal-header"
      aria-modal="true">
      <div class="modal-window" :style="{ width: modalWindowWidth, height: modalWindowHeight }" @click.stop>
        <div class="left-col" ref="leftCol" @scroll="handleScroll">
          <button class="close-button" @click="close" v-html="svgLib.close" aria-label="Close modal"></button>
          <div class="modal-header" :class="{ 'scrolled-class': isScrolled }" id="modal-header">
            <h4>{{ item.name }}</h4>
          </div>
          <div class="item-info">
            <p>{{ item.description }}</p>
            <quantity-selector v-if="showQuantity" :value="quantity" @quantity="updateQuantity" />
          </div>
          <!--<TabNavigation v-if="item?.modifierSets?.length > 2" :tabs="modifierSetTabs"
            :selectedTabRef="selectedModSetRef" :unmetRequirements="unmetRequirements"
            @tab-selected="handleModSetSelected" />-->
          <div class="modifier-sets" v-if="item.modifierSets.length">
            <ModifierSet v-for="(modSet, index) in item.modifierSets" :index="index" :key="modSet.ref"
              class="animate__animated" :ref="`modSet-${modSet.ref}`" :modifierSet="modSet"
              :selectedGraph="selectedModifiers(modSet.ref)" />
          </div>
          <SpecialRequest :visible="showSpecialRequest" :initialSpecialRequest="specialRequest"
            @update:specialRequest="updateSpecialRequest"
            :specialRequestInstructions="$t('specialRequestInstructions')" />
          <ItemControls :subtotal="subtotal" :item="item" :disabled="!valid" :isEdit="isEdit" @addToOrder="order"
            @updateItem="order" />
        </div>
        <div v-if="item.images.length" class="right-col"
          :style="{ backgroundImage: 'url(' + imageUrl(item.images[0]) + ')' }"></div>
      </div>
    </div>
  </transition>
</template>


<script>
import QuantitySelector from "@/components/features/itemOrder/QuantitySelector.vue";
import ItemControls from "@/components/features/itemOrder/ItemControls.vue";
// import TabNavigation from "@/components/global/TabNavigation.vue";
import ModifierSet from "@/components/features/itemOrder/ModifierSet.vue";
import SpecialRequest from "@/components/features/itemOrder/SpecialRequest.vue";
import { mapGetters } from "vuex";
import { imageUrl } from "@/utils/helpers";
import svgLib from "@/utils/svgLib";
import 'animate.css';
export default {
  data() {
    return {
      svgLib,
      isScrolled: false,
      highlightedModSetRef: null,
      selectedModSetRef: null,
    };
  },
  components: {
    QuantitySelector,
    ItemControls,
    //TabNavigation, 
    ModifierSet,
    SpecialRequest
  },
  emits: ["close"],
  props: {
    showQuantity: {
      type: Boolean,
      default: false
    },
    showSpecialRequest: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    imageUrl,
    updateQuantity(newQuantity) {

      this.$store.dispatch("orderItem/updateQuantity", newQuantity);
    },
    close() {
      this.$store.dispatch("dialog/openDialog", {
        title: "Confirm Close",
        message: "Are you sure you want to close? You changes will not be saved.",
        showCancel: true,
        onConfirm: () => {
          this.$store.dispatch("orderItem/close");
          this.$emit("close");
        },
      }); 
    },
    order() {
      const unmetRequirementRef = this.findNextUnmetRequirement();
      if (unmetRequirementRef) {
        this.scrollToModSet(unmetRequirementRef.split('-')[1]);
        return;
      }

      if (this.isEdit) {
        this.$store.dispatch("orderItem/updateItem");
      } else {
        this.$store.dispatch("orderItem/order", {
          ...this.item,
        });
      }
    },
    handleEscapeKey(event) {
      if (event.key === "Escape") {
        this.close();
      }
    },
    selectedModifiers(modifierSetRef) {
      const ms = this.lineModifierSets.find(
        (lms) => lms.modifierSetRef === modifierSetRef
      );
      return ms ? ms.lineModifiers : [];
    },
    handleModSetSelected(modSetRef) {
      this.selectedModSetRef = modSetRef;
      this.selectedModSet = this.item.modifierSets.find(
        (ms) => ms.ref === modSetRef
      );
      this.scrollToModSet(modSetRef);
    },
    scrollToModSet(modSetRef) {
      this.$nextTick(() => {
        const modSetComponent = this.$refs[`modSet-${modSetRef}`];

        if (modSetComponent && modSetComponent[0]) {
          const modSetElement = modSetComponent[0].$el;
          modSetElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
            offset: -300,
          });
        }
      });
    },
    findNextUnmetRequirement() {
      for (let i = 0; i < this.item.modifierSets.length; i++) {
        const modSet = this.item.modifierSets[i];
        const selectedModifiers = this.selectedModifiers(modSet.ref);
        if (selectedModifiers.length < modSet.min) {
          return `modSet-${modSet.ref}`;
        }
      }
      return null;
    },
    handleScroll(event) {
      const scrollTopThreshold = 10;
      this.isScrolled = event.target.scrollTop > scrollTopThreshold;
    },
    selectFirstModSet() {
      if (this.item && this.item.modifierSets.length > 0) {
        this.selectedModSetRef = this.item.modifierSets[0].ref;
      }
    },
    updateSpecialRequest(newRequest) {
      console.log("newRequest", newRequest);
      if (this.item) {
        this.item.instructions = newRequest;
      }
    },
  },
  computed: {
    ...mapGetters(["screenOrientation"]),
    ...mapGetters("orderItem", [
      "item",
      "subtotal",
      "quantity",
      "specialRequest",
      "lineModifierSets",
      "valid",
      "editIndex"
    ]),
    unmetRequirements() {
      return this.item.modifierSets
        .filter(modSet => {
          const selectedModifiers = this.selectedModifiers(modSet.ref);
          return selectedModifiers.length < modSet.min;
        })
        .map(modSet => modSet.ref);
    },
    isEdit() {
      return this.editIndex !== null;
    },
    modifierSetTabs() {
      return this.item?.modifierSets.map(modSet => ({
        ref: modSet.ref,
        name: modSet.name,
      })) || [];
    },
    hasRequiredChoices() {
      return this.item.modifierSets.some(modSet => modSet.min > 0);
    },
    unmetRequirementsCount() {
      return this.item.modifierSets.reduce((count, modSet) => {
        const selectedModifiers = this.selectedModifiers(modSet.ref);
        return count + (selectedModifiers.length < modSet.min ? 1 : 0);
      }, 0);
    },
    hasUnmetRequirements() {
      return this.item.modifierSets.some(modSet => {
        const selectedModifiers = this.selectedModifiers(modSet.ref);
        return selectedModifiers.length < modSet.min;
      });
    },
    modalWindowWidth() {
      return this.item.images.length ? "80vw" : "38rem";
    },
    modalWindowHeight() {

      if (!this.item.modifierSets.length && !this.item.images.length) {
        return "auto";
      }
      return "90vh";
    },
  },
  mounted() {
    document.addEventListener("keyup", this.handleEscapeKey);
    this.selectFirstModSet();
  },
  beforeUnmount() {
    document.removeEventListener("keyup", this.handleEscapeKey);
  },
  watch: {
    item() {
      this.highlightedModSetRef = null;
      this.isScrolled = false;
    },
    'item.modifierSets': {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.selectFirstModSet();
        }
      },
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  z-index: 5001;
  backdrop-filter: blur(10px);
}

.modal-window {
  display: flex;
  align-items: stretch;
  height: 90vh;
  border-radius: var(--border-radius-xl);
}

.scrolled-class {
  box-shadow: var(--box-shadow) !important;
  border-bottom: 0.125rem solid var(--grey-outline);
}

.left-col {
  width: 38rem;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  height: 100% !important;
  padding-bottom: 10rem;
  background-color: var(--primary-white);
}

.left-col.dark-mode {
  background-color: var(--grey-1);
  color: var(--primary-black);
}

.item-info {
  padding: 0rem 2.125rem 2rem 2.125rem;
  margin-bottom: 0.6rem !important;
}

.item-info p {
  color: var(--grey-medium);
  font-weight: 100;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.item-info.dark-mode p {
  color: var(--grey-50);
}

.tab-nav-container {
  padding: 0 2.125rem;
  background: var(--primary-white);
  position: sticky;
  top: 5rem;
  font-size: 1rem !important;
}

.tab-nav-container.dark-mode {
  background: var(--grey-1);
}

.tab-nav-container .arrow {
  width: 4rem !important;
}

.modifier-sets {
  border-top: 0.125rem solid var(--grey-outline);
}

.modifier-sets.dark-mode {
  border-top: 0.125rem solid var(--grey-50);
}

.modifier-sets .modifier-set:last-child {
  border-bottom: none;
}

.required-choices-pill {
  position: sticky;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: red;
  color: var(--primary-white);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 5rem;
  width: 15rem;
  font-size: 1.2rem;
  font-weight: 700;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  cursor: pointer;
}

.count {
  background-color: var(--primary-white);
  color: red;
  padding: 0.25rem 0.5rem;
  border-radius: 5rem;
  font-size: 1rem;
  position: relative;
  top: -0.14rem;
  left: 0.5rem;
  width: 1.28rem;
  height: 1.28rem;
  font-weight: 700;
}

.right-col {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
}

.right-col.dark-mode {
  background-color: #2a2a2a;
}

.all-choices-met {
  background-color: green;
  color: var(--primary-white);
}

.checkmark-icon {
  width: 1em;
  height: 1em;
  margin-right: 0.5rem;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
}

.close-button.dark-mode {
  fill: var(--primary-black);
}

.unmet-requirements-pill {
  position: sticky;
  bottom: 1rem;
  margin: 0 20%;
  background-color: #e02020;
  color: var(--primary-white);
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  border-radius: 1.5rem;
  cursor: pointer;
  box-shadow: var(--box-shadow);
  z-index: 10;
}

.unmet-requirements-pill .pill-text {
  font-size: 1rem;
  font-weight: 600;
}

.unmet-requirements-pill .go-to-unmet {
  background: none;
  border: none;
  margin-left: 0.5rem;
  cursor: pointer;
}

.modifier-set.unmet-requirement {
  border: 1px solid #e02020;
  border-radius: var(--border-radius-md);
  padding: 1rem;
}

.modifier-set.unmet-requirement::before {
  content: 'Required';
  color: #e02020;
  font-weight: bold;
  position: absolute;
  top: 1rem;
  right: 1rem;
}
</style>

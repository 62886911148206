const lookupList = [
    {
        "name": "Jacks Beef Teryaki",
        "lookup": "0001708287635",
        "ref": "80ebda79-dad7-496f-9568-fdec98441d85"
    },
    {
        "name": "Lunchable Dunk",
        "lookup": "00044700006801",
        "ref": "db007090-bb7e-4a05-b26b-f2df6758882e"
    },
    {
        "name": "General Mills Cinnamon Toast Crunch Treat Bars",
        "lookup": "0016000508293",
        "ref": "fa61ae35-c41a-42e9-9dcd-f8bfcc51c082"
    },
    {
        "name": "Topo Chico",
        "lookup": "0021136180596",
        "ref": "39ab6da3-0827-4089-b5a3-e6181bb556d3"
    },
    {
        "name": "Sabra, Hummus",
        "lookup": "0040822011952",
        "ref": "682abd37-5787-40be-9abf-92299357c2f6"
    },
    {
        "name": "Sabra, Guac",
        "lookup": "0040822343893",
        "ref": "eb82126e-e277-4156-aacc-aa38827b55c0"
    },
    {
        "name": "Haribo, Bears",
        "lookup": "0042238302211",
        "ref": "4d76db03-e74b-4972-8236-dfac2cb34386"
    },
    {
        "name": "Soda, Mexican Coke",
        "lookup": "0049000004632",
        "ref": "60448bcf-ba2a-4a23-8b1b-aeb9bdc613a5"
    },
    {
        "name": "Ben's Original Ready Rice Cilantro Lime Flavored Rice",
        "lookup": "0054800423446",
        "ref": "8aaa4324-14e6-44c9-b75c-801e0ba3fb4f"
    },
    {
        "name": "Ben's Original Ready Rice Brown Basmati Rice",
        "lookup": "0054800423484",
        "ref": "cdf1d5ea-6a4f-4487-b9b5-ee1dae4a43dd"
    },
    {
        "name": "Candy, Sour Patch Kids",
        "lookup": "0070462098358",
        "ref": "293eae23-c41c-4af1-a56e-d7cc577847d4"
    },
    {
        "name": "Corn Nuts Crunchy Corn Kernels - Ranch",
        "lookup": "0071159000258",
        "ref": "96da3e54-940c-424c-bc08-a23de87579ba"
    },
    {
        "name": "Corn Nuts Crunchy Corn Kernels - Chile Picante Con Limon",
        "lookup": "0071159000807",
        "ref": "412159e6-882d-4a72-a945-eef69adc701f"
    },
    {
        "name": "Jimmy Dean Morning Combos - Mini Maple Pancakes & Turkey Sausage Bites",
        "lookup": "0077900001543",
        "ref": "5e91b1aa-7f38-49fb-93ed-52ddf317ea77"
    },
    {
        "name": "JD Meat Lovers",
        "lookup": "0077900650468",
        "ref": "6f40c115-edec-41c1-ab7f-d165b05a90d2"
    },
    {
        "name": "Squirt",
        "lookup": "0078000019520",
        "ref": "e245a695-6400-4378-996b-f38e4970e069"
    },
    {
        "name": "Bidgio Dumplings",
        "lookup": "0080717671432",
        "ref": "1a55a0d5-4b6c-419d-86f4-70061da18828"
    },
    {
        "name": "Kevins Paleo Meal",
        "lookup": "0081026402371",
        "ref": "bbd2fd46-f951-496b-9483-6d8a81d3738b"
    },
    {
        "name": "Simply Asia Kung poa",
        "lookup": "0085428500083",
        "ref": "6537447b-7c30-4fcb-9ff6-1b6d21986435"
    },
    {
        "name": "Simply Asia Sesame",
        "lookup": "00854285000862",
        "ref": "46f91338-66a6-492b-aa52-fa499ed7a1e8"
    },
    {
        "name": "Jarritos Mandarin Soda, 12.5 oz",
        "lookup": "0090478410012",
        "ref": "c35f160d-e160-402a-bb13-f6ae3c9d974a"
    },
    {
        "name": "Jarritos Pineapple Soda, 12.5 oz",
        "lookup": "0090478410043",
        "ref": "4405bc15-9d29-4eb0-80bd-762e392fae0b"
    },
    {
        "name": "Jarritos Fruit Punch Soda, 12.5 oz",
        "lookup": "0090478410074",
        "ref": "c7fe423e-e383-4039-a60b-b21894038a89"
    },
    {
        "name": "Old Spice Travel",
        "lookup": "012044016435",
        "ref": "400ea338-3939-492f-97f2-3a8da865dcfc"
    },
    {
        "name": "Stouffer Mac",
        "lookup": "013800103406",
        "ref": "29fabe8b-72b6-4036-b797-0448dd8c320d"
    },
    {
        "name": "Stouffer Steak Bowl",
        "lookup": "013800475626",
        "ref": "03cf0a8f-7573-4897-b7f3-769d7895f46c"
    },
    {
        "name": "Stouffer Chicken Bowl",
        "lookup": "013800938947",
        "ref": "24451d73-89fc-4287-91df-93e4abed2978"
    },
    {
        "name": "Bare Crunchy Fuji & Reds",
        "lookup": "013971010008",
        "ref": "e1a4479a-4772-4611-9067-c0245b4ffaf2"
    },
    {
        "name": "Cheddar Goldfish",
        "lookup": "014100085478",
        "ref": "62a716ca-a293-4d17-87ac-3dab328ae593"
    },
    {
        "name": "Jacks Beef Spicy Sweet",
        "lookup": "017082887412",
        "ref": "dbe0fad4-f460-4f03-a2b7-b49cabc82289"
    },
    {
        "name": "Velvetta Cup",
        "lookup": "021000010875",
        "ref": "528c6b80-b26e-4eea-90b7-1196363644b9"
    },
    {
        "name": "Abuelita",
        "lookup": "028000016579",
        "ref": "f29fcd48-60e0-4f45-9f41-4873400c38d9"
    },
    {
        "name": "Frito Scoops",
        "lookup": "028400314039",
        "ref": "da3aaad6-e8ac-4160-9270-31463e328035"
    },
    {
        "name": "Planters Cashews",
        "lookup": "029000016071",
        "ref": "34605094-d7a4-4b43-b0ff-c01468fddd72"
    },
    {
        "name": "Shin Black",
        "lookup": "031146014408",
        "ref": "84361d00-511b-4b59-8125-21d9175d3970"
    },
    {
        "name": "Tan Tan Men chili oil",
        "lookup": "031146061020",
        "ref": "8ab53e0b-54e3-4e9b-bfcd-beba3ce4a888"
    },
    {
        "name": "Scope Travel",
        "lookup": "037000736271",
        "ref": "3217032e-9ea7-4b82-994d-c178c00c1be1"
    },
    {
        "name": "Van Holten Dill Pickle",
        "lookup": "038200000063",
        "ref": "845cd415-a21e-4f61-9dce-f41ea43dfcbe"
    },
    {
        "name": "Sabra Red pepper",
        "lookup": "040822011549",
        "ref": "f1b7b4fa-a0ac-4fc9-86fb-6594bd8dc332"
    },
    {
        "name": "Wheat Thins",
        "lookup": "041220127825",
        "ref": "31ca5b5e-f5a5-42ce-a2fc-066a273598a7"
    },
    {
        "name": "Frito Spicy Bean",
        "lookup": "041220803996",
        "ref": "bda05e9e-9852-409b-a6f3-5455dc8bbe89"
    },
    {
        "name": "Amys Palak",
        "lookup": "042272000593",
        "ref": "4e553a5f-86c2-459d-9d11-5b3eadd4e506"
    },
    {
        "name": "Trisciuts",
        "lookup": "044000050986",
        "ref": "1e81d41f-c894-4255-90bb-60b0565bf778"
    },
    {
        "name": "Bens White",
        "lookup": "054800423330",
        "ref": "d278a931-78cd-4268-b481-dd3b92de3fd4"
    },
    {
        "name": "Bens Cilantro",
        "lookup": "054800423446",
        "ref": "23058bfe-c181-4064-8dd1-b7e20829e62b"
    },
    {
        "name": "Coconut & Cashew Butter",
        "lookup": "058449172017",
        "ref": "db09c173-b6fc-41fc-a482-2b3cea4887b5"
    },
    {
        "name": "Kind Dark Chocolate Nuts & Sea Salt Bars",
        "lookup": "0602652176517",
        "ref": "82920d99-b65d-4f42-97fe-98e02230af37"
    },
    {
        "name": "Kind Dark Chocolate Almond & Coconut Bars",
        "lookup": "0602652199776",
        "ref": "1bfadc06-dfa2-405d-a711-7230258bf532"
    },
    {
        "name": "Tai Pei Rice",
        "lookup": "070077811311",
        "ref": "6341af1b-7efa-4f5a-830e-68c0dd9492a0"
    },
    {
        "name": "Nissin Scorchin shrimp",
        "lookup": "070662095027",
        "ref": "62913ac8-ee91-486b-8baa-d070a807b686"
    },
    {
        "name": "Nissin CK",
        "lookup": "070662096314",
        "ref": "ae0d9e69-c37a-4b6b-bb2c-6da0a635c175"
    },
    {
        "name": "Zatarians Sausage Gumbo",
        "lookup": "071429002609",
        "ref": "84ea8991-7ee8-447a-b62f-4657510a8935"
    },
    {
        "name": "Juice, Natalies, Blood Orange",
        "lookup": "0725341191148",
        "ref": "5ca5366d-f9a8-4952-b289-7c3523cd441c"
    },
    {
        "name": "Juice, Natalies, Carrot Ginger",
        "lookup": "0725341421344",
        "ref": "a4c68ca8-a916-4d74-b5ce-18c46518f0dd"
    },
    {
        "name": "Juice, Natalies, Natural Lemonade",
        "lookup": "0725341555544",
        "ref": "5066f8e7-7053-4ab5-9f16-37bfabe45d26"
    },
    {
        "name": "Juice, Natalies, Guava Lemonade",
        "lookup": "0725341717140",
        "ref": "a84b97e2-0173-460d-b40c-25c8207441fc"
    },
    {
        "name": "Juice, Natalies, Strawberry Lemonade",
        "lookup": "0725341888840",
        "ref": "47204387-a230-4d46-aabb-2844bbdf6cce"
    },
    {
        "name": "B & J Cookie",
        "lookup": "076840100583",
        "ref": "4af99b20-7cbb-4d6b-bfd7-0d8255d04992"
    },
    {
        "name": "B & J Half Baked",
        "lookup": "076840101320",
        "ref": "52423188-8c63-4434-8211-1466b83fc922"
    },
    {
        "name": "JD Pancakes Maple",
        "lookup": "077900114960",
        "ref": "4efba523-660f-41fc-8204-008e1bac6bc0"
    },
    {
        "name": "Degree Travel",
        "lookup": "079400457400",
        "ref": "2a4c4391-08b7-4605-b7a0-faee311935ea"
    },
    {
        "name": "Starkist Lunch",
        "lookup": "08000495433",
        "ref": "b1c6ee81-1f3d-4ab3-b1da-4da10c89f1f0"
    },
    {
        "name": "Kosmic Kombucha, Salty Dog",
        "lookup": "0804879396604",
        "ref": "5d13d04d-8b73-458e-8665-b555cefd6cc7"
    },
    {
        "name": "Kosmic Kombucha, Purple Haze",
        "lookup": "0804879396642",
        "ref": "04b1a050-aa6f-40da-b983-542cde8b9296"
    },
    {
        "name": "Kosmic Kombucha, Groovy Green",
        "lookup": "0804879430544",
        "ref": "0d85a205-fe9f-44e2-aade-0c7796da233c"
    },
    {
        "name": "Kosmic Kombucha, Ms Pink",
        "lookup": "0804879450542",
        "ref": "cc22c389-a951-4ce0-b3a2-6d50afe9238e"
    },
    {
        "name": "Kosmic Kombucha, Cherry Dog",
        "lookup": "0804879487142",
        "ref": "869e7867-57bc-48de-bc98-2f61043ff4a3"
    },
    {
        "name": "Kosmic Kombucha, Local.Lemon.Love",
        "lookup": "0804879492436",
        "ref": "e08ec59c-351d-4684-bc11-9daa3286a614"
    },
    {
        "name": "Verb, Nut Butter",
        "lookup": "0850031387690",
        "ref": "eaa3d2a1-73b3-442c-b061-0585290469c8"
    },
    {
        "name": "Verb, Lemon",
        "lookup": "0850043221302",
        "ref": "d78978bd-151f-4899-810e-9410c36c4230"
    },
    {
        "name": "Verb, Cookie Dough",
        "lookup": "0850043221722",
        "ref": "40a2b088-802b-4a0f-bbf1-22aae10cb7de"
    },
    {
        "name": "Popcorn, Texas Butter",
        "lookup": "0850051254132",
        "ref": "6eaedf30-edca-4533-8158-b36c867594ee"
    },
    {
        "name": "Jongga Kimch",
        "lookup": "0852320000000",
        "ref": "3684974c-3788-4f04-8358-e7a60f4151a8"
    },
    {
        "name": "Tea, Sweet",
        "lookup": "0852750003035",
        "ref": "696968b3-07fc-4de6-9b26-9949d2a7c3c5"
    },
    {
        "name": "Plantain Chips",
        "lookup": "086700671252",
        "ref": "4aa09dcb-b6dc-47dc-8026-4dec441e9d04"
    },
    {
        "name": "Lacroix, Lemon",
        "lookup": "15021239",
        "ref": "c3845039-f647-4411-be5d-93848a81dc9b"
    },
    {
        "name": "Lacroix, Pure",
        "lookup": "15021242",
        "ref": "e88eba5d-ccca-4b59-8253-5c0759dfa407"
    },
    {
        "name": "Bumble Bee Snack on the Run Tuna Salad with Crackers",
        "lookup": "16669510",
        "ref": "a651ccab-3f67-4a78-bfe1-eb7944ff05f8"
    },
    {
        "name": "Jacks Beef Spicy Stick",
        "lookup": "17082884435",
        "ref": "4edad2b9-4b0a-47f0-8a31-abdfd5e8b187"
    },
    {
        "name": "Snack packs",
        "lookup": "27000419007",
        "ref": "82a976ed-11e0-45b1-8dec-3e7b309a8d8a"
    },
    {
        "name": "Stacy Pita",
        "lookup": "28400564632",
        "ref": "c4dd1b48-6c53-4e70-bba7-22045300f2d4"
    },
    {
        "name": "Planters Nuts",
        "lookup": "29000072121",
        "ref": "1105979e-9596-421a-88b6-69a1059c5556"
    },
    {
        "name": "Cracker jacks",
        "lookup": "30000029138",
        "ref": "61a8586a-d4ce-4a17-a7a1-dc837e58db7a"
    },
    {
        "name": "Pepto-To-go",
        "lookup": "301490000106",
        "ref": "a13bb96b-1bfb-4a95-8d49-6839d121d8ae"
    },
    {
        "name": "Advil Travel",
        "lookup": "305730151108",
        "ref": "a4b521cb-fc87-434a-9f1c-34a3e1c17828"
    },
    {
        "name": "Chapstick",
        "lookup": "305730725125",
        "ref": "50bddfd0-4da9-4b56-b69f-d7ee8f72b1fc"
    },
    {
        "name": "Tums",
        "lookup": "307667388961",
        "ref": "e846c41f-f3a8-4fbf-972e-6ad1d863f6fd"
    },
    {
        "name": "Soon Veg",
        "lookup": "31146027033",
        "ref": "10269c6b-45a9-47b5-a180-72535e7f8d7a"
    },
    {
        "name": "Nongshim Spicy Shin Cup Noodle Soup",
        "lookup": "31146270606",
        "ref": "c426f276-8c41-48c4-93fb-53255cae8ab6"
    },
    {
        "name": "Halls Cherry",
        "lookup": "312546627499",
        "ref": "3bf07099-5e18-4bd8-8c14-153e905b169f"
    },
    {
        "name": "Halls Honey",
        "lookup": "312546628694",
        "ref": "2df371c9-ae60-4bb1-a9ae-ed8a452eb4be"
    },
    {
        "name": "Neosporin",
        "lookup": "312547238083",
        "ref": "14da61e7-27c5-4c0f-9ec7-d494be1423f4"
    },
    {
        "name": "Midol",
        "lookup": "312843172531",
        "ref": "e18a347e-e933-4118-bd37-a25957bdf966"
    },
    {
        "name": "DayQuil Travel",
        "lookup": "323900014435",
        "ref": "8a2061ec-5592-4355-9a16-43c547b25d68"
    },
    {
        "name": "Ice Breakers",
        "lookup": "3484308",
        "ref": "2a54f5b3-f14c-43d1-a06d-9ba650dd8907"
    },
    {
        "name": "Mucinex",
        "lookup": "366715972415",
        "ref": "8eae336d-33e2-4766-8c71-2d5373ebf686"
    },
    {
        "name": "Tylenol",
        "lookup": "366715974730",
        "ref": "9a477826-9179-414c-be15-720fabe39357"
    },
    {
        "name": "Lacroix, Lime",
        "lookup": "3718378",
        "ref": "04966085-3eb7-43fc-89c5-72ca2b262330"
    },
    {
        "name": "Lindor Milk",
        "lookup": "37466015842",
        "ref": "f0b034a7-b0aa-4660-8579-92af9f21ea63"
    },
    {
        "name": "Lindor Assorted",
        "lookup": "37466015859",
        "ref": "e330a0e2-5e7b-466b-9e26-e30ab9516b62"
    },
    {
        "name": "Frito Bean",
        "lookup": "41220804009",
        "ref": "02f9cb12-0f83-4353-af5e-c9686953b713"
    },
    {
        "name": "H-E-B Reserve Italian Style Snack Tray - Uncured Hard Salami & Gouda Cheese",
        "lookup": "41220964673",
        "ref": "e7c85a1e-b443-48eb-a24d-2cb934e62ce5"
    },
    {
        "name": "Amys Pad Thai",
        "lookup": "42272009244",
        "ref": "32b437a6-af06-49ea-b088-fdc1be3ad821"
    },
    {
        "name": "Hot Pockets",
        "lookup": "43695071436",
        "ref": "15e4cf26-96a6-4839-88e1-2ea09b07fa7c"
    },
    {
        "name": "Oreo Double stuff",
        "lookup": "44000060138",
        "ref": "2ff510dc-1381-404a-9ae6-ccd516e046c0"
    },
    {
        "name": "Ritz Cheese",
        "lookup": "44000882112",
        "ref": "cd44f337-dff8-4647-8fda-ae34294a0c1c"
    },
    {
        "name": "Abe Blueberry",
        "lookup": "44261490446",
        "ref": "25f54eec-0b65-46ef-a6d3-55d59b399fad"
    },
    {
        "name": "Hillshire Snack Plate",
        "lookup": "44500201819",
        "ref": "9bcddd72-3dbf-4962-a56e-2874a12f2a49"
    },
    {
        "name": "Lunchable Ham n Swiss",
        "lookup": "44700024546",
        "ref": "dc040315-fb44-4048-bd51-7af45d8a48f8"
    },
    {
        "name": "Lunchable TRK Ched",
        "lookup": "44700024553",
        "ref": "f833a890-113d-4f11-a6e6-5a9da5ada70d"
    },
    {
        "name": "P3 Portable Protein Pack Snack Tray - Turkey, Colby Jack & Almonds",
        "lookup": "44700070758",
        "ref": "2a957005-36f6-4fbd-ae8e-5aa882bb0caa"
    },
    {
        "name": "P3 Portable Protein Pack Snack Tray - Ham, Cashews & Colby Jack",
        "lookup": "44700074275",
        "ref": "1480d3be-8c81-48eb-8622-bbe9ebf03e97"
    },
    {
        "name": "Jacks Beef Stick",
        "lookup": "5743292",
        "ref": "107e28ce-b09c-4390-b9d3-6e5cb3d93dc3"
    },
    {
        "name": "Nature path Granola",
        "lookup": "58449172017",
        "ref": "25e2fa8b-2d88-44e8-b024-a2e69230c62f"
    },
    {
        "name": "Premier chocolate",
        "lookup": "643843714477",
        "ref": "eb3a9038-b7df-4354-97ac-3dc215632cf0"
    },
    {
        "name": "Premier vanilla",
        "lookup": "643843714507",
        "ref": "3039ae5d-308c-4240-98df-bd053b64ea18"
    },
    {
        "name": "Clear eyes",
        "lookup": "67811254330",
        "ref": "e3a5d792-d153-4e73-b903-d34e6bfdc043"
    },
    {
        "name": "Kodiak chocolate chip",
        "lookup": "705599016288",
        "ref": "52cb916a-5a53-4781-a1b9-4a7eb9e8f701"
    },
    {
        "name": "Kodiak blueberry",
        "lookup": "705599018398",
        "ref": "65e9f92e-f968-488c-a20c-fa7e4a02e235"
    },
    {
        "name": "Nissin Chow mein",
        "lookup": "70662087237",
        "ref": "642c3e8e-349e-4679-93cb-113c602c8820"
    },
    {
        "name": "Nissin Beef",
        "lookup": "70662096338",
        "ref": "c76d29b6-1fab-4374-a10d-550e8b181519"
    },
    {
        "name": "Cornuts Ranch",
        "lookup": "71159000258",
        "ref": "293dff59-7fa7-4cb6-8581-b6feb8f501d4"
    },
    {
        "name": "Cornuts Picante",
        "lookup": "71159000807",
        "ref": "39a8abce-17ab-4ee4-bff6-0636818d10af"
    },
    {
        "name": "Chamoy Rings - Watermelon",
        "lookup": "712199087440",
        "ref": "df2fb048-08bc-41b7-b96b-5a26a8f7fe03"
    },
    {
        "name": "Zatarians Shrimp Alfredo",
        "lookup": "71429665613",
        "ref": "03087e4a-0962-44d3-ba0f-9e460901dbe2"
    },
    {
        "name": "Bimbo Bimbuenlos",
        "lookup": "74323028140",
        "ref": "e69e5b41-b01a-4b5f-9168-1b1236f298e0"
    },
    {
        "name": "Pure Protein - HBD",
        "lookup": "749826763309",
        "ref": "5908afff-d5cd-41da-bee3-919457905d21"
    },
    {
        "name": "Jimmy Dean Simple Scrambles Breakfast Cup - Turkey Sausage",
        "lookup": "77900650543",
        "ref": "57c32966-96d8-4507-89b2-dd179f5fef96"
    },
    {
        "name": "Tapatio CK",
        "lookup": "780707102343",
        "ref": "4a75a99d-db38-4b35-942d-25c859ca02b7"
    },
    {
        "name": "Isadora Beans",
        "lookup": "781624008053",
        "ref": "8b64351c-f62d-4e07-b15f-e96c622738c2"
    },
    {
        "name": "Lily PB",
        "lookup": "810003460400",
        "ref": "a9e3a76a-e723-4bee-a400-96c69cf43899"
    },
    {
        "name": "lily Caramel Dark",
        "lookup": "810003460677",
        "ref": "5dcfc0d1-2f43-48a3-be7d-f3854222830a"
    },
    {
        "name": "RC Ranch Orginal",
        "lookup": "810074340007",
        "ref": "a070ad7f-7fde-47c0-9f15-a5a144e689b5"
    },
    {
        "name": "RC Ranch Peppered",
        "lookup": "810074340038",
        "ref": "ca567291-ffa1-4d98-ada6-023739b06a45"
    },
    {
        "name": "Hippea Chicpea puffs",
        "lookup": "810122080107",
        "ref": "00705aef-d97d-4525-ba81-6290a973163f"
    },
    {
        "name": "Peach Crisp",
        "lookup": "811387010045",
        "ref": "62ca9c62-0daa-4828-b44a-6f36717382ac"
    },
    {
        "name": "Bobo's Coconut",
        "lookup": "829262000029",
        "ref": "a306a360-929a-468c-b55b-0bf81536d403"
    },
    {
        "name": "Tropics Rice Pudding",
        "lookup": "829354000000",
        "ref": "d3556e13-37c9-4a6c-9a09-16042712ab46"
    },
    {
        "name": "Zapp Hot n Hot",
        "lookup": "83791059396",
        "ref": "0a3024b4-439c-4010-9ac0-88954dce9b30"
    },
    {
        "name": "Voodoo Chips",
        "lookup": "83791150055",
        "ref": "5d6db4eb-9a44-49e8-9953-3ae559fbd468"
    },
    {
        "name": "Verb, Banana",
        "lookup": "850031387676",
        "ref": "76067247-53ad-4628-8930-e1110b9bcca9"
    },
    {
        "name": "Verb, Birthday Cake",
        "lookup": "850043221418",
        "ref": "11236247-e69d-4f57-bdc4-666888037887"
    },
    {
        "name": "Siete Mexican Wedding Cookies",
        "lookup": "851769007966",
        "ref": "0597f979-f8e4-4422-8104-9072053adaf3"
    },
    {
        "name": "Granola sola",
        "lookup": "851921006202",
        "ref": "f2d425e6-eed8-4d6a-8779-53c496b6c36d"
    },
    {
        "name": "Partake Chocolate",
        "lookup": "852761007336",
        "ref": "19300503-7cd2-4331-979b-4c51ee8b45b3"
    },
    {
        "name": "Siete Salt n Vinegar",
        "lookup": "853986008085",
        "ref": "5358979c-18a3-4f65-a88b-bf40a6b202f7"
    },
    {
        "name": "Siete BBQ",
        "lookup": "853986008115",
        "ref": "6769fc84-a94f-45ce-8322-abce7214707a"
    },
    {
        "name": "Dots Pretzels",
        "lookup": "855712008017",
        "ref": "c4d3b678-b099-40fe-8321-0c2a472ae241"
    },
    {
        "name": "J & A Nuggets",
        "lookup": "859806003834",
        "ref": "d6603582-e799-4b9a-b027-17a869ce37e1"
    },
    {
        "name": "Quest Cookies & Cream",
        "lookup": "888849000005",
        "ref": "060611c7-9251-4968-ab33-029fd47bdadf"
    },
    {
        "name": "Quest Cookie Dough",
        "lookup": "888849000012",
        "ref": "c1a8f442-61dc-4f12-80d3-0d205de83177"
    },
    {
        "name": "Quest Double Chip",
        "lookup": "888849000234",
        "ref": "35e135bc-2b6c-4f94-9069-543abb40e439"
    },
    {
        "name": "Quest Brownie",
        "lookup": "888849000418",
        "ref": "f83a012d-3452-46a9-9106-dce33a1bb235"
    },
    {
        "name": "Quest Smore",
        "lookup": "888849001224",
        "ref": "94556354-974f-4f37-be57-f96f1b063e18"
    },
    {
        "name": "Quest Oatmeal",
        "lookup": "888849004621",
        "ref": "d01bd8e0-4739-4f8c-917b-4dfde9c62fee"
    },
    {
        "name": "Quest Chocolate chip",
        "lookup": "888849006007",
        "ref": "05dfd213-3c9b-45df-96ae-ff5cf9173b17"
    },
    {
        "name": "Quest Cheese",
        "lookup": "888849006632",
        "ref": "1980d86e-9e72-48ed-83cb-568c1a22b8cd"
    },
    {
        "name": "Quest Chili lime",
        "lookup": "888849006656",
        "ref": "7a2626c3-edb5-4517-8c18-7058dda25184"
    },
    {
        "name": "Quest PB",
        "lookup": "888849008049",
        "ref": "0fd91d27-3ba9-482b-860a-fbaff004ad99"
    },
    {
        "name": "Quest Taco",
        "lookup": "888849009954",
        "ref": "7960a0f7-7ee9-4b87-a8ca-d01c2de6506f"
    },
    {
        "name": "Strawberry bannana Crisp",
        "lookup": "894185000142",
        "ref": "6d2f28d0-8ccf-48b5-9278-b67bf7eb84a7"
    },
    {
        "name": "Vita Coco",
        "lookup": "898999010007",
        "ref": "ea088fc1-30ba-4e74-b9c7-20417c362520"
    },
    {
        "name": "Lindor White",
        "lookup": "9542443030",
        "ref": "80de35db-372f-4599-ab40-b0cf88c49151"
    },
    {
        "name": "Nutella to-go",
        "lookup": "9800800056",
        "ref": "d5293387-de99-4ceb-8651-574cfe660928"
    },
    {
        "name": "Nutella Biscuits",
        "lookup": "9800830039",
        "ref": "7122cd62-2808-4ab7-bb34-e0622d83cc2b"
    }, // end of bastrop
        {
            "ref": "7d67538c-526d-482e-a110-e867019e5529",
            "name": "Aluminum Foil",
            "lookup": "10900000154"
        },
        {
            "ref": "529f7b4e-583d-4dd3-b7bb-2b29e0d33f62",
            "name": "Body Wash Dove",
            "lookup": "11111123311"
        },
        {
            "ref": "5e2b1c1b-07fd-45a3-b6d8-e9a993eb2cc8",
            "name": "Repel",
            "lookup": "11423941092"
        },
        {
            "ref": "60cbefbc-42bd-4b2e-bbe4-1ce0b96b21b6",
            "name": "Old Spice Swagger",
            "lookup": "12044016435"
        },
        {
            "ref": "7695e3d7-7f3f-4415-9b08-7f487f10ebca",
            "name": "Starbrew Bottle Growler Agua",
            "lookup": "12345AGU"
        },
        {
            "ref": "e84d455b-3b94-49b1-b613-170748aae250",
            "name": "Multi Grain Bread Loaf",
            "lookup": "12345BRD"
        },
        {
            "ref": "6d39069c-3f2a-4658-9eeb-88bb292c42c0",
            "name": "Starbrew Bottle Almond brown Sugar",
            "lookup": "12345BRW"
        },
        {
            "ref": "89e56ff1-854c-44a7-979e-6471f2e9cc53",
            "name": "Beef - Burger",
            "lookup": "12345BUR"
        },
        {
            "ref": "e71bd7f3-6240-49d9-bba9-3a44ec4caf41",
            "name": "Chicken Breast",
            "lookup": "12345CK"
        },
        {
            "ref": "575ec860-1d89-4781-9edc-c57579a06a44",
            "name": "Starbrew Bottle Agua Fresca Bottle",
            "lookup": "12345FLY"
        },
        {
            "ref": "7e540802-819b-45db-97d1-6730fdeb4bab",
            "name": "Starbrew Bottle London Fog",
            "lookup": "12345FOG"
        },
        {
            "ref": "d08b8d57-96cd-4c11-a41f-8f7d1ad0a649",
            "name": "Starbrew Bottle Growler Kyoto",
            "lookup": "12345GRW"
        },
        {
            "ref": "5f57f9b4-5804-4d99-af58-4c7afabf66f0",
            "name": "Banana Foster Ice Cream Cookie",
            "lookup": "12345ICE"
        },
        {
            "ref": "dc1cf17c-5cc4-4d5c-9636-9165164de40b",
            "name": "Jitterz Coffee",
            "lookup": "12345JIT"
        },
        {
            "ref": "91c51b34-a875-42a7-997f-cf99b2bf7c87",
            "name": "Starbrew Bottle Cold Brew Kyoto",
            "lookup": "12345KYO"
        },
        {
            "ref": "9feecf8e-e15e-4a91-8f91-ae1965cfbcb3",
            "name": "Beef - NY Steak",
            "lookup": "12345NY"
        },
        {
            "ref": "47d5dad2-1861-47ca-9f33-8960e4d18d52",
            "name": "Starbrew Bottle Vanilla Oat Cold Brew",
            "lookup": "12345OAT"
        },
        {
            "ref": "1dcf0e08-be7f-4abc-8e68-4a94149f2ff2",
            "name": "Potato Salad",
            "lookup": "12345POT"
        },
        {
            "ref": "a4d01285-a883-44f1-9e26-61991673b657",
            "name": "Pork Chop",
            "lookup": "12345PRK"
        },
        {
            "ref": "747fd70e-667b-4487-bed3-ee7d4bc77a72",
            "name": "Ramen Topping Box",
            "lookup": "12345RAM"
        },
        {
            "ref": "fb9cb157-9a1d-464e-8a00-bc8ad2c921a9",
            "name": "Cured Smoked Salmon",
            "lookup": "12345SAL"
        },
        {
            "ref": "83ed5210-11a9-4d2f-8e63-ee0071689077",
            "name": "Beef - Flat Iron Steak",
            "lookup": "12345SIR"
        },
        {
            "ref": "db06f67b-8f7c-4ddc-a8ab-fb6ab0c144d9",
            "name": "Beef - Skirt Steak",
            "lookup": "12345SKT"
        },
        {
            "ref": "57649deb-2345-4fa1-a9c2-61664e8dc171",
            "name": "Starbrew Bottle hibiscuss nerry Tea",
            "lookup": "12345TEA"
        },
        {
            "ref": "65fb1764-f55f-4815-b5ed-c52ca8d369a5",
            "name": "Chicken Tikka Masala",
            "lookup": "12345TIK"
        },
        {
            "ref": "1bad1496-875b-44b8-af56-1c0dd5d020f1",
            "name": "Starbrew Bottle Space Juice",
            "lookup": "12345TNG"
        },
        {
            "ref": "c330b0fc-df3e-40db-9b97-ca4d82f6cfc4",
            "name": "Chickpea Masala",
            "lookup": "12345VEG"
        },
        {
            "ref": "2c89b97c-e045-45dd-9d9c-ceeb4e91f2f1",
            "name": "Red wine Vinaigrette",
            "lookup": "12345VIN"
        },
        {
            "ref": "660029eb-453e-40c0-887f-4584fe03dda6",
            "name": "Starbrew Bottle Growler, Tea",
            "lookup": "123TEA"
        },
        {
            "ref": "7f5f1582-6bae-4f7e-9011-92c866a0d62b",
            "name": "Glad Press n Seal Plastic Wrap",
            "lookup": "12587704608"
        },
        {
            "ref": "357a94ae-090a-4823-a46d-592b88f36f8c",
            "name": "Garbage bags, Glad",
            "lookup": "12587786857"
        },
        {
            "ref": "7712a407-87bb-40a7-a367-ef441eb74366",
            "name": "Ketchup Heinz",
            "lookup": "13000006057"
        },
        {
            "ref": "d71a24a7-7574-4eae-851e-3dfa8135b929",
            "name": "Ketchup Simply Ketchup",
            "lookup": "13000626095"
        },
        {
            "ref": "5711b7b9-214a-4746-b909-9b35414843c6",
            "name": "Ketchup Small",
            "lookup": "1312403"
        },
        {
            "ref": "a0142689-2100-43ad-99f4-54fab7f077be",
            "name": "Annie 2 pack Mac n Cheese",
            "lookup": "13562000593"
        },
        {
            "ref": "11e0c96b-ebcf-4345-9990-fb4317ba234f",
            "name": "Annie's Organic Shells mac and cheese",
            "lookup": "13562300396"
        },
        {
            "ref": "54300f7a-feae-4a72-b58b-d8462cc0ebe2",
            "name": "Annie's Organic Shells shells and white cheddar",
            "lookup": "13562300631"
        },
        {
            "ref": "b2013384-d530-4169-96c0-d7303465d9c0",
            "name": "Annie's Organic Shells aged cheddar",
            "lookup": "13562300983"
        },
        {
            "ref": "1bfac01f-41ae-4bde-b10e-e585154020e2",
            "name": "Ketchup Regular",
            "lookup": "1364008"
        },
        {
            "ref": "ee1a9511-139f-4ec5-aa6b-94ad079b2a47",
            "name": "Bagel Everything",
            "lookup": "13764028036"
        },
        {
            "ref": "5c8ead7e-89eb-46cd-b708-238d1af6d34a",
            "name": "stouffers mac n cheese Regular",
            "lookup": "13800103406"
        },
        {
            "ref": "d45b948a-aefc-4a26-8fb9-7c265742f56e",
            "name": "stouffers mac n cheese Large",
            "lookup": "13800103420"
        },
        {
            "ref": "e533b970-26e5-4f97-992d-c22e5a2a0ac8",
            "name": "Stouffers Bowls chicken bacon ranch",
            "lookup": "13800470089"
        },
        {
            "ref": "efe8c4b0-d620-42d9-8451-4498db726ff4",
            "name": "Stouffers Bowls steak and potato",
            "lookup": "13800475626"
        },
        {
            "ref": "c7f05a44-f531-4bb0-ace6-19029cf40d87",
            "name": "Stouffers Bowls Fried chicken",
            "lookup": "13800938947"
        },
        {
            "ref": "039da38a-53b9-4fa2-9ed5-60e25120a8db",
            "name": "Tortilla chip Disco de oro",
            "lookup": "13964193480"
        },
        {
            "ref": "2a7a9179-1b22-435f-9bed-684cb788311f",
            "name": "Bare Fruit Chips Cinnamon",
            "lookup": "13971021011"
        },
        {
            "ref": "5c159e4b-38b8-4f99-a8df-37ef1f32dda9",
            "name": "Bare Fruit Chips Small Bag",
            "lookup": "13971030013"
        },
        {
            "ref": "b65560f9-7deb-41c7-aaf4-c12041498cf1",
            "name": "Gold Fish Blasted",
            "lookup": "14100050759"
        },
        {
            "ref": "ed456575-7610-4a4b-ade4-736c3543dc97",
            "name": "Gold Fish Regular",
            "lookup": "14100085478"
        },
        {
            "ref": "3b1583a8-b669-4954-b682-0cb92323d5aa",
            "name": "Gold Fish Cheddar Blast",
            "lookup": "14100085485"
        },
        {
            "ref": "4f34d742-de4b-4c50-833a-429fbf868513",
            "name": "Bird's Eye Steamfresh Lentil Cheddar Pasta",
            "lookup": "14500017444"
        },
        {
            "ref": "dc52e225-ee5c-4007-82f5-078a868f8d45",
            "name": "Bird's Eye Steamfresh Lentil Alfredo Pasta",
            "lookup": "14500017505"
        },
        {
            "ref": "a7296dcc-3d4f-4aa3-a925-e73ea90920cf",
            "name": "Ramen - Snap Dragon Instant Chicken Ramen",
            "lookup": "1505501179"
        },
        {
            "ref": "f186a6d7-695c-4f48-8e48-7090cd9aeb4a",
            "name": "Flour, 5 LB",
            "lookup": "16000106109"
        },
        {
            "ref": "2d3a978e-9f5f-4820-b0ed-fc78498b154e",
            "name": "Lucky Charms",
            "lookup": "16000124998"
        },
        {
            "ref": "db52f961-0026-4342-be7e-ec614aef312d",
            "name": "Cereal, Honey nut Cherrios",
            "lookup": "16000125063"
        },
        {
            "ref": "dd79df09-0418-49e4-ba93-c7a60b7be37e",
            "name": "Cinnamon Toast Crunch",
            "lookup": "16000125933"
        },
        {
            "ref": "bd823492-54df-4d68-8488-44d72b1fdbf3",
            "name": "One A Day Vitamins Women's",
            "lookup": "16500074120"
        },
        {
            "ref": "c297da41-29d6-4285-824e-de59d28dfa8f",
            "name": "One A Day Vitamins Men's",
            "lookup": "16500565338"
        },
        {
            "ref": "dd42dcf0-cfff-4ea0-8300-9cadca284809",
            "name": "Corn Tortilla, Exquista",
            "lookup": "16916203008"
        },
        {
            "ref": "ae00a8ee-8a60-4700-8512-117f224086de",
            "name": "Tortilla, Flour La Banderita exquista",
            "lookup": "16916206009"
        },
        {
            "ref": "7627f80f-2a5a-487a-9d9e-8d0844d6b3ae",
            "name": "Jack Links Beef Jerky sweet & Hot",
            "lookup": "17082008978"
        },
        {
            "ref": "3c3b8f75-6cde-4cd9-a83f-9d791d33b826",
            "name": "Jack Links Beef Jerky Regular",
            "lookup": "17082877628"
        },
        {
            "ref": "40dfcb72-b539-41de-93b7-c4320941bbfc",
            "name": "Jack Links Beef Jerky peppered",
            "lookup": "17082877635"
        },
        {
            "ref": "31d779ae-470b-42ee-99c2-41a1aa4da876",
            "name": "Jack Links Beef Jerky teryaki",
            "lookup": "17082877642"
        },
        {
            "ref": "9d69744d-b856-4491-a95a-4bc86d5f0db7",
            "name": "Beef Stick, Jack Links Wild Stick",
            "lookup": "17082879783"
        },
        {
            "ref": "4c8e36b3-38ca-4ab2-bb94-d1300282e4b8",
            "name": "Beef Stick, Jack Links",
            "lookup": "17082884435"
        },
        {
            "ref": "b20fc35c-bf9f-4499-b9e5-b5ab2772a399",
            "name": "Cat - Purina One Dry Cat Food",
            "lookup": "17800474900"
        },
        {
            "ref": "605050f9-a739-44f6-b107-f14efdd4f02b",
            "name": "Cinnamon Rolls, Pilsbury Large",
            "lookup": "1815900"
        },
        {
            "ref": "ba7bfde0-1825-483e-aaba-7cd852e2dd97",
            "name": "Biscuits, Pillsbury",
            "lookup": "1821105"
        },
        {
            "ref": "f0039706-2b46-48bd-a7e2-6400502de617",
            "name": "Cinnamon Rolls, Pilsbury Regular",
            "lookup": "1850709"
        },
        {
            "ref": "3ded2e48-7dac-4a84-98a3-b96ae096e6f6",
            "name": "Baby Food Pouch Mixed Berry Oatmeal",
            "lookup": "1851201244"
        },
        {
            "ref": "0ae09711-2fce-408e-9d39-cf7628088568",
            "name": "Hand lotion, Jergens",
            "lookup": "19100109988"
        },
        {
            "ref": "358f2526-aacb-4c7a-988f-540353c18c6c",
            "name": "Cat - Frisco Light Weight Cat Litter",
            "lookup": "192269000000"
        },
        {
            "ref": "4aa20dfb-2f7e-4c95-9008-1cd4290db84d",
            "name": "Baking Powder",
            "lookup": "19900003202"
        },
        {
            "ref": "6b362847-53d5-4709-9a08-91a58d68d8fc",
            "name": "Weiser Sauce, Raspberry Chipolte Regular",
            "lookup": "20138330053"
        },
        {
            "ref": "adafe9aa-4bfb-4132-a397-314111bca511",
            "name": "Weiser Sauce, Raspberry Chipolte Texas Whiskey",
            "lookup": "20138330138"
        },
        {
            "ref": "761ed044-f18d-44ea-a715-4cd050cf44a7",
            "name": "Kraft Mac & Cheese Single Cup Regular",
            "lookup": "21000010875"
        },
        {
            "ref": "d199afa1-9c28-4145-b7dd-38ab2571848b",
            "name": "Kraft Mac & Cheese Single Cup Velvetta",
            "lookup": "21000023226"
        },
        {
            "ref": "9bb6ac84-2833-49f4-9441-b5c18241780c",
            "name": "Velveeta skillets- chicken alfredo",
            "lookup": "21000037513"
        },
        {
            "ref": "4c62a306-d896-44f8-86b8-ad669588c8b2",
            "name": "Cheese, Colby Jack Regular",
            "lookup": "21000055135"
        },
        {
            "ref": "6ab2772d-fdef-4e51-801a-dce31d807b6c",
            "name": "Cheese, Colby Jack fine Shred",
            "lookup": "21000055326"
        },
        {
            "ref": "a98f1e6c-f106-48bf-a00a-10bdf4b1048c",
            "name": "Cheese, Velvetta",
            "lookup": "21000616909"
        },
        {
            "ref": "79c24b00-c139-42b4-95f5-0f744e0824af",
            "name": "Cream Cheese Original",
            "lookup": "2101402"
        },
        {
            "ref": "e03d891c-6ac1-4022-9bb7-c7d3df5cd2ac",
            "name": "Cream Cheese Strawberry",
            "lookup": "2172701"
        },
        {
            "ref": "f82de3d7-1397-44c1-9c9b-dca9b838b8be",
            "name": "Cream Cheese Garden Vegetable",
            "lookup": "2173001"
        },
        {
            "ref": "5280e41e-15f6-4c3a-9640-8989ac6a32fa",
            "name": "Cream Cheese Pumpkin Spice",
            "lookup": "2174408"
        },
        {
            "ref": "858413c0-3a71-4e0e-8c6e-e414de574d38",
            "name": "Altoid Smalls",
            "lookup": "2297309"
        },
        {
            "ref": "28083c3d-2593-4d29-8df0-eae24feea2fc",
            "name": "Tyson Fun Nuggets",
            "lookup": "23700014528"
        },
        {
            "ref": "0d159a2f-61f3-454e-8867-d4cb2a2a4f8f",
            "name": "Chicken Strips",
            "lookup": "23700050571"
        },
        {
            "ref": "8447cfd2-db04-4dd3-84b8-ad684c76f451",
            "name": "Baby Food Pouch Chicken Casserole",
            "lookup": "23923333369"
        },
        {
            "ref": "d61aa4aa-80fd-4ee1-8a7e-c8f7e9b2542d",
            "name": "Baby Food Pouch Four Bean Feast",
            "lookup": "23923532915"
        },
        {
            "ref": "0f418e3e-0f31-48ee-8d71-0e15f17cc029",
            "name": "Tomato Paste",
            "lookup": "24000371168"
        },
        {
            "ref": "5ddf7fbe-a39d-48ab-809a-e450837be579",
            "name": "Ravioli, Fresh Buitoni Tortellini",
            "lookup": "24842112110"
        },
        {
            "ref": "f6cc181d-ae52-46ac-9656-17dab8c5003e",
            "name": "Ravioli, Fresh Buitoni small ravioli",
            "lookup": "24842172114"
        },
        {
            "ref": "02d2fb51-64a1-4c08-aa23-ccd76d1506b4",
            "name": "Ravioli, Fresh Buitoni Regular",
            "lookup": "24842192112"
        },
        {
            "ref": "d0de0916-397f-4a42-914d-c25f990d786b",
            "name": "Pasta Sauce, Buitoni marinara",
            "lookup": "24842311216"
        },
        {
            "ref": "0a24bea5-30e5-4591-b1c6-2805b3964480",
            "name": "Pasta Sauce, Buitoni Pesto Basil",
            "lookup": "24842321116"
        },
        {
            "ref": "3af58be1-a7bc-44d0-a1a5-ab85793ff4de",
            "name": "Sauce, Alfredo",
            "lookup": "24842873691"
        },
        {
            "ref": "6f49889c-f249-4789-bdf6-78c073734299",
            "name": "Juice, Orange",
            "lookup": "25000044830"
        },
        {
            "ref": "003c0b7e-3a8f-4f77-8c19-bc9782150cc1",
            "name": "Ziploc Quart Storage Bags",
            "lookup": "25700709497"
        },
        {
            "ref": "fd2ba4ed-1334-4bc2-8eca-4facf88064e6",
            "name": "Snack Packs Smal snack pack",
            "lookup": "27000419007"
        },
        {
            "ref": "955a962e-91b3-4941-a281-78e50840629f",
            "name": "Snack Packs Regular",
            "lookup": "27000419823"
        },
        {
            "ref": "01ef0df7-caa8-42d1-be84-78f1b0901825",
            "name": "Tortilla, Flour La Banderita Regular",
            "lookup": "27331000325"
        },
        {
            "ref": "28023848-e9d9-4860-912d-d2aa1d1390c6",
            "name": "Chocolate Chips",
            "lookup": "28000215606"
        },
        {
            "ref": "9bed4702-d29a-4229-a459-18110a38314b",
            "name": "Hot chocolate, Abuleitas",
            "lookup": "28000525538"
        },
        {
            "ref": "d851fcc6-706b-406d-816e-f3ce10acba03",
            "name": "Bean Dip, Jalapeno Cheddar Regular",
            "lookup": "28400000246"
        },
        {
            "ref": "8da5952a-c134-4b7f-981f-c3f2cd7c97f2",
            "name": "Bean Dip, Jalapeno Cheddar Normal",
            "lookup": "28400000789"
        },
        {
            "ref": "de304d74-5f4d-40ec-a450-bca5d7e44d70",
            "name": "Bean Dip, Jalapeno Cheddar Hot bean dip",
            "lookup": "28400000888"
        },
        {
            "ref": "43f457ac-6177-4b58-b189-4cab8dc7f94d",
            "name": "Cheetos, Hot Regular",
            "lookup": "28400314077"
        },
        {
            "ref": "060c83ce-60aa-4e10-b782-423edea490ab",
            "name": "Frito Scoop Frito",
            "lookup": "28400327237"
        },
        {
            "ref": "9e7845bb-79bd-405c-aedf-9dce4d0876d6",
            "name": "Pita Chips",
            "lookup": "28400564632"
        },
        {
            "ref": "34f3888a-b2d0-400e-b9c4-b15695e228ac",
            "name": "Frito Scoop Regular",
            "lookup": "28400589321"
        },
        {
            "ref": "a7b6b45b-d4ea-41df-8665-3a7ed6e268d0",
            "name": "Cheetos, Hot Small",
            "lookup": "28400589895"
        },
        {
            "ref": "fe9a4c6c-a544-4ae7-a29c-e3e555664fcc",
            "name": "Nuts, Planters Canned Regular",
            "lookup": "29000016071"
        },
        {
            "ref": "0ebcaffa-2b63-41f0-a89b-1788fd4d3e79",
            "name": "Nuts, Planters Canned Peanuts",
            "lookup": "29000072121"
        },
        {
            "ref": "663d924f-b245-47f9-b898-e45c471f791e",
            "name": "Au Gratin Pot",
            "lookup": "29700012663"
        },
        {
            "ref": "9cbfe16e-b361-4288-b846-34157f46abd0",
            "name": "Idahoan Mashed Pot",
            "lookup": "29700321413"
        },
        {
            "ref": "18ff333d-07c0-41cc-a082-adc21d6cb10a",
            "name": "Cracker Jacks",
            "lookup": "30000029138"
        },
        {
            "ref": "71a4df9c-1712-4b92-a5ab-51294421e084",
            "name": "Tylenol small",
            "lookup": "300450000000"
        },
        {
            "ref": "8a79108e-092a-4a5d-b04e-bfee555d4df3",
            "name": "Contact Solution",
            "lookup": "300650000000"
        },
        {
            "ref": "3ef92f7e-78ab-4357-8d3e-99f166718fdb",
            "name": "Baby Formula, Similac Gentlease",
            "lookup": "300875000000"
        },
        {
            "ref": "53b1075e-d257-4973-a363-587927921400",
            "name": "Jergens Ultra Moisture Lotion Vaseline",
            "lookup": "305213000000"
        },
        {
            "ref": "9e6ea67c-5373-49b2-926f-e1c9eb61306d",
            "name": "Children's Advil",
            "lookup": "305730000000"
        },
        {
            "ref": "8702c8d9-ebf1-4d0b-9434-ebc9433166eb",
            "name": "Burt's Bees Lip Balm Cherry chapstick",
            "lookup": "3057307051"
        },
        {
            "ref": "4ecc42c5-529d-4691-9d68-11cddcffbeaf",
            "name": "Burt's Bees Lip Balm Chapstick",
            "lookup": "305731000000"
        },
        {
            "ref": "2b76715a-f612-4ce3-81fc-bf1f22377199",
            "name": "Tums Large Berry",
            "lookup": "307667000000"
        },
        {
            "ref": "573a48a6-7a50-4b1e-a6d9-95675ff743cd",
            "name": "Toilet paper, Charmin",
            "lookup": "30772041765"
        },
        {
            "ref": "5890d411-fdb2-41f5-88be-fd73ecb9f3e4",
            "name": "Bounty Paper Towels Paper Towels, Bounty",
            "lookup": "30772061220"
        },
        {
            "ref": "56c6b774-0458-46c0-b3d7-d25ce39f961a",
            "name": "Bounty Paper Towels triple roll",
            "lookup": "30772061336"
        },
        {
            "ref": "413f483d-d7a5-4fd7-ba1c-1b4d3e5717c0",
            "name": "Detergent, Cascade Small Size",
            "lookup": "30772064818"
        },
        {
            "ref": "668be13c-2cb7-45c5-81f7-2033184ef31e",
            "name": "Detergent, Cascade Regular",
            "lookup": "30772120484"
        },
        {
            "ref": "6824cbe5-82f5-4d10-8926-25b12c31239f",
            "name": "Lil DrugStore Finger Nail Clipper revlon",
            "lookup": "309972000000"
        },
        {
            "ref": "9ac69f7e-1bff-4906-9f34-0f18583bc6cc",
            "name": "Ramen - Nongshim Shin Instant Beef",
            "lookup": "31146014408"
        },
        {
            "ref": "b5342959-c371-477c-879e-e708041b26f2",
            "name": "Ramen, Soon Kimchi Veggie",
            "lookup": "31146027033"
        },
        {
            "ref": "26ca56d9-0b18-4d29-a6b3-bc83b1e396a7",
            "name": "Ramen, Soon Kimchi Regular",
            "lookup": "31146033492"
        },
        {
            "ref": "4f6e904c-aebd-4025-b19f-f223d199128c",
            "name": "Ramen, Chili oil Black Garlic",
            "lookup": "31146058105"
        },
        {
            "ref": "bdcc102c-fef9-4c88-a93e-d3ab3968bbd2",
            "name": "Ramen - Nongshim Shin Instant Vegan",
            "lookup": "31146058679"
        },
        {
            "ref": "d8401b6a-5dcb-4882-a566-20a9a4c4351f",
            "name": "Ramen, Chili oil Regular",
            "lookup": "31146061020"
        },
        {
            "ref": "a06f6b01-cd94-4496-b71c-2aa8c8cc0272",
            "name": "Ramen - Nongshim Shin Instant spicy",
            "lookup": "31146270606"
        },
        {
            "ref": "3ab719f3-f431-4ebd-ab69-1d564262c8d5",
            "name": "Neosporin",
            "lookup": "312547000000"
        },
        {
            "ref": "c85f9a3e-b6e2-4ffb-9a17-cdb38fb1c0fc",
            "name": "Midol Complete 16 count",
            "lookup": "312843000000"
        },
        {
            "ref": "83a5b2ec-7979-4262-b869-75af3379da82",
            "name": "DayQuil Liquid gels",
            "lookup": "323900000000"
        },
        {
            "ref": "c4737a33-992c-44ee-afa6-22278253df7b",
            "name": "Pasta Sauce, Orti di Calabria Tomato Basil",
            "lookup": "33157000012"
        },
        {
            "ref": "09ec37fc-12e3-4413-b001-3052e1728a31",
            "name": "Pasta Sauce, Orti di Calabria Regular",
            "lookup": "33157000043"
        },
        {
            "ref": "633fc125-df56-475e-9324-2c1d2759ff12",
            "name": "Pasta Sauce, Orti di Calabria sicilian zesty",
            "lookup": "33157000074"
        },
        {
            "ref": "b99719fd-79bf-4d52-9022-17bc6ddf93d4",
            "name": "Baking soda",
            "lookup": "33200011101"
        },
        {
            "ref": "e0b57e2e-eace-4dd7-b6be-a91982f898aa",
            "name": "Season Salt",
            "lookup": "33844060015"
        },
        {
            "ref": "3eceb152-08e5-4f25-8e79-cb38f8501988",
            "name": "Chicken Nuggets Dino",
            "lookup": "3469580521"
        },
        {
            "ref": "1bed6b7d-cf6a-44c9-a30d-7d3f47e0330e",
            "name": "Ice Breakers Gum` Cinnamon",
            "lookup": "3481107"
        },
        {
            "ref": "b35ccbe1-36cf-4595-a96e-c0249a236cf0",
            "name": "Ice Breakers Gum` Peppermint",
            "lookup": "3484308"
        },
        {
            "ref": "9232a933-2dee-4bf1-8405-2ee17ee253c6",
            "name": "Toothpaste, Large Colgate",
            "lookup": "35000458360"
        },
        {
            "ref": "d716d316-0394-4190-be64-41739b05cec3",
            "name": "ToothBrush",
            "lookup": "35000551146"
        },
        {
            "ref": "21aea707-d2aa-4213-b9bf-bdf3d9661c24",
            "name": "Irish Spring Body Soap` Ultimate Wakeup",
            "lookup": "35000993977"
        },
        {
            "ref": "04ea308a-0fc8-4447-8fbe-d91736a83dff",
            "name": "Irish Spring Body Soap` Mountain Chill",
            "lookup": "35000994745"
        },
        {
            "ref": "6505d03e-d643-4b75-820a-d4d7880fc749",
            "name": "Irish Spring Body Soap` Original Clean",
            "lookup": "35000996749"
        },
        {
            "ref": "5f584f2a-42f5-4681-8a28-1123978624c4",
            "name": "Benadryl, Childrens",
            "lookup": "350581000000"
        },
        {
            "ref": "e1f90195-efcd-4ba2-a8d0-ca757bcbac06",
            "name": "Kleenex Regular",
            "lookup": "36000500912"
        },
        {
            "ref": "0cb72a55-c57d-466f-9041-ed8b4d822f72",
            "name": "Kleenex Small",
            "lookup": "36000542714"
        },
        {
            "ref": "5956033a-ebd3-474c-8ada-605eb75eb028",
            "name": "Meatballs",
            "lookup": "36669061755"
        },
        {
            "ref": "0cdcddfa-1465-4ac9-904d-2837c06a8298",
            "name": "Tylenol Extra Strength 3 Dose",
            "lookup": "366716000000"
        },
        {
            "ref": "f6a49880-ef50-47d9-b20b-e106a55e4e40",
            "name": "Deodorant, Womens Secret",
            "lookup": "37000123439"
        },
        {
            "ref": "94a26d6c-65bc-4926-b56a-5c7bf26e172b",
            "name": "Dryer Sheets",
            "lookup": "37000432272"
        },
        {
            "ref": "662d09bb-d5fa-48fd-b573-26d8add0ad72",
            "name": "Tide Pods Regular",
            "lookup": "37000509608"
        },
        {
            "ref": "11d83130-1387-4d12-9a3b-b27fc44b69c5",
            "name": "Wipes Pampers wipes",
            "lookup": "37000872115"
        },
        {
            "ref": "4e383cd3-c572-4eb5-9e43-36b4dba163e5",
            "name": "Dove Body Wash",
            "lookup": "37000919810"
        },
        {
            "ref": "251ab9d2-97f4-4705-b1d3-de1ad317a892",
            "name": "Tide Pods 3 in 1 Large",
            "lookup": "37000930372"
        },
        {
            "ref": "fd10af2d-6d44-48a9-8bd3-ed9d77ba65bb",
            "name": "Scope Mouthwash",
            "lookup": "37000956587"
        },
        {
            "ref": "ab49a07b-8419-4509-b425-a999b4b6a966",
            "name": "Febreeze Air freshner",
            "lookup": "37000962571"
        },
        {
            "ref": "054a8120-dfa2-43ec-aaed-2aff2eefbcc3",
            "name": "Tide Pods 17 CT",
            "lookup": "37000977797"
        },
        {
            "ref": "83f6ba8f-4688-40d3-9907-6d142c5a0e10",
            "name": "Tide Pods 32 pack",
            "lookup": "37000977810"
        },
        {
            "ref": "8adfd550-4805-413a-8b02-425842f0f1ad",
            "name": "Green Beans, Libby",
            "lookup": "37100033188"
        },
        {
            "ref": "ed9ff742-9a10-48fa-b065-b1b5d490514d",
            "name": "Libby's Sweet Corn",
            "lookup": "37100036622"
        },
        {
            "ref": "4a46e056-d743-4331-9559-f08ec3ec110d",
            "name": "Libbys green Beans",
            "lookup": "37100180004"
        },
        {
            "ref": "f51e73d7-fe78-40de-b24e-5ef7d977b74f",
            "name": "Lindor balls Dark chocolate",
            "lookup": "37466014005"
        },
        {
            "ref": "faad4c95-d837-4a8e-a8d8-0899219a1895",
            "name": "Lindor balls Milk small",
            "lookup": "37466015842"
        },
        {
            "ref": "b99979d2-0e1a-434c-9b6d-1d2edebad0c1",
            "name": "Lindor balls assorted small",
            "lookup": "37466015859"
        },
        {
            "ref": "671e8484-9223-4122-afc4-2c32bc5e9cf8",
            "name": "Lindor balls white chocolate",
            "lookup": "37466018553"
        },
        {
            "ref": "3bf8d6a8-c2c9-49aa-92da-4966a87d0fc2",
            "name": "Hormel Chili Regular",
            "lookup": "37600241939"
        },
        {
            "ref": "0accc616-8f8e-4661-8a33-1ec65f866935",
            "name": "Dinty Moore Beef Stew",
            "lookup": "37600246095"
        },
        {
            "ref": "9d7b065a-ff59-4076-9a13-b8d66fb1cee6",
            "name": "Hormel Chili Small Hormel",
            "lookup": "37600292092"
        },
        {
            "ref": "acfda70d-d8ec-43c2-9649-d5cbceff121b",
            "name": "Bacon, Black Label",
            "lookup": "37600351607"
        },
        {
            "ref": "ef6a6890-4798-4401-8ce2-3e6c390437c5",
            "name": "Pop Tarts Cookies n Cream",
            "lookup": "38000222450"
        },
        {
            "ref": "b43ec8a9-4ce0-4e69-a949-ef7243b8bbaa",
            "name": "Pop Tarts Cinnamon",
            "lookup": "38000222610"
        },
        {
            "ref": "81d15f6f-6b5e-446e-9e77-89f446ee375a",
            "name": "Pop Tarts Regular",
            "lookup": "38000222719"
        },
        {
            "ref": "c1158836-93cc-4155-b211-0e75de38c6c4",
            "name": "Pop Tarts Smore",
            "lookup": "38000222795"
        },
        {
            "ref": "7e874be3-311d-422a-a293-0d70cdecc3f0",
            "name": "Dog - Purina Pro Plan Wet Food",
            "lookup": "38100192745"
        },
        {
            "ref": "ac76e85e-bbb2-41cb-a4b6-e842f1ccc3ca",
            "name": "Band-Aid 8ct",
            "lookup": "381370000000"
        },
        {
            "ref": "a6ef78a6-7875-44a4-aea1-a951f4578a1b",
            "name": "Aussie Miracle Moist Shampoo`",
            "lookup": "381519000000"
        },
        {
            "ref": "dafab26d-6b06-4fe6-8f38-7b941ca54b3e",
            "name": "Bushes Baked Beans",
            "lookup": "39400016144"
        },
        {
            "ref": "c676ed14-9973-47ae-b04c-457cad9bedde",
            "name": "Paper Plates",
            "lookup": "39938647193"
        },
        {
            "ref": "65a77699-a5b9-4cd9-b7a3-17c9e9ad05a5",
            "name": "Hummus Roasted red pepper Regular",
            "lookup": "40822011549"
        },
        {
            "ref": "02d89fa9-09c0-4f6e-8df1-cbf117827056",
            "name": "Clartin Liquid Gel",
            "lookup": "41100806109"
        },
        {
            "ref": "66091b51-33e4-408a-89e3-77bb0cc21d92",
            "name": "Clartin Childrens, Liquid",
            "lookup": "41100811028"
        },
        {
            "ref": "3b684ced-1e38-4443-81e4-4e1bf4108905",
            "name": "Hydrocortisone cream Water proof",
            "lookup": "41167002506"
        },
        {
            "ref": "568a8047-053b-407f-81f4-d559e3604fa7",
            "name": "Hydrocortisone cream Regular",
            "lookup": "41167002704"
        },
        {
            "ref": "5cfbe511-1acf-4e71-834d-121913b7f0cc",
            "name": "Bread, Natures Own Wheat HEB Wheat",
            "lookup": "41220031085"
        },
        {
            "ref": "699ed77b-3837-4b93-8828-56ebe580c301",
            "name": "Texas Deviled egg Potato Salad Potato salad",
            "lookup": "41220031641"
        },
        {
            "ref": "fc012a26-9ce6-4ff4-b768-9db3d7d0326e",
            "name": "Soup, Broccoli cheddar Regular",
            "lookup": "41220081387"
        },
        {
            "ref": "8609cfc5-a5e1-4c26-bd37-01a3c7f45bfa",
            "name": "Nestfresh Eggs Large Brown",
            "lookup": "41220129126"
        },
        {
            "ref": "6f67fc43-3569-4946-9452-9c93ccc79386",
            "name": "Nestfresh Eggs Soy Free Large Brown",
            "lookup": "41220129133"
        },
        {
            "ref": "64a8a5b7-00a4-4a30-ae9e-fff936d6273e",
            "name": "Cheese, Sliced Tilamook HEB Cheddar",
            "lookup": "41220194742"
        },
        {
            "ref": "c4f91806-99ed-4158-b78b-a68614add8e6",
            "name": "Toothbrush Combo Kit Colgate",
            "lookup": "41220246762"
        },
        {
            "ref": "47a6b523-5805-49f3-8626-6afcf12a6e94",
            "name": "Marks BBq sauce Regular",
            "lookup": "41220257317"
        },
        {
            "ref": "c09f1729-2a5f-41f0-8a68-15c972694a07",
            "name": "Jimmy morning Combo Bacon Egg Rounds",
            "lookup": "41220306534"
        },
        {
            "ref": "7388d74d-24c9-4b1e-a879-03dce86f17b5",
            "name": "Mixed Berries Trio",
            "lookup": "41220313440"
        },
        {
            "ref": "fb5249c2-79d4-4897-950c-258beaae8e37",
            "name": "Mixed Berries Regular",
            "lookup": "41220313501"
        },
        {
            "ref": "74aedab0-7e32-4697-9d8b-ea94116acdc6",
            "name": "Bacon Jam Jalapeno",
            "lookup": "41220343119"
        },
        {
            "ref": "47d36a3d-f079-466c-b8e9-b7ceeef6c47c",
            "name": "Nail File",
            "lookup": "41220348091"
        },
        {
            "ref": "9c913503-d481-44d8-ba8f-11b49ed4a59e",
            "name": "Potato, Baby",
            "lookup": "41220500314"
        },
        {
            "ref": "07f4ef1b-d232-4d61-8f4a-758635f6dae7",
            "name": "Potato 5 LB",
            "lookup": "41220608560"
        },
        {
            "ref": "0eba5560-0884-4e88-9f82-927ab1e09fbb",
            "name": "Lemons and Limes",
            "lookup": "41220703579"
        },
        {
            "ref": "ee5c4871-b3e8-41bd-8691-402e6b612a21",
            "name": "Bun, hamburger",
            "lookup": "41220771776"
        },
        {
            "ref": "b01824b2-8181-4ddb-855c-c3ce364ee8f3",
            "name": "Bean Dip, Jalapeno Cheddar Regular HEB",
            "lookup": "41220803996"
        },
        {
            "ref": "e69901df-d4a7-4a51-8335-ae0e907eab9c",
            "name": "Bean Dip, Jalapeno Cheddar Jalapeno HEB",
            "lookup": "41220804009"
        },
        {
            "ref": "d6fb9f45-12e6-49d7-a8bd-c20fabf7412f",
            "name": "Marks BBq sauce sweet n spicy",
            "lookup": "41220807994"
        },
        {
            "ref": "2da4c8f3-3224-4286-81cc-fe8a81b3695b",
            "name": "Soup, Broccoli cheddar Tortilla",
            "lookup": "41220829514"
        },
        {
            "ref": "c4dffcb8-9bdf-4f92-8d3d-2bc783adacdd",
            "name": "Sun Screen HEB SPF 50 Regular",
            "lookup": "41220899104"
        },
        {
            "ref": "ec95956d-8047-42af-991b-597e1aae0d9f",
            "name": "Sun Screen HEB SPF 50 SPF 70",
            "lookup": "41220899111"
        },
        {
            "ref": "2fe5491d-aa5f-4142-8a12-93d2e55fb876",
            "name": "Tomato, Cherry Cherub",
            "lookup": "41220899883"
        },
        {
            "ref": "29ada07b-6d99-441c-b26b-3989043d7de7",
            "name": "Hummus Roasted red pepper Tomato basil",
            "lookup": "41220915125"
        },
        {
            "ref": "90801781-258e-49f7-bb70-ea077fe3421a",
            "name": "Yaco Coconut water HEB",
            "lookup": "41220949410"
        },
        {
            "ref": "fad24cd7-8c3e-41cd-a7e1-8d7016e2ca47",
            "name": "Snack Plate, Heb",
            "lookup": "41220964673"
        },
        {
            "ref": "b6b306c7-08df-491f-9cbb-877684f13613",
            "name": "Tamales de Pollo Regular",
            "lookup": "41220978717"
        },
        {
            "ref": "282b81f5-8d59-4905-8e12-374f25c0a4d4",
            "name": "Tamales de Pollo Tamales cream cheese",
            "lookup": "41220978724"
        },
        {
            "ref": "43589154-5fe8-484c-9e9f-4cf26f73646d",
            "name": "Marks Salsa Medium",
            "lookup": "41220983377"
        },
        {
            "ref": "7ab790c1-1bfa-475c-8e55-f37d130e749b",
            "name": "Marks Salsa Hot",
            "lookup": "41220983384"
        },
        {
            "ref": "f01abdc7-760a-40e8-b705-a2a9b34a5605",
            "name": "Bacon Jam Regular",
            "lookup": "41220991914"
        },
        {
            "ref": "a730857b-4f5a-491e-a349-036c375ccc98",
            "name": "Duracell batteries",
            "lookup": "41333415017"
        },
        {
            "ref": "c9a1cb69-15bd-406f-af3b-ec2fbcf40821",
            "name": "Panko",
            "lookup": "41390050046"
        },
        {
            "ref": "ad3f4aaf-cb0c-462f-948a-f52294fdc956",
            "name": "kikoman Soy Sauce",
            "lookup": "4139247"
        },
        {
            "ref": "ef106c63-e93c-428f-9d2e-286b13517ef6",
            "name": "Tortilla, Flour La Banderita San antonio",
            "lookup": "41460358102"
        },
        {
            "ref": "4a6feb23-22e7-4c4a-b1d0-c1f9801dab7e",
            "name": "French's Classic Yellow Mustard",
            "lookup": "41500007007"
        },
        {
            "ref": "da514a26-9d45-4686-a376-7b23122cb5a0",
            "name": "Frank's Red Hot Wing Sauce",
            "lookup": "41500805023"
        },
        {
            "ref": "a55dedb3-744b-48b0-9a7c-299041e938b2",
            "name": "Amy's Organic Mac and cheese",
            "lookup": "42272000302"
        },
        {
            "ref": "e49be291-cc2a-4203-9925-5ade6fe2ee3c",
            "name": "Amy's Organic Indian Mattar Paneer",
            "lookup": "42272000586"
        },
        {
            "ref": "74f2cbfa-e325-4362-851b-cb295cc81245",
            "name": "Amy's Organic Palak Paneer",
            "lookup": "42272000593"
        },
        {
            "ref": "da568aed-f818-4735-b8d8-efe2faadef6e",
            "name": "Amy's Organic Regular",
            "lookup": "4227200080"
        },
        {
            "ref": "bc0a1553-b906-4826-bd25-a08312c39a5c",
            "name": "Amy's Organic Pad Thai",
            "lookup": "42272009244"
        },
        {
            "ref": "62559b35-147f-471b-a2b7-23b2153c165f",
            "name": "Amy's Organic Chinese noodle",
            "lookup": "42272010059"
        },
        {
            "ref": "0c538da5-aabd-4db1-8788-04d99471eb5d",
            "name": "Amy's Organic Chile Relleno",
            "lookup": "4227201128"
        },
        {
            "ref": "efbeb365-8354-4693-8d61-2a4cf0b39711",
            "name": "Hot Pockets, Pepperoni",
            "lookup": "43695071436"
        },
        {
            "ref": "80c2202e-aa2f-4334-a074-9fc06f6a3c7c",
            "name": "Crackers, Ritz Regular",
            "lookup": "44000031114"
        },
        {
            "ref": "9ae4b96a-ab86-4e80-aaf2-428d2dfa407e",
            "name": "Chips Ahoy",
            "lookup": "44000033385"
        },
        {
            "ref": "d7b12299-974d-41a1-a430-0e03b70f9e43",
            "name": "Truiscuits",
            "lookup": "44000050986"
        },
        {
            "ref": "1bf05c93-dafb-45c0-a919-2a4d0b8fe17a",
            "name": "Oreo, Double stuff Normal",
            "lookup": "44000060114"
        },
        {
            "ref": "708ea691-74d6-413a-a85f-fef43fa86343",
            "name": "Oreo, Double stuff Regular",
            "lookup": "44000060138"
        },
        {
            "ref": "5eff128b-3bb9-467c-ba65-443ebc24174a",
            "name": "Oreo, Double stuff Double Stuff Family",
            "lookup": "44000060251"
        },
        {
            "ref": "7e22f70f-98bb-4f20-a1c3-64f427ab0216",
            "name": "Wheat Thins Family Size",
            "lookup": "44000069216"
        },
        {
            "ref": "7b4e1af7-198d-4ae8-81d0-af221b858ce7",
            "name": "Wheat Thins Regular",
            "lookup": "44000069230"
        },
        {
            "ref": "7fab4dd6-03f3-4177-a9a2-c8225183e9d2",
            "name": "Wheat Thins Reduced fat",
            "lookup": "44000069254"
        },
        {
            "ref": "83518a94-1774-4d45-bf40-b73246d1e614",
            "name": "Crackers, Ritz Sandwich crackers",
            "lookup": "44000882112"
        },
        {
            "ref": "9663f208-cea0-405a-8b15-3ec11cf8a667",
            "name": "Muffin, Blueberry Abe Blueberry",
            "lookup": "44261490446"
        },
        {
            "ref": "00596a9e-08d9-4830-8df9-07116cca354f",
            "name": "Muffin, Blueberry Abe Regular",
            "lookup": "44261490460"
        },
        {
            "ref": "5468226b-2473-48ad-8aff-bd089a77b5db",
            "name": "Muffin, Blueberry Abe Chocolate Chip",
            "lookup": "44261490545"
        },
        {
            "ref": "35964541-ae60-46a4-8aed-c3a7e4029d82",
            "name": "Hillshire Snack Plate Regular",
            "lookup": "44500062458"
        },
        {
            "ref": "e5479238-ceba-4dc3-ab08-7233bb8b5f6d",
            "name": "Hillshire Snack Plate salami",
            "lookup": "44500201819"
        },
        {
            "ref": "4a234660-886f-4d45-be2d-2bfa40c2f8ff",
            "name": "Hillshire Snack Plate Blue",
            "lookup": "44500201826"
        },
        {
            "ref": "b805b7b9-3076-4215-8705-c58059503e9b",
            "name": "Clorox Wipes",
            "lookup": "44600016283"
        },
        {
            "ref": "fa493dd6-ae20-4aa2-b6b7-b1df4b85de6b",
            "name": "Lunchables Ham Big",
            "lookup": "44700006771"
        },
        {
            "ref": "87b33d5c-ad78-460e-9555-650f76326b85",
            "name": "Lunchables Chicken Dunkers",
            "lookup": "44700006801"
        },
        {
            "ref": "0d3d9b96-476c-4495-a13e-ff579ebdd7ca",
            "name": "Lunchables ham and swiss",
            "lookup": "44700024546"
        },
        {
            "ref": "1f59bc01-dc2a-435d-b06b-22d51dc7705e",
            "name": "Lunchables Regular",
            "lookup": "44700024553"
        },
        {
            "ref": "f027b88a-289a-420b-838d-ff205aa5eec4",
            "name": "Lunchables Sub Sandwich, Turkey Cheese",
            "lookup": "44700069561"
        },
        {
            "ref": "823be6a2-973e-4f67-9e75-8c15e72d5d00",
            "name": "Lunchables Pizza",
            "lookup": "44700361139"
        },
        {
            "ref": "adcc6fe1-56e3-49be-8ab7-26bf8b036447",
            "name": "Peter Pan Creamy Peanut Butter Regular",
            "lookup": "45300000503"
        },
        {
            "ref": "76d2f769-e92c-42c9-b6c0-74dafbb37273",
            "name": "Peter Pan Creamy Peanut Butter Peanut Butter Small",
            "lookup": "45300005492"
        },
        {
            "ref": "578912ed-1b48-4e55-a244-d1144da94f52",
            "name": "Lobster mac n Cheese",
            "lookup": "45425593263"
        },
        {
            "ref": "2959e048-1f9d-4e74-b767-6195d3b03a12",
            "name": "OFF-sweat resistant",
            "lookup": "46500018107"
        },
        {
            "ref": "4fe4c2c5-c429-40d4-bede-30b0741ce72e",
            "name": "OFF Family Care",
            "lookup": "46500018350"
        },
        {
            "ref": "6e587777-514d-400a-8b84-24c268fb0140",
            "name": "Air Freshner",
            "lookup": "46500733321"
        },
        {
            "ref": "291ea482-ffd7-4d77-86cf-8f7fe19892b2",
            "name": "Mosquito Coil",
            "lookup": "46500752049"
        },
        {
            "ref": "2ae3c98c-2ba4-4ab0-aae9-0bace91c05e3",
            "name": "OFF! Insect Repellant",
            "lookup": "46500818813"
        },
        {
            "ref": "905ae3b6-35a9-4ec1-9e20-987d0f4ddcfc",
            "name": "Jalapeno Poppers",
            "lookup": "46704039106"
        },
        {
            "ref": "6bc16142-bf79-4012-94af-e3baebc93eeb",
            "name": "Dentek Easy Brush Large Dentek",
            "lookup": "47701001950"
        },
        {
            "ref": "f8c9dc0b-3295-4462-815b-1d1e1bc04f02",
            "name": "Dentek Easy Brush Regular",
            "lookup": "47701002032"
        },
        {
            "ref": "2508dddd-0fb2-4ba1-b035-ec6a9fe4c8fe",
            "name": "Bagel Plain",
            "lookup": "48121277079"
        },
        {
            "ref": "bf81b3c7-bd74-4125-bc91-4ac2d9d6bd00",
            "name": "Dots pretzels Snack Factory",
            "lookup": "49508006008"
        },
        {
            "ref": "266f216c-21b8-4434-b57d-aefecd0e24ee",
            "name": "Cookie Dough, Nestle",
            "lookup": "50000009268"
        },
        {
            "ref": "8795a21c-af4a-4f9a-a3fc-297e1b89ec27",
            "name": "Creamer, Vanilla Latte",
            "lookup": "50000979677"
        },
        {
            "ref": "fb26915e-40e3-443a-93e0-a8bb6b23a448",
            "name": "Dimetapp, Children's cold and Cough",
            "lookup": "5002666000"
        },
        {
            "ref": "76d375b4-247f-47ba-9e2e-f8c30014dae6",
            "name": "Batiste Dry Shampoo`",
            "lookup": "5010720000000"
        },
        {
            "ref": "2a9252a5-3224-49f9-9c3b-637196f88973",
            "name": "Hot Dog Buns Regular",
            "lookup": "50400751163"
        },
        {
            "ref": "ea06e74b-bc3e-49c1-9f11-038c6601ab8e",
            "name": "V8 Splash",
            "lookup": "51000125736"
        },
        {
            "ref": "732d1b36-8b84-4fe3-a010-3e5bcf3ac34b",
            "name": "Spaghettios with meatball",
            "lookup": "51000233141"
        },
        {
            "ref": "597bc565-9651-4172-92ed-983996144223",
            "name": "Well Yes Soup Squash & Sweet Potato",
            "lookup": "51000246332"
        },
        {
            "ref": "29d00f33-767f-428d-9533-11ab93cedd35",
            "name": "Well Yes Soup Corn & Roasted Poblano",
            "lookup": "51000246356"
        },
        {
            "ref": "6b8eb4f9-c75f-440f-b3fc-78a35b4c7c27",
            "name": "Well Yes Soup Tomato Basil",
            "lookup": "51000250346"
        },
        {
            "ref": "22ef257a-eae0-44c1-ab4e-f90af0c6c762",
            "name": "Shaving Cream",
            "lookup": "51009000256"
        },
        {
            "ref": "1218a6bd-0abe-4f0f-8f81-bb2f5941ba2c",
            "name": "Lint Roller",
            "lookup": "51141598581"
        },
        {
            "ref": "7c2d7121-c516-4e8a-a163-64bff6780e59",
            "name": "Smuckers Goober Grape",
            "lookup": "51500000557"
        },
        {
            "ref": "85af8a80-4722-400f-a098-21ee89a24bcb",
            "name": "Smuckers Goober Strawberry",
            "lookup": "51500000656"
        },
        {
            "ref": "6947d0b7-87ff-4870-b1e8-b8b3f634bdac",
            "name": "Jam, Blackberry Regular",
            "lookup": "51500006849"
        },
        {
            "ref": "10265dd2-eb36-468a-af9f-9f65ea43c97b",
            "name": "Jam, Blackberry Strawberry",
            "lookup": "51500012291"
        },
        {
            "ref": "37454c42-a0ab-420b-8c52-55bcfd97b71f",
            "name": "Seasoning, McCormick Chili powder Paprika",
            "lookup": "52100004488"
        },
        {
            "ref": "5bd13e9e-dfed-48a4-9001-8823ac1ec0a8",
            "name": "Seasoning, McCormick Chili powder Pepper",
            "lookup": "52100030043"
        },
        {
            "ref": "5d749d93-9867-4a9f-86ae-d25fe01e83ad",
            "name": "Vanilla Extract",
            "lookup": "52100070865"
        },
        {
            "ref": "bc839bed-4784-4ddb-a1fe-63fab7f7994f",
            "name": "Seasoning, McCormick Chili powder Regular",
            "lookup": "52100071084"
        },
        {
            "ref": "1599d361-da2c-46ad-9fc9-2cdc034ff45a",
            "name": "Seasoning, McCormick Chili powder Cinnamon",
            "lookup": "52100071091"
        },
        {
            "ref": "af0b8598-8932-4f78-a155-fc03950fb7bb",
            "name": "Salt Grinder",
            "lookup": "52100746029"
        },
        {
            "ref": "45300437-56d3-4d03-b66f-f10ac8e68af6",
            "name": "Duke's Mayo",
            "lookup": "52500080082"
        },
        {
            "ref": "1b1bc869-4f49-4a3d-bd71-ff01868ec10b",
            "name": "Seasoning, McCormick Chili powder Garlic Powder",
            "lookup": "5259315"
        },
        {
            "ref": "8c809c24-f34c-495d-ba11-7292812c6e6c",
            "name": "Seasoning, McCormick Chili powder Onion powder",
            "lookup": "52593615"
        },
        {
            "ref": "72f88d27-d790-4a49-88cf-8e1e2b5425e3",
            "name": "Seasoning, McCormick Chili powder Oregano",
            "lookup": "5269613"
        },
        {
            "ref": "7e2216e5-4779-4f52-847a-83addbfcce16",
            "name": "Seasoning, McCormick Chili powder Basil",
            "lookup": "5271618"
        },
        {
            "ref": "bbf4922d-0328-4849-a4bf-077e1c6ce3fb",
            "name": "vlasic pickes",
            "lookup": "54100005601"
        },
        {
            "ref": "64366995-dcf6-48c8-bbad-7d0777fb81a5",
            "name": "A1 Steak Sauce",
            "lookup": "54400000092"
        },
        {
            "ref": "d0293837-7c76-4224-9845-858ff1bc403c",
            "name": "Ballpark Franks Angus Bun Size",
            "lookup": "54500101743"
        },
        {
            "ref": "50f34ebe-b426-437e-bcd8-cd81377c2e5b",
            "name": "Ballpark Franks Angus Regular",
            "lookup": "54500101750"
        },
        {
            "ref": "de14454c-f1dd-44a7-be2a-e156e3cdac27",
            "name": "Tasty Bite Variety White Rice",
            "lookup": "54800423330"
        },
        {
            "ref": "5b4b4af3-7d79-4a7a-b553-2e62625387f4",
            "name": "Ready Rice, Ben's Cilantro Lime",
            "lookup": "54800423446"
        },
        {
            "ref": "891c124c-cc4c-4e13-a06d-83d6f087359c",
            "name": "Natures path Coconut and cashew Choccolate",
            "lookup": "58449162070"
        },
        {
            "ref": "388e794d-3160-4a6c-9e71-14fb84a97e4d",
            "name": "Natures path Coconut and cashew Regular",
            "lookup": "58449172017"
        },
        {
            "ref": "d3588588-2c75-4752-a18d-15677ac29856",
            "name": "Nature's Path Organic Toaster Pastries Frosted Berry Strawberry",
            "lookup": "58449410003"
        },
        {
            "ref": "6d9850b4-e26d-4aef-a171-c4b47e8b84e4",
            "name": "Nature's Path Organic Toaster Pastries Granny's Apple Pie",
            "lookup": "58449410027"
        },
        {
            "ref": "51750a1f-192e-4fd0-b1eb-3bdc60fc08a4",
            "name": "Natures Path Organix cherry pomegranate",
            "lookup": "58449410157"
        },
        {
            "ref": "36319647-459c-4caa-b225-5ec058a9368b",
            "name": "Natures Path Organix wildberry acai",
            "lookup": "58449410225"
        },
        {
            "ref": "b462ae7a-284c-4729-aced-01b9e0ba2474",
            "name": "Cereal Nature's Path Regular",
            "lookup": "58449770206"
        },
        {
            "ref": "e876f389-14c7-4579-8800-afd791061f7d",
            "name": "Cereal Nature's Path maple pecan",
            "lookup": "58449771432"
        },
        {
            "ref": "1a084637-05a4-4894-9ecc-a51ceb55e976",
            "name": "Cereal Nature's Path Vanilla",
            "lookup": "58449771555"
        },
        {
            "ref": "4bd9cc36-a34d-48ff-8c05-9b37212b5153",
            "name": "Cereal Nature's Path small vanilla",
            "lookup": "58449890256"
        },
        {
            "ref": "a0aee1ba-0206-46ab-9c53-2a3059f48f92",
            "name": "Jack & Annie's Jackfruit Meatballs",
            "lookup": "5980600381"
        },
        {
            "ref": "0cb5220e-3017-42c8-b890-f9fd4d7d34d5",
            "name": "Jack & Annie's Buffalo Jackfruit Wings",
            "lookup": "5980600384"
        },
        {
            "ref": "2b5e6854-f352-4caf-ab00-30640bfc5bad",
            "name": "Beef, Ground Raw 2F",
            "lookup": "60006727307"
        },
        {
            "ref": "fcee9e26-7bfd-4b4d-add0-0bff442f80cd",
            "name": "Powerup almond crunch",
            "lookup": "609723000000"
        },
        {
            "ref": "517e8293-cd82-4882-b977-2a5c4dbe4968",
            "name": "Chicken Stock",
            "lookup": "611443000000"
        },
        {
            "ref": "20dd7928-ea58-4f82-b703-bdcf03e3f3e6",
            "name": "Tajin",
            "lookup": "633148000000"
        },
        {
            "ref": "9c8438eb-2f82-4b3f-8b7c-f8c0d12ea913",
            "name": "Tomato, Rotel",
            "lookup": "64144282432"
        },
        {
            "ref": "c4b8277a-4818-4e46-984d-c7a385a68d7c",
            "name": "Syrup, Buttersworth",
            "lookup": "644210000000"
        },
        {
            "ref": "d108f007-b73b-40ac-8e2b-6f84df4ae3a5",
            "name": "Marks Salsa Roasted Salsa",
            "lookup": "657979000000"
        },
        {
            "ref": "8c519b47-41b1-4f9a-a723-1825a2f4bf00",
            "name": "Aussie Miracle Moist Deep Conditioner`",
            "lookup": "66400172822"
        },
        {
            "ref": "9c38b955-05cc-47e3-adb8-e7ae9b778548",
            "name": "Crest Toothbrush & Toothpaste Colgate combo",
            "lookup": "672935000000"
        },
        {
            "ref": "40deb11f-1dbe-4f68-9475-d8cfe4a415e3",
            "name": "Clear eyes Triple",
            "lookup": "678112000000"
        },
        {
            "ref": "2bb52382-c19d-4ebf-bde5-9e2e325a2d69",
            "name": "Baby Formula, Similac similac advance",
            "lookup": "70074559582"
        },
        {
            "ref": "4943d207-7110-4d88-939b-63ea2f6b3f7d",
            "name": "Pedialyte liquid Pedialyte packets 8 Pack",
            "lookup": "70074560915"
        },
        {
            "ref": "1eb8297c-b037-4027-84fa-c2dc530d4fae",
            "name": "Pedialyte liquid Regular",
            "lookup": "70074803364"
        },
        {
            "ref": "1f92aa36-c955-41ae-ba7e-00b016695b6b",
            "name": "Rice, Shrimp fried Tai Pai Regular",
            "lookup": "70077811311"
        },
        {
            "ref": "9d25cf7c-444f-47ae-a699-adca37bbb20c",
            "name": "Men's Disposable Razor BIC",
            "lookup": "70330713802"
        },
        {
            "ref": "998fc141-a501-48b7-868b-3ca0fa2b4da2",
            "name": "Ramen, Nissin Hot n Spicy Chicken reg",
            "lookup": "70662026038"
        },
        {
            "ref": "8825b803-d495-48af-9fb6-e948175900a3",
            "name": "Chow mein, Nissin",
            "lookup": "70662087237"
        },
        {
            "ref": "2f6127d4-9c0e-416a-b211-73f83db4a7cc",
            "name": "Ramen, Nissin Hot n Spicy Scorchn Shrimp",
            "lookup": "70662095027"
        },
        {
            "ref": "6e768c1a-4dcd-441b-a2a3-006ecc179809",
            "name": "Ramen, Nissin Hot n Spicy Molten Chicen",
            "lookup": "70662095034"
        },
        {
            "ref": "3755698e-6d55-4d37-afdb-403749900ec8",
            "name": "Ramen, Nissin Hot n Spicy Regular",
            "lookup": "70662096314"
        },
        {
            "ref": "bfa965d2-dd38-4762-9332-dca19dd8d57f",
            "name": "Ramen, Nissin Hot n Spicy Spicy Beef",
            "lookup": "70662096338"
        },
        {
            "ref": "3b4554d5-2244-4e55-b36c-8a6650d151ad",
            "name": "Tomato, Crushed",
            "lookup": "70796300035"
        },
        {
            "ref": "e7b0d235-6423-4b21-b7b6-fcdb840051a9",
            "name": "Ka'Me Udon Noodles",
            "lookup": "70844705577"
        },
        {
            "ref": "933ae0bf-752f-45ac-8848-493265b58a53",
            "name": "Bread - SaraLee Artesano Sliced Bread Mrs Bairds",
            "lookup": "70870000356"
        },
        {
            "ref": "31a4c2ba-2d54-4f43-bd07-5412e1e4de11",
            "name": "Bread, Natures Own Wheat Bairds White Bread",
            "lookup": "70870000523"
        },
        {
            "ref": "924f3698-b662-42b8-a942-d883fab5eacc",
            "name": "Hot Dog Buns Mrs bairds",
            "lookup": "70870003012"
        },
        {
            "ref": "ea612b79-6fd7-45b7-8ead-b395dacca8e8",
            "name": "Garlic, Chopped",
            "lookup": "7096983"
        },
        {
            "ref": "a92e23b5-7acb-4dc4-85ed-65b0b106e570",
            "name": "Beef and Bean Burritos",
            "lookup": "71007018688"
        },
        {
            "ref": "834cf0ec-2041-4173-8a6b-d2774c279781",
            "name": "Ranch",
            "lookup": "71100286540"
        },
        {
            "ref": "a59a206b-067b-4aee-a246-6caa22ff6f8a",
            "name": "Cornuts Ranch",
            "lookup": "71159000258"
        },
        {
            "ref": "ac763df1-2b38-4f8a-8477-4dec669e1353",
            "name": "Cornuts Chili Picante",
            "lookup": "71159000807"
        },
        {
            "ref": "47780d6d-3d49-429d-b367-20ee82226fe4",
            "name": "Dog - Rachael Ray Nutrish Treats Turkey Bacon",
            "lookup": "71190001702"
        },
        {
            "ref": "ba8f24f4-48ca-417f-a69f-1b4ca3d7aad5",
            "name": "Dog - Rachael Ray Nutrish Treats Chicken",
            "lookup": "71190006011"
        },
        {
            "ref": "d5455f2c-d33f-4ff4-b919-fac85001a12c",
            "name": "Dog - Rachael Ray Nutrish Treats Real Beef and Rice",
            "lookup": "71190006035"
        },
        {
            "ref": "4cce6a57-935d-42af-9887-d925af9de0da",
            "name": "Bagel Regular",
            "lookup": "71314069199"
        },
        {
            "ref": "7c17dcd9-ed52-42f2-8af1-d0fe29bf93b0",
            "name": "Bagel Cinnamon Rasin",
            "lookup": "71314069274"
        },
        {
            "ref": "553adb8b-aad0-4257-b3cf-42db4e761207",
            "name": "Zatarians Sausage gumbo Regular",
            "lookup": "71429002609"
        },
        {
            "ref": "df2dc0c0-c631-4251-9dbf-703b234e37d2",
            "name": "Zatarians Sausage gumbo Shrimp Alfredo",
            "lookup": "71429665613"
        },
        {
            "ref": "7d51c9eb-67c4-48e2-9008-62a7022dae4f",
            "name": "Salad, Avocado Ranch Regular",
            "lookup": "71430000915"
        },
        {
            "ref": "0d0e1364-3f32-4d6c-9a4a-4ceb1d626302",
            "name": "Salad, Avocado Ranch Cesar",
            "lookup": "71430008065"
        },
        {
            "ref": "e95a3954-aada-49bb-8f2c-61814ff88153",
            "name": "Salad, Avocado Ranch Summer",
            "lookup": "71430010730"
        },
        {
            "ref": "9eaafade-85aa-4105-b9a6-b817a26071d1",
            "name": "Lock master",
            "lookup": "71649264320"
        },
        {
            "ref": "c0e561f2-4e35-4fb8-8f95-fc4b2aad5b55",
            "name": "Steamed dupplings",
            "lookup": "717671273"
        },
        {
            "ref": "c0708b53-70be-4a08-9998-68f2841e2784",
            "name": "stone ground mustard",
            "lookup": "71828011028"
        },
        {
            "ref": "0a3cd0a7-84bf-4885-80a4-766510e68530",
            "name": "Sugar, Brown Granulated sugar",
            "lookup": "72100026006"
        },
        {
            "ref": "d6e4d3b8-4b7e-449c-8c67-c781d40d534f",
            "name": "Sugar, Brown Regular",
            "lookup": "72100066002"
        },
        {
            "ref": "3e606542-72f9-4a4d-89fc-4e3747d550ad",
            "name": "Coppertone 4 in 1 50 SPF Lotion",
            "lookup": "72140027476"
        },
        {
            "ref": "485e280b-4034-427c-ab27-57ae77f06a44",
            "name": "Coppertone 4 in 1 30 SPF Lotion",
            "lookup": "72140027612"
        },
        {
            "ref": "821db036-cd4f-497f-9c63-4c60b2ab3d06",
            "name": "Coppertone 4 in 1 50 SPF Spray",
            "lookup": "72140027643"
        },
        {
            "ref": "f920c46a-1283-4664-a204-a935fea2ce53",
            "name": "Coppertone 4 in 1 Spray SPF 50",
            "lookup": "72140035174"
        },
        {
            "ref": "3069e1c6-a737-4867-a61f-4bde0de1ecc9",
            "name": "Amore Grilled Peppers",
            "lookup": "72248710171"
        },
        {
            "ref": "9b7f3561-be93-4e0e-9e06-5b32d5c73e57",
            "name": "Bread, Natures Own Wheat Regular",
            "lookup": "72250037068"
        },
        {
            "ref": "52ccfb16-2315-4153-978a-1d3d72f8eec2",
            "name": "Pasta, Pappardelle Pappardelle",
            "lookup": "72368510828"
        },
        {
            "ref": "1278447d-5048-4ebc-99bb-a88fce9b6ea9",
            "name": "Delallo Potato Gnocchi",
            "lookup": "72368510972"
        },
        {
            "ref": "3c1ca56a-96e1-49b3-94f3-0f7ae7d4b0e2",
            "name": "Flour Tortilla, Don Pedro",
            "lookup": "725304000000"
        },
        {
            "ref": "0095fa48-4a9f-4a86-a564-b4afc9f78d8c",
            "name": "Butter, Falfurrias Sweet Cream",
            "lookup": "72732000016"
        },
        {
            "ref": "38523115-e440-4ae0-8ad8-c5c74123ec8a",
            "name": "Butter, Falfurrias Regular",
            "lookup": "72732990010"
        },
        {
            "ref": "c78b4326-b54e-4764-8abe-1bab17bcd62b",
            "name": "Terra Chips",
            "lookup": "728229000000"
        },
        {
            "ref": "fabadca0-a85d-4574-b541-130c640e7b61",
            "name": "Cheese, Sliced Tilamook Sharp Cheddar",
            "lookup": "72830030021"
        },
        {
            "ref": "68e17813-518c-4a5c-ab7b-f06b8ad848b3",
            "name": "Cheese, Sliced Tilamook pepper jack",
            "lookup": "72830030137"
        },
        {
            "ref": "a9bb96a5-b434-4fbe-b29a-0fccca9e0cb8",
            "name": "Cheese, Sliced Tilamook Regular",
            "lookup": "72830031110"
        },
        {
            "ref": "fff63b45-6414-4179-a9e0-2bad6fed9b4b",
            "name": "Tampons",
            "lookup": "73010004542"
        },
        {
            "ref": "32e68605-d388-465c-81fb-dffa436dbf21",
            "name": "Sour Cream",
            "lookup": "73420000110"
        },
        {
            "ref": "86a6be6d-6200-4fba-b301-2c25fd76f1a6",
            "name": "Cottage Cheese",
            "lookup": "73420516406"
        },
        {
            "ref": "b99626b5-fe17-42ae-897f-e9819077dc02",
            "name": "Mozzarella Snacks",
            "lookup": "73480006855"
        },
        {
            "ref": "19a7725d-0186-47a3-9afa-25f1a31b9c35",
            "name": "Nestfresh Eggs Regular",
            "lookup": "736921000000"
        },
        {
            "ref": "2ec04c4b-7ba8-4027-9d33-b8bf83a5d7e4",
            "name": "Playing cards",
            "lookup": "73854008089"
        },
        {
            "ref": "5105fdab-1561-4d05-a77c-ddd8c264135b",
            "name": "Matcha Stick",
            "lookup": "741391000000"
        },
        {
            "ref": "927d76b7-6a85-4753-907b-63d4591adc14",
            "name": "Oatmeal Cup, Kathleen's Brown Sugar",
            "lookup": "74269278206"
        },
        {
            "ref": "cf35195c-ac19-4626-9c82-2457ffbce661",
            "name": "Kathleens Quick oat",
            "lookup": "742693000000"
        },
        {
            "ref": "d7115be5-1fe5-47cd-8f2c-17cecf85b4a2",
            "name": "Muffin, Vanilla Bimbo Blue",
            "lookup": "74323002447"
        },
        {
            "ref": "5332e4ff-be8b-423f-9986-b6743820e422",
            "name": "Donuts, Sugar",
            "lookup": "74323028140"
        },
        {
            "ref": "29bd060e-abc4-474d-bbac-ae6e76d60af5",
            "name": "Muffin, Vanilla Bimbo Regular",
            "lookup": "74323028164"
        },
        {
            "ref": "ea728b95-24d5-4fd3-87cc-621dafba5d15",
            "name": "Muffin, Vanilla Bimbo Loaf cake",
            "lookup": "74323081251"
        },
        {
            "ref": "8fce49fd-b905-4119-85c2-b620ff92bcb3",
            "name": "Muffin, Vanilla Bimbo small Buenos",
            "lookup": "74323091045"
        },
        {
            "ref": "e52cadf4-6d24-4ee4-af54-e72a19b00dff",
            "name": "Nitro, Bimbo",
            "lookup": "74323091199"
        },
        {
            "ref": "110c0f13-b8ef-41e5-a9d5-4bceacb924dc",
            "name": "Coffee, cafe bustello",
            "lookup": "74471000500"
        },
        {
            "ref": "a5c1a83b-8556-4739-bd7f-069755926838",
            "name": "Tasty Bite Variety STicky",
            "lookup": "74892400309"
        },
        {
            "ref": "70ae61bd-298e-45be-a723-fea9a1870ebd",
            "name": "Guacamole Dip",
            "lookup": "7503030000000"
        },
        {
            "ref": "5346af88-7a51-413f-b4df-a8147606ca61",
            "name": "Tomato, Cherry Regular",
            "lookup": "751667000000"
        },
        {
            "ref": "f3c43e5c-db62-4c67-b049-808bd96311d9",
            "name": "PopCorn",
            "lookup": "76150232097"
        },
        {
            "ref": "fe227ee2-3a91-4dfc-a2d1-aed20b1b750e",
            "name": "Oil, Canola",
            "lookup": "761721000000"
        },
        {
            "ref": "2dea7183-a4dd-4c17-ab76-bf5c65f36bb0",
            "name": "Coffee, Starbucks Sumatra Colmbia",
            "lookup": "762111000000"
        },
        {
            "ref": "919d6d3d-1a2c-4b31-8a83-d449a1654329",
            "name": "Coffee, Starbucks Sumatra Regular",
            "lookup": "762112000000"
        },
        {
            "ref": "0a4e88be-23d7-4cc9-ad1f-d02274e1d354",
            "name": "Emergen-C Individual Packs",
            "lookup": "76314306466"
        },
        {
            "ref": "0e6964ee-46f5-4534-a380-197cb4eb4fb3",
            "name": "Jalapeno, Canned",
            "lookup": "76397009070"
        },
        {
            "ref": "39ed11c7-42d3-4c75-9f4a-2d7308bad688",
            "name": "Barilla Ready Pasta, Gemelli Rotini",
            "lookup": "76808011784"
        },
        {
            "ref": "e18c8462-52eb-4464-8a20-9f8f59a5afca",
            "name": "Barilla Ready Pasta, Gemelli Regular",
            "lookup": "76808011807"
        },
        {
            "ref": "6b5e7ff2-5fcf-406f-b334-20862dba306b",
            "name": "Pasta, Spaghetti 1 LB",
            "lookup": "76808280081"
        },
        {
            "ref": "fb24bf44-ceaf-418f-946c-45d2f5057c75",
            "name": "Pasta, Spaghetti Regular",
            "lookup": "76808523195"
        },
        {
            "ref": "21096e5f-74de-4312-a89e-0a3c8951aeff",
            "name": "Ben & Jerrys Milk Cookies Non dairy",
            "lookup": "76840002283"
        },
        {
            "ref": "8148a64b-d28d-47b9-b461-6f7f9e8029af",
            "name": "Ben & Jerrys Churros",
            "lookup": "76840003860"
        },
        {
            "ref": "61f0cb83-954a-43f1-9f05-1e405b03ae39",
            "name": "Ben & Jerrys NY Super Chunk",
            "lookup": "76840100149"
        },
        {
            "ref": "79f91d6b-4dfd-45c6-a19f-a702da7cfdbe",
            "name": "Ben & Jerrys cherry garcia",
            "lookup": "76840100156"
        },
        {
            "ref": "9abcaccb-7c43-4acc-9702-ad1a17eb0e1a",
            "name": "Ben & Jerrys Chunky Monkey",
            "lookup": "76840100354"
        },
        {
            "ref": "f363241f-2ae6-48a0-b343-5fef0a001c95",
            "name": "Ben & Jerrys Big Brownie",
            "lookup": "76840100477"
        },
        {
            "ref": "79244e50-a4e8-41a7-9e40-b69b28fb3a69",
            "name": "Ben & Jerrys Big cookie dough",
            "lookup": "76840100583"
        },
        {
            "ref": "2893d0f0-1a9c-4ffd-91ea-13c33c0e32df",
            "name": "Ben & Jerrys Half Baked Large",
            "lookup": "76840101320"
        },
        {
            "ref": "1ef412d4-947d-428a-98fe-7eee2e8e4cc2",
            "name": "Ben & Jerrys Americone Dream",
            "lookup": "76840135554"
        },
        {
            "ref": "bc474e07-ba9f-4dc5-9726-e3e700a450c5",
            "name": "Ben & Jerrys cherry garcia small",
            "lookup": "7684020015"
        },
        {
            "ref": "8d8038fe-4443-4485-a77d-5cd9655c2acf",
            "name": "Ben & Jerrys Cherry Garcia sm",
            "lookup": "76840200153"
        },
        {
            "ref": "adbeb7eb-7621-41da-85da-48753c70d7c0",
            "name": "Ben & Jerrys Brownie",
            "lookup": "76840200160"
        },
        {
            "ref": "7d83768e-9f7c-47cd-8781-7f9322cf113b",
            "name": "Ben & Jerrys Cookie Dough",
            "lookup": "76840200276"
        },
        {
            "ref": "b4b80531-5377-4b38-900a-6424b2aeda98",
            "name": "Ben & Jerrys Strawberry cheescake",
            "lookup": "76840253302"
        },
        {
            "ref": "cfe6abfa-2d25-47fd-9c1c-dbd9a9b4fe61",
            "name": "Ben & Jerrys Brownie Batter Pint",
            "lookup": "76840529872"
        },
        {
            "ref": "2202fa11-3967-482b-a016-a79951647b22",
            "name": "Bean Dip, SR",
            "lookup": "7707900436"
        },
        {
            "ref": "2530159c-eaed-4e12-936e-7d69e6f295e2",
            "name": "Jimmy morning Combo Regular",
            "lookup": "77900114960"
        },
        {
            "ref": "35d5cdc8-d1d9-4b5a-a0d0-dd6d1512211a",
            "name": "Sausage, Patties cooked",
            "lookup": "77900192098"
        },
        {
            "ref": "f6ae7a89-fe6a-4353-822f-a6ad30eafe8e",
            "name": "Sausage Breakfast in a cup Jimmy Dean meat lovers",
            "lookup": "7790065046"
        },
        {
            "ref": "c0b0bd0d-9d8d-4d5c-a0f6-dd2144e79ad6",
            "name": "Sausage Breakfast in a cup Jimmy Dean Sausage",
            "lookup": "77900650475"
        },
        {
            "ref": "e734dffe-5aad-4753-9d24-d922253f5ee9",
            "name": "Sausage Breakfast in a cup Jimmy Dean Bacon",
            "lookup": "77900650482"
        },
        {
            "ref": "be7ae040-af35-45bd-8508-951e65e78631",
            "name": "Clam Chowder, Blount 30 oz Regular",
            "lookup": "77958530200"
        },
        {
            "ref": "f7bb4309-c379-4402-8c25-577c39fab7b6",
            "name": "Clam Chowder, Blount 30 oz Lobster Bisque",
            "lookup": "77958530309"
        },
        {
            "ref": "91c880c7-8036-4d85-99c2-0c5618961fe8",
            "name": "Panera Soup- Autumn Squash Regular",
            "lookup": "77958690133"
        },
        {
            "ref": "81f132b1-3a11-4245-b03c-672d622beb81",
            "name": "Panera Soup- Autumn Squash New England Clam Chowder",
            "lookup": "77958690201"
        },
        {
            "ref": "1d677cd8-b2c2-4c7f-abd0-5c1c0823f05b",
            "name": "Panera Soup- Autumn Squash chicken noodle",
            "lookup": "77958690416"
        },
        {
            "ref": "f900ffc4-f723-4ac8-b42a-1700deb4b0bb",
            "name": "Panera Soup- Autumn Squash Chicken Tortilla",
            "lookup": "77958690645"
        },
        {
            "ref": "bb8ab9bd-8f6f-4e01-924c-1c143649143f",
            "name": "Panera Soup- Autumn Squash Mac and Cheese",
            "lookup": "77958692496"
        },
        {
            "ref": "2ce4e067-b571-4936-8921-7bcfd8b8231a",
            "name": "Panera Soup- Autumn Squash Baked potato",
            "lookup": "77958694148"
        },
        {
            "ref": "dba872c4-b376-40cd-90ef-58439a124b6f",
            "name": "Panera Soup- Autumn Squash Broc Cheddar",
            "lookup": "77958694919"
        },
        {
            "ref": "8021a8fb-12e4-4deb-8a0d-587f8c612b43",
            "name": "Panera Soup- Autumn Squash Wild Rice",
            "lookup": "77958697699"
        },
        {
            "ref": "41bc9778-2dc5-4738-93d2-d1bcc3c9fb90",
            "name": "Baby Food Pouch Apple Bannana Berries",
            "lookup": "7804640000000"
        },
        {
            "ref": "fec5bfdc-5532-4638-97fe-e142e9856ddf",
            "name": "Isadora Refried Beans",
            "lookup": "781624000000"
        },
        {
            "ref": "9ca23232-207b-42d5-a86a-cf2c86409feb",
            "name": "Peanuts Regular",
            "lookup": "78264082247"
        },
        {
            "ref": "f829adfa-2e90-4d70-8a7b-6ccc34007b5a",
            "name": "Seasoning, Jack daniel",
            "lookup": "78342017574"
        },
        {
            "ref": "525afaf1-75c0-4942-8843-1f55629ce020",
            "name": "LLoyds BBQ Chicken Regular",
            "lookup": "78929335862"
        },
        {
            "ref": "a0e35067-c31f-4b5a-bed2-c099b6c3da70",
            "name": "LLoyds BBQ Chicken Beef",
            "lookup": "78929335886"
        },
        {
            "ref": "021742e1-ab44-4942-a1a4-34e37d5d0c40",
            "name": "Burt's Bees Lip Balm Regular",
            "lookup": "792850000000"
        },
        {
            "ref": "deca11f4-c763-429a-b2a9-16262618e7e1",
            "name": "Face Mask Regular",
            "lookup": "792851000000"
        },
        {
            "ref": "dc9cbbce-7e3c-4f61-a136-2a4955911cf5",
            "name": "Deodorant, Womens Regular",
            "lookup": "79400017437"
        },
        {
            "ref": "c1b26476-8ba7-4713-ba3f-4f05dd22533d",
            "name": "Travel Size Degree Deodorant",
            "lookup": "79400152299"
        },
        {
            "ref": "d2829601-7ba0-4943-97b0-1e77584f10fd",
            "name": "Deodorant, Womens Other",
            "lookup": "79400457400"
        },
        {
            "ref": "f18b6ee5-7d9d-42a6-b23e-3ef42bf48f4d",
            "name": "Dove Men+Care 2in1 Shampoo & Conditioner`",
            "lookup": "79400475510"
        },
        {
            "ref": "e3aa1b04-f546-41b3-ad3b-af28c444be80",
            "name": "Aloe Vera Regular",
            "lookup": "79656001143"
        },
        {
            "ref": "156d4e38-c29a-4863-8242-4a4f193d4c74",
            "name": "Banana Boat Spray Sun Screen SPF 30",
            "lookup": "79656031782"
        },
        {
            "ref": "c16fa56b-0d46-4641-afbd-17810180735a",
            "name": "Banana Boat Spray Sun Screen SPF 50",
            "lookup": "79656031799"
        },
        {
            "ref": "07a445d1-a3a3-4b93-8ee5-aeff895aa72f",
            "name": "Banana Boat Sport Lotion Regular",
            "lookup": "79656049718"
        },
        {
            "ref": "39702be9-da56-48c4-8ef1-077366714e59",
            "name": "Banana Boat Sport Lotion SPF 50",
            "lookup": "79656049725"
        },
        {
            "ref": "0719ec60-36c2-4aba-82ee-79019bbc43cf",
            "name": "Lettuce",
            "lookup": "796632000000"
        },
        {
            "ref": "b3334ab6-dc4b-4137-8ebe-636a41a268c8",
            "name": "Starkist Lunch To-Go",
            "lookup": "80000495433"
        },
        {
            "ref": "ef111318-485f-4f9f-bb28-936a4ecd8154",
            "name": "Starkist Tuna Creations - Variety Herb & Garlic",
            "lookup": "80000513045"
        },
        {
            "ref": "ce20cebd-26d4-4f38-9869-c4fdc9dbd643",
            "name": "Starkist Tuna Creations - Variety Hickory smoked",
            "lookup": "80000513069"
        },
        {
            "ref": "b155895d-2d62-4597-b057-7cdd46ec2f1b",
            "name": "Starkist Tuna Creations - Variety Sweet & Spicy",
            "lookup": "80000513083"
        },
        {
            "ref": "d9253b68-6ad5-4ee0-a03d-6695fc9f685e",
            "name": "Starkist Tuna Creations - Variety Lemon Pepper",
            "lookup": "80000513090"
        },
        {
            "ref": "2be6b59f-66e5-40aa-9113-e2938a936ce8",
            "name": "Starkist Tuna Creations - Variety Bacon Ranch",
            "lookup": "80000515902"
        },
        {
            "ref": "bc64e727-3710-4a2d-aae4-4c6ec5f775da",
            "name": "Mrs Meyer's Clean Day Dish Soap lavender",
            "lookup": "80812411035"
        },
        {
            "ref": "9dae90df-b015-4074-913e-52199646d392",
            "name": "Bark Jerky",
            "lookup": "810013000000"
        },
        {
            "ref": "d2f8cbcf-62fe-4201-91d1-972457984c29",
            "name": "Hello Bello Lotion",
            "lookup": "810048000000"
        },
        {
            "ref": "a2986034-f84b-4074-8d59-9b1069983c38",
            "name": "Treval Size - Schmidt's Natural Deoderant",
            "lookup": "810117000000"
        },
        {
            "ref": "8073ae39-b476-4f8d-bceb-4e848921da07",
            "name": "Hippeas Chickpea puffs",
            "lookup": "810122000000"
        },
        {
            "ref": "5e4eb91a-0e23-4c41-93f6-864acec75ee2",
            "name": "Strawberries",
            "lookup": "811334000000"
        },
        {
            "ref": "3a4fafb6-d4f4-4eaa-a059-26e56ac59802",
            "name": "Aveeno Baby Lotion",
            "lookup": "8137003664"
        },
        {
            "ref": "a53d8ebe-3c3f-4a71-b766-90561904818b",
            "name": "Baby Food Pouch peach oatmeal coconut",
            "lookup": "818512000000"
        },
        {
            "ref": "5c8a9061-f449-437d-8901-e3f29478d986",
            "name": "HOLT Test",
            "lookup": "16500080145"
        },
        {
            "ref": "44d7f8f9-e4b1-4ea9-aea8-8754fc534093",
            "name": "Peanuts Colossal",
            "lookup": "81864241167"
        },
        {
            "ref": "b8cd9382-19d5-4f6d-9dd0-ad5271f3832d",
            "name": "Complete Contact Solution",
            "lookup": "827444000000"
        },
        {
            "ref": "72e0187b-fa52-47a7-878b-98516b4290ce",
            "name": "Rice Pudding, Coconut milk",
            "lookup": "829354000000"
        },
        {
            "ref": "5d0f88e2-45d1-4048-8eb5-a26ed1f165d5",
            "name": "Rhythm Cauliflower Bites",
            "lookup": "829739000000"
        },
        {
            "ref": "78031a61-988f-49e8-b6b5-b38273364adc",
            "name": "Rhythm Superfoods Beet Chips",
            "lookup": "829740000000"
        },
        {
            "ref": "5a6a1b21-6ba2-4148-9b7e-db93917d1b68",
            "name": "Zapps chips Kettle voodoo Zapps Hotter n Jot",
            "lookup": "83791059396"
        },
        {
            "ref": "9796a00f-0266-4cba-81e1-912f308cfdc5",
            "name": "Zapps chips Kettle voodoo Regular",
            "lookup": "83791150055"
        },
        {
            "ref": "65bfa245-1dbe-4c66-839e-1aaba2420ed3",
            "name": "Baby Food Pouch Plum organic, apple brocoli",
            "lookup": "846675000000"
        },
        {
            "ref": "59e278ac-10fe-40cd-8101-db1c910f9148",
            "name": "Face Mask Alien",
            "lookup": "849980000000"
        },
        {
            "ref": "182324f9-742c-495c-a922-eaa6876d8104",
            "name": "Wipes Regular",
            "lookup": "850000000000"
        },
        {
            "ref": "aa536161-af7f-4596-819d-575fa265742c",
            "name": "Fava, Vegan Taco Protein",
            "lookup": "850010000000"
        },
        {
            "ref": "3ab505c3-a1c4-40bd-9122-1625e8ead2b1",
            "name": "Mixed Berries Blueberries",
            "lookup": "850016000000"
        },
        {
            "ref": "b9a4a328-e94c-4c68-84a4-53780d73b001",
            "name": "Goodles, Vegan Mac & Cheese",
            "lookup": "850032000000"
        },
        {
            "ref": "80bd22cb-ed3c-41fc-92fa-3eb357cf1f7b",
            "name": "Extra Virgin Olive Oil",
            "lookup": "850687000000"
        },
        {
            "ref": "06d712e1-4fef-4b00-a75b-c11fd19ecd24",
            "name": "Garofolo Pasta",
            "lookup": "85164000073"
        },
        {
            "ref": "91059e2c-8dfd-43a9-9182-7aad2a2b3998",
            "name": "Pasta, Pappardelle Regular",
            "lookup": "85164000165"
        },
        {
            "ref": "bd225d2c-4986-41bb-9515-f09afc7f5a1d",
            "name": "Chocolate Maple Granola",
            "lookup": "851921000000"
        },
        {
            "ref": "464b40cf-cee1-4257-bccf-30bbfa4a9abe",
            "name": "Ramen, Kimchi Regular",
            "lookup": "852320000000"
        },
        {
            "ref": "bacb0fe2-0e1a-47bf-89aa-8b88c65af980",
            "name": "Chocolate Chip cookie, Vegan Partake",
            "lookup": "852761000000"
        },
        {
            "ref": "0aefbe65-3409-469a-b7fe-9041e8b5b281",
            "name": "Water, Zen",
            "lookup": "852789000000"
        },
        {
            "ref": "f882cf3c-bd0f-48e7-bdfe-8ed2ce6d69bf",
            "name": "Spicy Kung Pao",
            "lookup": "854285000000"
        },
        {
            "ref": "6f3eda1c-6ec3-47e0-99c0-94b7a00cb430",
            "name": "Top Organic Menstrual Care Cotton Tampons - Regular",
            "lookup": "855212000000"
        },
        {
            "ref": "0781f68e-abc0-4935-ad1c-9ca9dc5b523b",
            "name": "Kimchi, Mothers",
            "lookup": "855230000000"
        },
        {
            "ref": "114b3c90-6c5d-4b2a-b4aa-8f02729379c3",
            "name": "Tzatziki",
            "lookup": "855616000000"
        },
        {
            "ref": "fe21da9d-0160-40bf-8490-0aff3982361c",
            "name": "Banza Chickpea Pasta",
            "lookup": "857183000000"
        },
        {
            "ref": "173ba91c-ce5c-4856-8026-c1377a22069c",
            "name": "Powerup omega",
            "lookup": "857468000000"
        },
        {
            "ref": "a8dd9d90-bb61-4241-ac75-97b5b3b797b5",
            "name": "Biena Honey Roasted Chickpeas",
            "lookup": "857597000000"
        },
        {
            "ref": "8458a4e4-baaf-4110-8d98-09ae24129885",
            "name": "Natures path Coconut and cashew Coconut Chocolate",
            "lookup": "858660000000"
        },
        {
            "ref": "4b58e4c0-b9a8-4c86-80d6-d1973a0cad80",
            "name": "Blue Buffalo Wet Dog Food Regular",
            "lookup": "859610000000"
        },
        {
            "ref": "d6400671-fd0d-48ea-90b7-06dc9f817e73",
            "name": "Jack & Annie's Jack Nuggets",
            "lookup": "859806000000"
        },
        {
            "ref": "c7cafb31-03b0-4a53-a6e6-7daac30b9de5",
            "name": "COVID-19 Rapid test",
            "lookup": "860009000000"
        },
        {
            "ref": "2ecf1266-f7be-408b-8df5-66b500f355ca",
            "name": "Top Organic Menstrual Care Cotton Liners - Ultra Thin",
            "lookup": "860495000000"
        },
        {
            "ref": "9212299f-dbd3-43f8-b60e-b7035c4e1116",
            "name": "Sun Ridge Farms Plantain chips",
            "lookup": "86700671252"
        },
        {
            "ref": "927118e5-24ed-43d9-9ef2-5888936ae390",
            "name": "Artisana Raw Nut Butter",
            "lookup": "870001000000"
        },
        {
            "ref": "15aaeaf3-01fc-4f35-83e6-fb6480108b29",
            "name": "Sun Bum Sunscreen SPF 30 Spray",
            "lookup": "87176000196"
        },
        {
            "ref": "df75d457-2da4-444a-8802-2f8baa6c4b7d",
            "name": "Capri Sun 12 pack Pacific cooler",
            "lookup": "87684000991"
        },
        {
            "ref": "f06b22eb-4628-4d6d-ab10-f55e378044a3",
            "name": "Capri Sun 12 pack Regular",
            "lookup": "87684001073"
        },
        {
            "ref": "ce1f9b19-ce9b-4a93-b9e8-8387b99e6f87",
            "name": "Kimchi",
            "lookup": "87703272323"
        },
        {
            "ref": "b81ce07b-d936-457e-9706-54abed78b42d",
            "name": "Yaco Coconut water Regular",
            "lookup": "8851380000000"
        },
        {
            "ref": "460235d6-c624-415f-be02-2b0b93edafd2",
            "name": "Siracha",
            "lookup": "8854690000000"
        },
        {
            "ref": "5fbfe863-98c1-472f-a633-b029cadd97df",
            "name": "Betterbody Foods Quinoa",
            "lookup": "897922000000"
        },
        {
            "ref": "bb7a127a-4f01-4a65-9640-66c2e53a0acd",
            "name": "Southwest Plant Taco crumble",
            "lookup": "89836160119"
        },
        {
            "ref": "a5ca8f14-2834-451c-8fa3-b6b7599f14ad",
            "name": "Plant Boss Organic Chili Meat Crumbles",
            "lookup": "8983616020"
        },
        {
            "ref": "f52409e9-e48a-4491-a829-4576f69fcb91",
            "name": "Relish, Sweet Mt. Olive",
            "lookup": "9300000079"
        },
        {
            "ref": "4fef0201-db43-476f-8162-5dc052a63346",
            "name": "Aloe Vera Burn Gel",
            "lookup": "9347110000000"
        },
        {
            "ref": "10f85659-1fe6-4cc5-b78e-5004d5fa3892",
            "name": "Herbal Essence Shampoo",
            "lookup": "94187017517"
        },
        {
            "ref": "1ff02695-9880-474b-a961-51bb1f2fc0d0",
            "name": "Herbal Essence Conditioner",
            "lookup": "94187017524"
        },
        {
            "ref": "1571ed63-d449-4082-8129-da285ce4fd8f",
            "name": "Jergens Ultra Moisture Lotion Regular",
            "lookup": "94187027837"
        },
        {
            "ref": "984ed085-3cac-4c3c-9a72-a2e1f0bebb72",
            "name": "Venus Disposable Razor",
            "lookup": "94187052280"
        },
        {
            "ref": "f1611323-ca50-49f4-98d9-4428440c5a12",
            "name": "Banana Boat Sun Screen, Small",
            "lookup": "94187073698"
        },
        {
            "ref": "22fccca3-0a0e-4101-9824-fea2b0fc5a31",
            "name": "Lindor balls Caramel",
            "lookup": "9542004194"
        },
        {
            "ref": "52787639-a156-46cb-a182-5e60203cc8c2",
            "name": "Lindor balls sea salt",
            "lookup": "9542004859"
        },
        {
            "ref": "5c52357d-7467-4285-9ce9-bf56bfac0bf3",
            "name": "Lindor balls Assorted Big",
            "lookup": "9542016166"
        },
        {
            "ref": "43ef5f28-4e78-4430-8a19-840870a8dc19",
            "name": "Lindor balls milk big",
            "lookup": "9542016173"
        },
        {
            "ref": "e0e04f38-dc10-4273-bcb6-a9f79e06f9c2",
            "name": "Lindor balls Holiday ball",
            "lookup": "9542017644"
        },
        {
            "ref": "3df8b060-0a38-4dea-ac54-46c422e7106d",
            "name": "Lindor balls Big Box",
            "lookup": "9542436100"
        },
        {
            "ref": "6d7a91fb-bae6-40c1-a63d-629db18b437f",
            "name": "Texas Deviled egg Potato Salad Regular",
            "lookup": "96938266236"
        },
        {
            "ref": "558cf8ce-6e25-4c49-942c-ade94c29607a",
            "name": "nutella To go",
            "lookup": "9800800056"
        },
        {
            "ref": "0c70dfb4-8b50-4e52-babf-d1a41dd0e9e9",
            "name": "Nutella biscuits",
            "lookup": "9800830039"
        },
        {
            "ref": "822b6661-ca43-4493-a434-8a5f86c02098",
            "name": "Ad Astra Shirt",
            "lookup": ""
        },
        {
            "ref": "10e374c0-8b31-444e-bc39-35a2651efcd9",
            "name": "Advil 100ct",
            "lookup": ""
        },
        {
            "ref": "1cc488e6-b414-42d6-bf29-07758058a63a",
            "name": "Advil 200mg Travel Size",
            "lookup": ""
        },
        {
            "ref": "7e3c4ddf-a347-4bbd-836e-97e646106e4c",
            "name": "Annie Single Mac & Cheese Cup",
            "lookup": ""
        },
        {
            "ref": "2e90c5a1-a785-4e9a-a2f5-1afc2cb83f18",
            "name": "Aveeno Daily Moisturizing Lotion",
            "lookup": ""
        },
        {
            "ref": "dc8f0e4c-929f-436d-be8f-2c8f11363ca7",
            "name": "Avocado",
            "lookup": ""
        },
        {
            "ref": "00827a20-4eba-47df-ad1c-7f21353207ac",
            "name": "Bare Fruit Chips Regular",
            "lookup": ""
        },
        {
            "ref": "8b90e238-4468-45bf-99cc-d06f6762b0b3",
            "name": "Beef - Kalbi Ribs",
            "lookup": ""
        },
        {
            "ref": "2945e640-2664-46f1-95b8-b7c56111c0bc",
            "name": "Bell Pepper Varieties",
            "lookup": ""
        },
        {
            "ref": "c19f95f7-a9ed-489a-923b-fdec8737762f",
            "name": "Ben & Jerrys Americone Pint",
            "lookup": ""
        },
        {
            "ref": "600a99f5-0249-446a-b794-635122701673",
            "name": "Bird's Eye Steamfresh Veggie Spaghetti",
            "lookup": ""
        },
        {
            "ref": "850a8518-a9fd-45a2-8d3c-325fb59f73f1",
            "name": "Bounty Paper Towels Regular",
            "lookup": ""
        },
        {
            "ref": "08a45a3b-6be8-4658-8a73-6b5607bbe557",
            "name": "Bread - SaraLee Artesano Sliced Bread Regular",
            "lookup": ""
        },
        {
            "ref": "e5c04a29-6ff2-43a1-81ff-1ad34c898b86",
            "name": "Cat - Feather Bird Cat Toy",
            "lookup": ""
        },
        {
            "ref": "c501f825-de49-44b4-8c8c-1afdc15b17b1",
            "name": "Cat - Toy Mouse",
            "lookup": ""
        },
        {
            "ref": "81709eb0-644d-43de-b415-2292ae63bb8d",
            "name": "Cheese - Kraft Mozzarella",
            "lookup": ""
        },
        {
            "ref": "b0365134-1594-4ba9-a20e-fa0d87efc685",
            "name": "Crawfish Boil",
            "lookup": ""
        },
        {
            "ref": "c2217a50-70e3-4b2a-ae06-be75f63a2ee7",
            "name": "Customer Dinner",
            "lookup": ""
        },
        {
            "ref": "3e501f4b-a5e7-4a04-b754-5031acb9e7a0",
            "name": "Dog - Earth Rated Poop Bags",
            "lookup": ""
        },
        {
            "ref": "7b9969a6-7995-4ea4-a493-6780b8af5701",
            "name": "Dr. Proctor",
            "lookup": ""
        },
        {
            "ref": "323ac5d8-52fa-466e-804b-429055ccb5b9",
            "name": "Dragon Toy",
            "lookup": ""
        },
        {
            "ref": "c1f255c1-6703-4436-bf76-22851d82ff3f",
            "name": "Gillette Sensor5 Disposable Razors",
            "lookup": ""
        },
        {
            "ref": "920e194d-dc22-4b3f-9c63-4573d1531f2b",
            "name": "Glide Floss",
            "lookup": ""
        },
        {
            "ref": "2f5fa30a-51b6-4bdb-91c7-43e9ca5f9b84",
            "name": "Grab & Go $1.00",
            "lookup": ""
        },
        {
            "ref": "93c12b2d-7206-48a9-9902-da0e00cadd49",
            "name": "Grab & Go $2.00",
            "lookup": ""
        },
        {
            "ref": "c9ac1435-c265-4ee9-8edf-4a8a75fb1651",
            "name": "Grab & Go $3.00",
            "lookup": ""
        },
        {
            "ref": "b3bde1d2-bd7a-467f-8d56-baa5a8fa0d31",
            "name": "Housekeeping Services",
            "lookup": ""
        },
        {
            "ref": "05dcdab1-395c-4cf2-8bfb-9d09063db1f3",
            "name": "Irish Spring Bar Soap",
            "lookup": ""
        },
        {
            "ref": "08c481cd-be5b-424c-bd3b-a334261b5688",
            "name": "Ketchup Reg",
            "lookup": ""
        },
        {
            "ref": "19a5bbcd-e008-4c81-a332-0ab7a6891ea3",
            "name": "Lemon",
            "lookup": ""
        },
        {
            "ref": "0cb78125-7fd0-4297-9742-6c367f756fc3",
            "name": "Lime",
            "lookup": ""
        },
        {
            "ref": "07686a55-d217-411a-95b1-a7d6a55e1eac",
            "name": "Mens F9 Tshirt - Large Regular",
            "lookup": ""
        },
        {
            "ref": "ba981983-d167-4437-89ae-fad2d4831d88",
            "name": "Mens F9 Tshirt - Medium Regular",
            "lookup": ""
        },
        {
            "ref": "f8135176-f02e-46a7-95df-082dc33d994c",
            "name": "Mens F9 Tshirt - Small Regular",
            "lookup": ""
        },
        {
            "ref": "4919c87a-6249-4d44-9169-990bef2e6f7e",
            "name": "Mens F9 Tshirt - XL Regular",
            "lookup": ""
        },
        {
            "ref": "746ef181-f5e8-4f5c-9f24-9f770d12bfd3",
            "name": "Mens F9 Tshirt - XXL Regular",
            "lookup": ""
        },
        {
            "ref": "00ce8f72-5cd5-44cf-ba30-2c56172a3244",
            "name": "monthly rent",
            "lookup": ""
        },
        {
            "ref": "3a2d863e-d51d-40d7-856f-bad84ee6cb2e",
            "name": "Natures Path Organix Regular",
            "lookup": ""
        },
        {
            "ref": "9f3093a6-810c-455c-ad4c-98781ef54ce9",
            "name": "Neutrogena Gentle Daily Cleanser",
            "lookup": ""
        },
        {
            "ref": "e80295f3-949c-4d8c-9b21-877f362034fe",
            "name": "Neutrogena Makeup Removing Cleansing Towelette",
            "lookup": ""
        },
        {
            "ref": "167134de-cc24-481b-ae15-8c971a929a56",
            "name": "Onion Varieties",
            "lookup": ""
        },
        {
            "ref": "394a7f31-f86f-4180-992f-771408b7c505",
            "name": "Pastries Chocolate Muffin",
            "lookup": ""
        },
        {
            "ref": "75c61b33-44eb-43b1-af2a-d077f53cf838",
            "name": "Pastries Huckleberry Muffin",
            "lookup": ""
        },
        {
            "ref": "4309ff42-6fc2-4943-9ba2-79cb1325e607",
            "name": "Pastries Olive Oil Muffin",
            "lookup": ""
        },
        {
            "ref": "2d144b86-65a1-47af-ae01-c31ec9de9521",
            "name": "Pastries Quiche",
            "lookup": ""
        },
        {
            "ref": "a6975ab0-d140-4a51-847a-30d850e590e9",
            "name": "Pastries Egg Bites",
            "lookup": ""
        },
        {
            "ref": "93766fea-e30c-4972-ba55-e28db451c272",
            "name": "Patch",
            "lookup": ""
        },
        {
            "ref": "79228823-b12f-446f-9e4a-367a99bf6fce",
            "name": "Pea Flower Lemonade",
            "lookup": ""
        },
        {
            "ref": "aab4dcef-9360-4598-9ba3-d1e8a771c57f",
            "name": "Purple Badge Scans $5",
            "lookup": ""
        },
        {
            "ref": "4ac85e97-f728-49a5-8874-cf3821748821",
            "name": "Rhythm Carrot Sticks",
            "lookup": ""
        },
        {
            "ref": "305936b1-6627-4f42-b1f1-eaaeba889f3e",
            "name": "Scan Alternative Final Payment $1",
            "lookup": ""
        },
        {
            "ref": "b563ecbf-94b1-409c-b9a3-b63aad24f71e",
            "name": "Scan Alternative Item 165",
            "lookup": ""
        },
        {
            "ref": "0004c18c-7977-4ec6-ac0d-afc6a32eaa65",
            "name": "Scan Alternative Payment $100",
            "lookup": ""
        },
        {
            "ref": "34fc24d2-aae1-41d7-8a34-3ff59b542212",
            "name": "Scan Alternative Payment $130",
            "lookup": ""
        },
        {
            "ref": "bb95c0dc-4932-481f-870d-6e499af87f46",
            "name": "Scan Alternative Payment $150",
            "lookup": ""
        },
        {
            "ref": "1a68d932-39d2-4afd-bf0e-c808d064273e",
            "name": "Scan Alternative Payment $200",
            "lookup": ""
        },
        {
            "ref": "a04c3f07-2606-4c28-a458-5d489378f948",
            "name": "Scan Alternative Payment $40",
            "lookup": ""
        },
        {
            "ref": "a81c907f-004d-4b06-9b81-2165e79cca93",
            "name": "Scan Alternative Payment $45",
            "lookup": ""
        },
        {
            "ref": "f53eecfa-4847-4632-8634-c79d77b9b414",
            "name": "Scan Alternative Payment $50",
            "lookup": ""
        },
        {
            "ref": "fedae2e6-51f2-47bd-9146-43abb8a40919",
            "name": "Scan Alternative Payment $75",
            "lookup": ""
        },
        {
            "ref": "83c8c6e0-6e88-41c4-a6d6-5228b307ca05",
            "name": "Set Menu - Classic",
            "lookup": ""
        },
        {
            "ref": "4df2d844-f7ea-4d12-99f0-008ff20e08bb",
            "name": "Set Menu - Complete",
            "lookup": ""
        },
        {
            "ref": "214977c0-3267-42f2-ba8d-ac3da61feaad",
            "name": "Short Rib Feature",
            "lookup": ""
        },
        {
            "ref": "a8484977-f723-40a3-b570-ea3fae0bfec4",
            "name": "Solimo Makeup Remover Wipes",
            "lookup": ""
        },
        {
            "ref": "5a4fd6e7-3b5f-4ceb-b52e-e3e9323f7eb2",
            "name": "Starbrew Bottle pineapple lemonade",
            "lookup": ""
        },
        {
            "ref": "128d75d0-45c2-4dea-806f-4f02fdaf49e4",
            "name": "Starship Hat",
            "lookup": ""
        },
        {
            "ref": "f7a5de51-4a47-4403-a28b-4ebaaba527cb",
            "name": "Starship Sticker Pack",
            "lookup": ""
        },
        {
            "ref": "8cae8f12-6748-486f-9af4-75eca6cac306",
            "name": "Starship Tshirt - L",
            "lookup": ""
        },
        {
            "ref": "4d8a00c8-2d4b-4918-a96e-416b68be6c81",
            "name": "Starship Tshirt - M",
            "lookup": ""
        },
        {
            "ref": "8b2b8682-14e4-4c59-9d0b-be27051c18ea",
            "name": "Starship Tshirt - S",
            "lookup": ""
        },
        {
            "ref": "628fb7f7-a05b-4426-8a72-c921d320b253",
            "name": "Starship Tshirt - XL",
            "lookup": ""
        },
        {
            "ref": "867cb1f9-86a1-473d-a620-1d960d3e82fd",
            "name": "Summer Camp Plus",
            "lookup": ""
        },
        {
            "ref": "34decfff-124e-4309-9451-55b850c20342",
            "name": "test",
            "lookup": ""
        },
        {
            "ref": "1cfa77f7-7067-4006-8f3e-3618213b3937",
            "name": "Tomato, large",
            "lookup": ""
        },
        {
            "ref": "8c54642a-dde6-4c97-a681-08ce35ed021c",
            "name": "Tortilla chip Siete Regular",
            "lookup": ""
        },
        {
            "ref": "2a895374-c660-4bc4-beb1-57c5d256c19a",
            "name": "Travel Size Colgate Toothpaste",
            "lookup": ""
        },
        {
            "ref": "a8040e5f-2a0c-4fb8-bdae-295bca72cae4",
            "name": "Tresemme Extra Hold Hair Gel",
            "lookup": ""
        },
        {
            "ref": "7f811cd1-9641-4410-9e81-f677dfbed1bc",
            "name": "Tuition Payment Ad Astra",
            "lookup": ""
        },
        {
            "ref": "e7a19e55-71dd-449b-ad27-58267478bf24",
            "name": "Valet Laundry",
            "lookup": ""
        },
        {
            "ref": "9a57be59-2f3b-4b50-b257-e0b73b88b4fc",
            "name": "Womens F9 Tshirt - Large",
            "lookup": ""
        },
        {
            "ref": "b829bbf5-1692-4e62-8b8e-eb40e76a9247",
            "name": "Womens F9 Tshirt - Medium",
            "lookup": ""
        },
        {
            "ref": "e283bbc0-04ca-48e3-9207-9c5793e217b2",
            "name": "Womens F9 Tshirt - Small",
            "lookup": ""
        },
        {
            "ref": "33671b16-12d2-48c2-820f-bec732abdd1c",
            "name": "Womens F9 Tshirt - XL",
            "lookup": ""
        },
        {
            "ref": "af02af7c-07d4-48a7-a54c-acc41be249cf",
            "name": "Womens F9 Tshirt - XXL",
            "lookup": ""
        },
        {
            "ref": "0e834113-8cbd-466b-ba5d-1568f5762a02",
            "name": "Womens Occupy Mars Tshirt - Large",
            "lookup": ""
        },
        {
            "ref": "09362a9b-b2fa-4ce8-ab0f-78399759f5f2",
            "name": "Womens Occupy Mars Tshirt - Medium",
            "lookup": ""
        },
        {
            "ref": "d10e922e-5e95-4c2c-be7e-9b527324d0d5",
            "name": "Womens Occupy Mars Tshirt - Small",
            "lookup": ""
        },
        {
            "ref": "a46d4b0d-21a8-43e1-996a-bfae52401495",
            "name": "Womens Occupy Mars Tshirt - XL",
            "lookup": ""
        },
        {
            "ref": "ebf2a927-edab-4a61-a300-52e7bcbe563a",
            "name": "Womens Occupy Mars Tshirt - XXL",
            "lookup": ""
        }
    ];

    module.exports = lookupList;
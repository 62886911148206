import { v4 as uuidv4 } from 'uuid';
import api from "@/utils/api";
import { saveData, loadData, removeData } from "@/utils/storageHelper";


const terminalStoreData = {
    terminalDetails: loadData('terminalDetails') || {
        name: '',
        locationId: '',
    },
    locationId: loadData('locationId') || null,
    apiToken: loadData('apiToken') || null,
    deviceId: loadData('deviceId') || uuidv4(),
    pairingMethod: loadData('pairingMethod') || null,
    previewMid: loadData('previewMid') || null,
}

export default {
    namespaced: true,
    state() {
        return {
            errorMessage: '',
            registrationError: '',
            canRetryRegistration: false,
            config: loadData('config') || {},
            ...terminalStoreData,
        }
    },
    getters: {
        errorMessage: state => state.errorMessage,
        serial: () => {
            // eslint-disable-next-line no-undef
            if (typeof Shift4Kiosk !== 'undefined' && typeof Shift4Kiosk.deviceConfig === 'function') {
                // eslint-disable-next-line no-undef
                const config = JSON.parse(Shift4Kiosk.deviceConfig());
                return config.serialNumber;
            }

            let demoSerial = localStorage.getItem('demoSerial');
            if (!demoSerial) {
                const randomNumber = Math.floor(1000000000 + Math.random() * 9000000000);
                demoSerial = `DEMO${randomNumber}`;
                localStorage.setItem('demoSerial', demoSerial);
            }

            return demoSerial;
        },
        terminalDetails: state => state.terminalDetails,
        locationId: state => state.locationId,
        apiToken: state => state.apiToken,
        deviceId: state => state.deviceId,
        registrationError: state => state.registrationError,
        canRetryRegistration: state => state.canRetryRegistration,
        terminalPaired: state => !!state.terminalDetails,
        pairingMethod: state => state.pairingMethod,
        previewMid: state => state.previewMid,
    },
    mutations: {
        SET_ERROR_MESSAGE(state, message) {
            state.errorMessage = message;
        },
        SET_TERMINAL_DETAILS(state, details) {
            state.terminalDetails = details;
            saveData('terminalDetails', state.terminalDetails);
        },
        SET_API_TOKEN(state, token) {
            state.apiToken = token;
            saveData('apiToken', token);
        },
        SET_LOCATION_ID(state, locationId) {
            state.locationId = locationId;
            saveData('locationId', locationId);
        },
        SET_DEVICE_ID(state, deviceId) {
            state.deviceId = deviceId;
            saveData('deviceId', deviceId);
        },
        SET_CAN_RETRY_REGISTRATION(state, canRetry) {
            state.canRetryRegistration = canRetry;
        },
        SET_PAIRING_METHOD(state, method) {
            state.pairingMethod = method;
            saveData('pairingMethod', method);
        },
        SET_PREVIEW_MID(state, previewMid) {
            state.previewMid = previewMid;
            saveData('previewMid', previewMid);
        },
        SET_CONFIG(state, config) {
            state.config = config;
            saveData('config', config);
        },
    },
    actions: {
        async registerTerminalByMid({ commit, dispatch, rootState }, mid) {
            commit('SET_PAIRING_METHOD', 'mid');
            commit('SET_PREVIEW_MID', mid);

            // Call index store's loadPublicLocationData
            try {
                const response = await dispatch('loadPublicLocationData', null, { root: true });
                if (response) {
                    commit('SET_PREVIEW_MID', mid);
                    commit("SET_TERMINAL_DETAILS", {
                        locationName: rootState.location.name,
                        locationId: rootState.location.details.id,
                    });
                    commit('SET_LOCATION_ID', rootState.location.details.id);
                    return { success: true };
                } else {
                    commit('SET_PAIRING_METHOD', null);
                    commit('SET_PREVIEW_MID', null);
                    return { success: false, error: `Terminal ${mid} is not registered to a kiosk location.` };
                }
            } catch (error) {
                commit('SET_PAIRING_METHOD', null);
                commit('SET_PREVIEW_MID', null);
                return { success: false, error: error.message };
            }
        },
        async registerTerminalSerial({ commit, state, getters }) {
            commit('SET_CAN_RETRY_REGISTRATION', false);
            commit('SET_ERROR_MESSAGE', '');
            try {
                const response = await api.callAPI("post", "terminal/serial", { serial: getters.serial, deviceId: state.deviceId });
                if (response) {
                    const { terminal, apiToken, config } = response.data;
                    commit("SET_API_TOKEN", apiToken);
                    commit("SET_TERMINAL_DETAILS", terminal);
                    commit('SET_LOCATION_ID', terminal.location_id);
                    commit('SET_PAIRING_METHOD', 'serial');
                    commit('SET_CONFIG', config);
                } else {
                    commit('SET_ERROR_MESSAGE', response?.error?.message || 'Unexpected response from the server');
                    commit('SET_CAN_RETRY_REGISTRATION', true);
                }
            } catch (error) {

                // if 4XX status - assume serial does not exist
                if (error.response && error.response.status >= 400 && error.response.status < 500) {
                    commit('SET_ERROR_MESSAGE', `Terminal ${getters.serial} is not registered to a location.`);
                } else {
                    const errorMessage = error?.message || 'An unexpected error occurred';
                    commit('SET_ERROR_MESSAGE', errorMessage);
                }
                commit('SET_CAN_RETRY_REGISTRATION', true);
            }
        },
        async registerTerminal({ commit, state }, { pin }) {
            commit('SET_ERROR_MESSAGE', '');
            try {
                const response = await api.callAPI("POST", "terminal/register", { token: pin, deviceId: state.deviceId });
                if (response.status === 200) {
                    const { terminal, apiToken, config } = response.data;
                    console.log('PIN TERMINAL', terminal);
                    commit("SET_API_TOKEN", apiToken);
                    commit("SET_TERMINAL_DETAILS", terminal);
                    commit('SET_LOCATION_ID', terminal.location_id);
                    commit('SET_PAIRING_METHOD', 'pin');
                    commit('SET_CONFIG', config);
                } else if (response.status === 404 || response.status === 403) {
                    commit('SET_ERROR_MESSAGE', `PIN Code is incorrect. Please try again.`);
                } else {
                    commit('SET_ERROR_MESSAGE', response.data.error || 'Unexpected response from the server');
                }

            } catch (error) {
                const errorMessage = error?.response?.data?.error || 'An unexpected error occurred';
                commit('SET_ERROR_MESSAGE', errorMessage);
            }
        },
        async deregisterTerminal({ commit, state }) {
            commit('SET_ERROR_MESSAGE', '');
            try {
                await api.callAPI("POST", "terminal/deregister", null, state.apiToken);
            } catch (error) {
                console.log(error);
            }
            commit('SET_API_TOKEN', null);
            commit('SET_DEVICE_ID', uuidv4());
            commit('SET_LOCATION_ID', null);
            commit('SET_PAIRING_METHOD', null);
            commit('SET_TERMINAL_DETAILS', null);
            commit('SET_PREVIEW_MID', null);
            console.log('deregistered');
            removeData('apiToken');
            removeData('deviceId');
            removeData('terminalDetails');
            removeData('pairingMethod');
            removeData('previewMid');

        },
        async checkAuth({ state, commit }) {
            try {
                const response = await api.callAPI("GET", "v2/terminal", null, state.apiToken);
                commit('SET_TERMINAL_DETAILS', response.terminal);
                commit('SET_LOCATION_ID', response.terminal.location_id);
                return true;
            } catch (error) {
                commit('SET_TERMINAL_DETAILS', null);
                commit('SET_LOCATION_ID', null);
                console.error(error);
                return false;
            }
        },
    }
};

<template v-if="summaryDetailed && summary">
    <div class="dashboard-container">
        <!-- Toolbar with Period Selection, Comparison Selection, and Theme Switcher -->
        <div class="toolbar">
            <div class="period-selection">
                <button v-for="period in periods" :key="period.value" class="btn"
                    :class="{ 'btn-selected': selectedPeriod === period.value }" @click="updateSummary(period.value)">
                    {{ period.label }}
                </button>
            </div>
            <div class="theme-switcher">
                <label for="themeSelect">Theme:</label>
                <select id="themeSelect" v-model="selectedTheme" @change="applyTheme">
                    <option v-for="theme in themes" :key="theme.value" :value="theme.value">
                        {{ theme.label }}
                    </option>
                </select>
            </div>
        </div>

        <!-- Dashboard Content -->
        <div class="grid-container">

            <!-- Orders Timeline Chart -->
            <div class="chart-card large" v-if="summaryDetailed?.ordersTimeline?.length > 0">
                <div class="card-title">Order Timeline</div>
                <TimelineComponent v-if="summaryDetailed?.ordersTimeline" :periodStartDateTime="periodStartDateTime"
                    :periodEndDateTime="periodEndDateTime" :ordersTimeline="summaryDetailed.ordersTimeline" />
            </div>


            <!-- Summary Cards -->
            <div class="summary-card" v-for="metric in summaryMetrics" :key="metric.title">
                <div class="card-title">{{ metric.title }}</div>
                <transition name="fade" mode="out-in">
                    <div class="card-value" :class="getTrendClass(metric.trend)">
                        {{ metric.value }}
                    </div>
                </transition>
                <div class="card-subtitle">
                    <span :class="getTrendClass(metric.trend)">
                        {{ getTrendArrow(metric.trend) }}
                    </span>
                    <span class="compare-value">
                        ({{ metric.compareValue }})
                    </span>
                </div>
            </div>

            <!-- Tip Distribution -->
            <div class="list-card" v-if="summaryDetailed?.tipDistribution">
                <div class="card-title">Tip Distribution</div>
                <ul>
                    <li v-for="tip in summaryDetailed.tipDistribution" :key="tip.tip_range">
                        <span>{{ tip.tip_range }}</span>
                        <span>{{ tip.count }} orders</span>
                    </li>
                </ul>
            </div>

            <!-- Rating Distribution -->
            <div class="list-card" v-if="summaryDetailed?.ratingDistribution">
                <div class="card-title">Rating Distribution</div>
                <ul>
                    <li v-for="rating in summaryDetailed.ratingDistribution" :key="rating.rating">
                        <span>{{ rating.rating }} Stars</span>
                        <span>{{ rating.count }} orders</span>
                    </li>
                </ul>
            </div>

            <!-- Sales by Order Flow -->
            <div class="list-card" v-if="summaryDetailed?.salesByOrderFlow">
                <div class="card-title">Sales by Order Flow</div>
                <ul>
                    <li v-for="flow in summaryDetailed.salesByOrderFlow" :key="flow.order_type">
                        <span>{{ flow.order_type || 'Unknown' }}</span>
                        <span>{{ flow.count }} orders</span>
                    </li>
                </ul>
            </div>


            <!-- Average Order Value -->
            <div class="summary-card" v-if="summary?.averageOrderValue?.length > 0">
                <div class="card-title">Average Order Value</div>
                <div class="card-value">
                    {{ formatCurrency(summary.averageOrderValue[0].avg_order_value) }}
                </div>
            </div>

            <!-- Customer Count -->
            <div class="summary-card" v-if="summary?.customerCount?.length > 0">
                <div class="card-title">Unique Customers</div>
                <div class="card-value">
                    {{ summary.customerCount[0].unique_customers }}
                </div>
            </div>

            <!-- Top Items -->
            <div class="list-card medium" v-if="summary?.topItems?.length > 0">
                <div class="card-title">Top Items</div>
                <ul>
                    <li v-for="item in summary.topItems" :key="item.item_name" class="top-item">
                        <span class="item-name">{{ item.item_name }}</span>
                        <div class="bar-container">
                            <div class="bar" :style="{ width: getBarWidth(item.total_quantity) }"></div>
                        </div>
                        <span class="quantity-sold">{{ item.total_quantity }}</span>
                    </li>
                </ul>
            </div>

            <!-- Revenue by Payment Type -->
            <div class="table-card" v-if="summary?.revenueByPaymentType?.length > 0">
                <div class="card-title">Revenue by Payment Type</div>
                <table>
                    <tbody>
                        <tr v-for="payment in summary.revenueByPaymentType.filter(p => p.revenue > 0)" :key="payment.payment_type">
                            <td>
                                <span v-if="payment.payment_type === 'CREDIT'"><SvgIcon name="payCredit" width="2rem" height="2rem" style="margin-right: 0.5rem;" />Credit</span>
                                <span v-else-if="payment.payment_type === 'NON_CASH' && payment.revenue > 0"><SvgIcon name="employee" width="2rem" height="2rem" style="margin-right: 0.5rem;" />Badge</span>
                                <span v-else-if="payment.payment_type === 'CASH' && payment.revenue > 0"><SvgIcon name="payUpFront" width="2rem" height="2rem" style="margin-right: 0.5rem;" />Cash</span>
                                <span v-else-if="payment.payment_type === 'DISCOUNTS'"><SvgIcon name="coupon" width="2rem" height="2rem" style="margin-right: 0.5rem;" />Discounts/Vouchers</span>
                            </td>
                            <td>{{ formatCurrency(payment.revenue) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
import { markRaw } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { formatCurrency } from '@/utils/helpers';
import TimelineComponent from '@/views/admin/TimelineComponent.vue';

Chart.register(...registerables);

export default markRaw({
    name: 'DashboardCharts',
    components: {
        TimelineComponent,
    },
    data() {
        return {
            selectedPeriod: 'today',
            selectedComparison: 'allTime', // Default comparison period
            periods: [
                { label: 'Today', value: 'today' },
                { label: 'Yesterday', value: 'yesterday' },
                { label: 'Last Week', value: 'lastWeek' },
                { label: 'Month to Date', value: 'monthToDate' },
                { label: 'Last Month', value: 'lastMonth' },
                { label: 'All Time', value: 'allTime' },
            ],
            themes: [
                { label: 'Default', value: 'default' },
                { label: 'Dark', value: 'dark' },
                { label: 'Light', value: 'light' },
                { label: 'Blue', value: 'blue' },
                { label: 'Green', value: 'green' },
            ],
            selectedTheme: 'default',
            charts: {},
        };
    },
    computed: {
        ...mapGetters('admin', ['summary', 'summaryDetailed', 'activeLocationId']),
        summaryMetrics() {
            if (!this.summaryDetailed) {
                return [];
            }
            return [
                { title: 'Total Sales', value: formatCurrency(this.summaryDetailed.totalSales.value), compareValue: formatCurrency(this.summaryDetailed.totalSales.compareValue), trend: this.summaryDetailed.totalSales.trend },
                { title: 'Total Orders', value: this.summaryDetailed.totalOrders.value, compareValue: this.summaryDetailed.totalOrders.compareValue, trend: this.summaryDetailed.totalOrders.trend },
                { title: 'Average Rating', value: this.summaryDetailed.averageRating.value, compareValue: this.summaryDetailed.averageRating.compareValue, trend: this.summaryDetailed.averageRating.trend },
                {
                    title: 'Time to Complete Order Average',
                    value: this.formatDuration(this.summaryDetailed.averageDuration.value),
                    compareValue: this.formatDuration(this.summaryDetailed.averageDuration.compareValue),
                    trend: this.summaryDetailed.averageDuration.trend
                },
                { title: 'Average Tip %', value: this.summaryDetailed.averageTipPercentage.value, compareValue: this.summaryDetailed.averageTipPercentage.compareValue, trend: this.summaryDetailed.averageTipPercentage.trend },
            ];
        }
    },
    mounted() {
        const period = localStorage.getItem('selectedPeriod') || 'today';
        const comparison = localStorage.getItem('selectedComparison') || 'allTime';
        this.selectedComparison = comparison;
        console.log('period', period);
        this.updateSummary(period);
    },
    watch: {
        summaryDetailed() {
            this.applyTheme();
            this.createOrdersTimelineChart();
        },
        activeLocationId() {
            const period = localStorage.getItem('selectedPeriod');
            this.updateSummary(period);
        },
    },
    methods: {
        ...mapActions('admin', ['fetchSummary']),
        formatCurrency,
        getTrendArrow(trend) {
            switch (trend) {
                case 'Up':
                    return '▲'; // Up arrow
                case 'Down':
                    return '▼'; // Down arrow
                case 'Stagnant':
                default:
                    return '▬'; // Neutral line
            }
        },
        getTrendClass(trend) {
            switch (trend) {
                case 'Up':
                    return 'trend-up';
                case 'Down':
                    return 'trend-down';
                case 'Stagnant':
                default:
                    return 'trend-stagnant';
            }
        },
        formatDuration(seconds) {
            const hrs = Math.floor(seconds / 3600);
            const mins = Math.floor((seconds % 3600) / 60);
            const secs = Math.floor(seconds % 60);
            let formattedDuration = '';

            if (hrs > 0) {
                formattedDuration += `${hrs} hour${hrs > 1 ? 's' : ''} `;
            }
            if (mins > 0) {
                formattedDuration += `${mins} minute${mins > 1 ? 's' : ''} `;
            }
            if (secs > 0 || formattedDuration === '') {
                formattedDuration += `${secs} second${secs > 1 ? 's' : ''}`;
            }

            return formattedDuration.trim();
        },
        async updateSummary(period) {
            this.selectedPeriod = period;
            localStorage.setItem('selectedPeriod', period);
            await this.fetchSummary({ period });
            this.createOrdersTimelineChart();
        },
        applyTheme() {
            document.documentElement.setAttribute('data-theme', this.selectedTheme);
            this.createOrdersTimelineChart();
        },
        createOrdersTimelineChart() {
            const chartRef = this.$refs.ordersTimelineChart;

            if (!chartRef) {
                return;
            }

            // Destroy existing chart instance if it exists
            if (this.charts.ordersTimelineChart) {
                this.charts.ordersTimelineChart.destroy();
                // Clear the canvas
                chartRef.getContext('2d').clearRect(0, 0, chartRef.width, chartRef.height);
            }

            const labels = this.summaryDetailed.ordersTimeline.map(order => new Date(order.created_at).toLocaleString());
            const data = this.summaryDetailed.ordersTimeline.map(order => order.total_in_pennies / 100);

            const chart = new Chart(chartRef, {
                type: 'line',
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: 'Order Amount',
                            data: data,
                            backgroundColor: this.getChartColors(labels.length, 'background'),
                            borderColor: this.getChartColors(labels.length, 'border'),
                            borderWidth: 1,
                        },
                    ],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        y: {
                            beginAtZero: true,
                            ticks: {
                                color: getComputedStyle(document.documentElement)
                                    .getPropertyValue('--text-color')
                                    .trim(),
                            },
                        },
                        x: {
                            ticks: {
                                color: getComputedStyle(document.documentElement)
                                    .getPropertyValue('--text-color')
                                    .trim(),
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            labels: {
                                color: getComputedStyle(document.documentElement)
                                    .getPropertyValue('--text-color')
                                    .trim(),
                            },
                        },
                    },
                },
            });

            // Store the chart instance for later destruction
            this.charts.ordersTimelineChart = markRaw(chart);
        },
        getChartColors(count, type) {
            // Define color sets for each theme
            const themeColors = {
                default: {
                    background: ['#007bff', '#6c757d', '#28a745', '#17a2b8', '#ffc107', '#dc3545', '#fd7e14', '#20c997'],
                    border: ['#0056b3', '#545b62', '#1e7e34', '#117a8b', '#bd2130', '#e0a800', '#c81e5b', '#1a7f5a']
                },
                dark: {
                    background: ['#343a40', '#495057', '#6c757d', '#adb5bd', '#ced4da', '#dee2e6', '#e9ecef', '#f8f9fa'],
                    border: ['#212529', '#343a40', '#495057', '#6c757d', '#adb5bd', '#ced4da', '#dee2e6', '#e9ecef']
                },
                light: {
                    background: ['#f8f9fa', '#e9ecef', '#dee2e6', '#ced4da', '#adb5bd', '#6c757d', '#495057', '#343a40'],
                    border: ['#ffffff', '#f8f9fa', '#e9ecef', '#dee2e6', '#ced4da', '#adb5bd', '#6c757d', '#495057']
                },
                blue: {
                    background: ['#007bff', '#0056b3', '#003f7f', '#001f3f', '#0d47a1', '#1976d2', '#2196f3', '#64b5f6'],
                    border: ['#0056b3', '#003f7f', '#001f3f', '#0d47a1', '#1976d2', '#2196f3', '#64b5f6', '#bbdefb']
                },
                green: {
                    background: ['#28a745', '#218838', '#1e7e34', '#155724', '#1b5e20', '#2e7d32', '#388e3c', '#4caf50'],
                    border: ['#218838', '#1e7e34', '#155724', '#1b5e20', '#2e7d32', '#388e3c', '#4caf50', '#81c784']
                }
            };

            // Get the current theme's colors
            const currentTheme = themeColors[this.selectedTheme] || themeColors.default;

            // Generate color arrays based on the current theme
            const backgroundColors = currentTheme.background.slice(0, count);
            const borderColors = currentTheme.border.slice(0, count);

            return type === 'background' ? backgroundColors : borderColors;
        },
        navigateTo() {
            // Implement navigation or actions when clicking on summary cards
        },
        getBarWidth(quantity) {
            const maxQuantity = Math.max(...this.summary.topItems.map(item => item.total_quantity));
            const percentage = (quantity / maxQuantity) * 100;
            return `${percentage}%`;
        },
    },
});
</script>

<style scoped>
:root {
    --primary-color: #007bff;
    --background-color: #ffffff;
    --card-background: #f9f9f9;
    --text-color: #333333;
}

[data-theme='default'] {
    --primary-color: #007bff;
    --background-color: #ffffff;
    --card-background: #f9f9f9;
    --text-color: #333333;
}

[data-theme='dark'] {
    --primary-color: #6c757d;
    --background-color: #343a40;
    --card-background: #454d55;
    --text-color: #f8f9fa;
}

[data-theme='light'] {
    --primary-color: #f8f9fa;
    --background-color: #ffffff;
    --card-background: #f0f0f0;
    --text-color: #343a40;
}

[data-theme='blue'] {
    --primary-color: #007bff;
    --background-color: #e3f2fd;
    --card-background: #bbdefb;
    --text-color: #0d47a1;
}

[data-theme='green'] {
    --primary-color: #28a745;
    --background-color: #e8f5e9;
    --card-background: #c8e6c9;
    --text-color: #1b5e20;
}

.dashboard-container {
    padding: 1rem;
    background-color: var(--background-color);
    color: var(--text-color);
}

.toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.period-selection .btn {
    margin-right: 0.5rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    background-color: var(--card-background);
    color: var(--text-color);
    cursor: pointer;
}

.period-selection .btn-selected {
    background-color: var(--primary-color);
    color: #ffffff;
}

.theme-switcher {
    display: flex;
    align-items: center;
}

.theme-switcher label {
    margin-right: 0.5rem;
}

.theme-switcher select {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Grid Layout */
.grid-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    /* 12-column grid for flexibility */
    grid-gap: 1rem;
}

/* Define how many columns each item spans */
.summary-card {
    background-color: var(--card-background);
    /* Summary cards span 4 columns (1/3 of 12) */
    grid-column: span 4;
    border: 1px solid #eaecef;
    border-radius: 8px;
    padding: 1rem;
    position: relative;
    cursor: pointer;
    transition: box-shadow 0.3s;
}

.summary-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.card-title {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
    color: var(--text-color);
}

.card-value {
    font-size: 2rem;
    font-weight: bold;
    color: var(--primary-color);
}

.card-subtitle {
    font-size: 0.9rem;
    color: var(--text-color);
    display: flex;
    align-items: center;
}

.compare-value {
    margin-left: 0.5rem;
    font-size: 0.8rem;
    color: var(--text-color);
}

.chart-card {
    background-color: var(--card-background);
    /* Regular chart cards span 4 columns */
    grid-column: span 4;
    border: 1px solid #eaecef;
    border-radius: 8px;
    padding: 1rem;
    position: relative;
    transition: box-shadow 0.3s;
    display: flex;
    flex-direction: column;
    min-height: 300px;
    max-height: 400px;
    overflow: hidden;
}

.chart-card.large {
    /* Large chart cards span 12 columns */
    grid-column: span 12;
}

.list-card.medium {
    /* Medium chart cards span 6 columns */
    grid-column: span 8;
}

.chart-card canvas {
    flex: 1;
    max-width: 100%;
    max-height: 100%;
}

.list-card {
    background-color: var(--card-background);
    /* List card spans 4 columns */
    grid-column: span 4;
    border: 1px solid #eaecef;
    border-radius: 8px;
    padding: 1rem;
    position: relative;
    cursor: pointer;
    transition: box-shadow 0.3s;
}

.list-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.list-card ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.list-card li {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--primary-color);
}

.list-card li:last-child {
    border-bottom: none;
}

.table-card {
    background-color: var(--card-background);
    /* Table card spans 8 columns */
    grid-column: span 8;
    border: 1px solid #eaecef;
    border-radius: 8px;
    padding: 1rem;
    position: relative;
    transition: box-shadow 0.3s;
}

.table-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.table-card table {
    width: 100%;
    border-collapse: collapse;
}

.table-card th,
.table-card td {
    padding: 0.75rem;
    border-bottom: 1px solid #eaecef;
    text-align: left;
    color: var(--text-color);
}

.table-card th {
    background-color: var(--primary-color);
    color: #ffffff;
}

.table-card tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

/* Responsive Adjustments */
@media screen and (max-width: 1024px) {

    .summary-card,
    .chart-card,
    .list-card,
    .table-card {
        grid-column: span 6;
        /* On medium screens, elements span 6 columns */
    }

    .chart-card.large,
    .table-card {
        grid-column: span 12;
        /* Large elements span full width */
    }
}

@media screen and (max-width: 768px) {
    .toolbar {
        flex-direction: column;
        align-items: flex-start;
    }

    .theme-switcher {
        margin-top: 0.5rem;
    }

    .summary-card,
    .chart-card,
    .list-card,
    .table-card {
        grid-column: span 12;
        /* On small screens, elements span full width */
    }

    .list-card.medium {
    /* Medium chart cards span 6 columns */
    grid-column: span 12;
}
}

.list-group-item {
    background-color: #f7f7f7;
    border: none;
    color: #444;
    font-weight: normal;
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #eaecef;
}

.list-group-item:last-child {
    border-bottom: none;
}

.list-group-item .badge {
    background-color: #007bff;
    color: #fff;
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
    border-radius: 0.2rem;
}

.info-card {
    background-color: var(--primary-color);
    color: #ffffff;
    border-radius: 10px;
    margin-top: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    text-align: center;
}

.info-header {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.info-body {
    padding: 1rem 0;
}

.info-value {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 0.25rem;
}

.info-subtitle {
    font-size: 1rem;
    font-weight: normal;
    color: #dddddd;
}

.badge {
    background-color: #007bff;
    color: #fff;
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
    border-radius: 0.2rem;
}

canvas {
    max-width: 100%;
}

.equal-height {
    display: flex;
    flex-wrap: wrap;
}

.equal-height>[class^='col-'] {
    display: flex;
    flex-direction: column;
}

.equal-height .card,
.equal-height .info-card {
    flex: 1;
}

.scrollable-buttons {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    padding-bottom: 10px;
}

.scrollable-buttons .btn {
    flex: 0 0 auto;
    border-radius: 20px;
    transition: all 0.3s;
    color: #333;
    background-color: #f0f0f0;
}

.scrollable-buttons .btn:hover {
    background-color: #e0e0e0;
}

.scrollable-buttons .btn-primary {
    background-color: #007bff;
    color: #fff;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}

.rating-card {
    background-color: var(--card-background);
    grid-column: span 4;
    border: 1px solid #eaecef;
    border-radius: 8px;
    padding: 1rem;
    position: relative;
    transition: box-shadow 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rating-icons {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 1rem;
}

.rating-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rating-item span {
    margin-top: 0.5rem;
    font-size: 1.2rem;
    color: var(--text-color);
}

.trend-up {
    color: green;
}

.trend-down {
    color: red;
}

.trend-stagnant {
    color: gray;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.trend-up {
  animation: trendUp 0.5s ease;
}

.trend-down {
  animation: trendDown 0.5s ease;
}

.trend-stagnant {
  animation: trendStagnant 0.5s ease;
}

@keyframes trendUp {
  from {
    transform: translateY(10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes trendDown {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes trendStagnant {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.bar-container {
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 4px;
    overflow: hidden;
    margin: 0.5rem 0;
}

.bar {
    height: 10px;
    background-color: var(--primary-color);
    border-radius: 4px;
}

.top-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--primary-color);
}

.top-item:last-child {
    border-bottom: none;
}

.item-name {
    flex: 1;
    margin-right: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bar-container {
    flex: 2;
    background-color: #e0e0e0;
    border-radius: 4px;
    overflow: hidden;
    margin: 0 1rem;
}

.bar {
    height: 10px;
    background-color: var(--primary-color);
    border-radius: 4px;
}

.quantity-sold {
    flex: 0 0 auto;
    white-space: nowrap;
}
</style>

import { isScheduleOpen } from "@/utils/time.js";

function applyPriceAdjustments(items, priceAdjustType, priceAdjustAmount) {
  return items.map(item => {
    let adjustedPrice = item.originalPrice;
    if (priceAdjustType === 'percentUp') {
      adjustedPrice = item.originalPrice * (1 + priceAdjustAmount / 100);
    } else if (priceAdjustType === 'percentDecrease') {
      adjustedPrice = item.originalPrice * (1 - priceAdjustAmount / 100);
    }

    return {
      ...item,
      price: adjustedPrice,
      modifierSets: item.modifierSets.map(modifierSet => ({
        ...modifierSet,
        modifiers: modifierSet.modifiers.map(modifier => {
          let modAdjustedPrice = modifier.originalPrice;
          if (priceAdjustType === 'percentUp') {
            modAdjustedPrice = modifier.originalPrice * (1 + priceAdjustAmount / 100);
          } else if (priceAdjustType === 'percentDecrease') {
            modAdjustedPrice = modifier.originalPrice * (1 - priceAdjustAmount / 100);
          }
          return {
            ...modifier,
            price: modAdjustedPrice
          };
        })
      }))
    };
  });
}

export default {
  namespaced: true,
  state() {
    return {
      schedules: null,
      items: null,
      taxes: null,
      translations: null,
      priceAdjustType: null,
      priceAdjustAmount: null
    };
  },
  getters: {
    translationStrings: (state, getters, rootState, rootGetters) => {
      const language = rootGetters.currentLanguage;

      return state.translations?.[language] || {};
    },
    currentLanguage: (state, getters, rootState, rootGetters) => {
      return rootGetters.currentLanguage;
    },
    schedules: (state, getters, rootState) => {
      const terminalSchedules = rootState.terminal?.terminalDetails?.config?.schedules;
      if (terminalSchedules && state.schedules) {
        // needs to filter on state.schedules - and include only those that are in the terminal config
        return state.schedules.filter(x => terminalSchedules.includes(x.ref));
      }
      return state.schedules;
    },
    items: (state, getters) => {
      if (getters.currentLanguage === 'en') {
        return state.items;
      }

      const translationStrings = getters.translationStrings;
      const applyTranslations = (item, translations) => {
        const translatedItem = { ...item };

        if (translations) {
          translatedItem.name = translations[item.name] || item.name;
          translatedItem.description = translations[item.description] || item.description;
        }

        translatedItem.modifierSets = item.modifierSets.map(modifierSet => ({
          ...modifierSet,
          name: translations[modifierSet.name] || modifierSet.name,
          modifiers: modifierSet.modifiers.map(modifier => ({
            ...modifier,
            name: translations[modifier.name] || modifier.name,
          })),
        }));

        return translatedItem;
      };

      if (!state.items) return [];

      return state.items.map(item => applyTranslations(item, translationStrings));
    },
    departments: (state, getters, rootState, rootGetters) => {
      const translationStrings = getters.translationStrings;
      const now = new Date();
      let openDepartments = getters.schedules?.filter(schedule =>
        isScheduleOpen(schedule, now)
      ).flatMap(schedule => schedule.departments) || [];

      const availableLastOrderedItems = rootGetters['customer/availableLastOrderedItems'];
      if (availableLastOrderedItems.length > 0) {
        const reorderDepartment = {
          ref: 'reorder',
          name: 'Order Again',
          items: availableLastOrderedItems.map(item => ({ ref: item.itemRef, lineModifierSets: item.lineModifierSets })),
        };
        openDepartments = [reorderDepartment, ...openDepartments];
      }

      return openDepartments.reduce((unique, department) => {
        const existingDepartment = unique.find(dept => dept.ref === department.ref);
        if (existingDepartment) {
          // Merge items if department already exists and ensure distinct items
          existingDepartment.items = [
            ...existingDepartment.items,
            ...department.items.filter(item => 
              !existingDepartment.items.some(existingItem => existingItem.ref === item.ref)
            )
          ];
        } else {
          // Add new department with translated name
          unique.push({
            ...department,
            name: translationStrings[department.name] || department.name,
          });
        }
        return unique;
      }, []);
    },
  },
  mutations: {
    SET_MENU(state, menu) {
      state.schedules = menu.schedules;
      state.items = menu.items.map(item => ({
        ...item,
        originalPrice: item.price,
        modifierSets: item.modifierSets.map(modifierSet => ({
          ...modifierSet,
          modifiers: modifierSet.modifiers.map(modifier => ({
            ...modifier,
            originalPrice: modifier.price,
          }))
        }))
      }));
      state.items = applyPriceAdjustments(state.items, state.priceAdjustType, state.priceAdjustAmount);
      state.taxes = menu.taxes;
      state.translations = menu.translations;
    },
    SET_STOCK(state, stock) {
      const stockStatusMap = stock.reduce((map, item) => {
        map[item.itemRef] = item.status;
        return map;
      }, {});
      if (state && state.items) {
        state.items = state.items.map((item) => ({
          ...item,
          stockStatus: stockStatusMap[item.ref] || "IN_STOCK",
        }));
      }
    },
    SET_PRICE_RULE(state, { type, amount }) {
      state.priceAdjustType = type;
      state.priceAdjustAmount = amount;
      if (state.items) {
        state.items = applyPriceAdjustments(state.items, type, amount);
      }
    }
  },
  actions: {
    setPriceRule({ commit }, { type, amount }) {
      console.log('setPriceRule', type, amount);
      commit("SET_PRICE_RULE", { type, amount });
    },
  },
};


export default {
  namespaced: true,
  state() {
    return {
      config: null,
      details: null,
      hasCoupons: false,
    };
  },
  getters: {
    config: (state) => state.config,
    orderTypes: (state, getters, rootState) => {
      const terminalOrderTypes = rootState.terminal?.terminalDetails?.config?.orderFlows;
      if (terminalOrderTypes) {
        // this needs to filter on state.config.orderTypes - and include only those that are in the terminal config
        return state.config?.orderTypes.filter(x => terminalOrderTypes.includes(x.id)).filter(x => x?.active) || [];
      }
      return state.config?.orderTypes.filter(x => x?.active) || [];
    },
    details: (state) => state.details,
    brandColor: (state) => state.config?.template?.brandColor || "#295aea",
    locationName: (state) => state.details?.name || '',
    hasCoupons: (state) => state.hasCoupons,
  },
  mutations: {
    SET_DETAILS(state, details) {
      state.details = details;
    },
    SET_CONFIG(state, config) {
      state.config = config;
    },
    SET_HAS_COUPONS(state, hasCoupons) {
      state.hasCoupons = hasCoupons;
    },
  },
  actions: {
  },
};

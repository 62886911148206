<template>
    <div class="settings-hold">
        <form @submit.prevent="saveSettings" v-if="config">
            <!-- Restart Terminals Button -->
            <div class="card mb-4" v-if="isAdmin">
                <div class="card-header">
                    <i class="fas fa-cogs"></i> Admin Actions
                </div>
                <div class="card-body">
                    <button type="button" class="btn btn-danger" @click="handleRestartTerminals">
                        Restart Terminals
                    </button>
                    <br /><br />
                    <div class="mb-3">
                        <label for="accessToken" class="form-label">Access Token</label>
                        <input type="text" class="form-control" id="accessToken" v-model="config.accessToken"
                            placeholder="Enter your access token" :aria-describedby="'accessTokenHelp'">
                        <small id="accessTokenHelp" class="form-text text-muted">Your gateway access token for secure
                            transactions.</small>
                    </div>

                    <!-- Dropdown for OLO and STM -->
                    <div class="mt-3">
                        <label for="railSelection" class="form-label">Select Rails</label>
                        <select id="railSelection" class="form-select" v-model="config.rails">
                            <option value="olo">OLO</option>
                            <option value="stm">STM</option>
                        </select>
                    </div>
                    <div v-if="!config.usesOnboard" class="mt-3">
                        <label for="pinPadLocation" class="form-label">Credit Payment Method</label>
                        <select id="pinPadLocation" class="form-select" v-model="config.pinPadLocation">
                            <option value="right">PIN Pad on Right</option>
                            <option value="left">PIN Pad on Left</option>
                            <option value="onboard">Onboard Reader</option>
                        </select>
                    </div>

                    <!-- Payment Engagement Settings -->
                    <div class="mt-3">
                        <label for="paymentEngagement" class="form-label">Payment Engagement Mode</label>
                        <select id="paymentEngagement" class="form-select" v-model="config.startPaymentInstantly">
                            <option :value="true">Engage Payment Device Instantly at Checkout</option>
                            <option :value="false">Show Pay Button</option>
                        </select>
                    </div>

                    <br />

                    <div class="form-check form-switch mb-3">
                        <input class="form-check-input" type="checkbox" v-model="config.rumSessions" id="rumSessions">
                        <label class="form-check-label" for="rumSessions"
                            v-tooltip="'Enable Datadog RUM sessions for enhanced troubleshooting'">Enable RUM
                            Sessions</label>
                    </div>
                </div>
            </div>

            <!-- General Settings -->
            <div class="card mb-4">
                <div class="card-header">
                    <i class="fas fa-sliders-h"></i> General Settings
                </div>
                <div class="card-body">
                    <!--<div class="mb-3">
                        <label for="loaderType" class="form-label" v-tooltip="'Choose the loader type'">Loader
                            Type</label>
                        <select v-model="config.loaderType" class="form-select" id="loaderType">
                            <option value="spinner">Spinner</option>
                            <option value="bar">Bar</option>
                        </select>
                    </div>-->
                    <div class="form-check form-switch mb-3">
                        <input class="form-check-input" type="checkbox" v-model="config.active" id="active">
                        <label class="form-check-label" for="active"
                            v-tooltip="'Toggle system activity status'">Active</label>
                    </div>
                    <div v-if="!config.active" class="mb-3">
                        <label for="deactivatedMessage" class="form-label"
                            v-tooltip="'Message displayed when system is inactive'">Deactivation Message</label>
                        <input type="text" class="form-control" id="deactivatedMessage"
                            v-model="config.text.deactivatedMessage"
                            :class="{ 'is-invalid': isDeactivationMessageInvalid }" required>
                        <div v-if="isDeactivationMessageInvalid" class="invalid-feedback">
                            Deactivation message is required when the system is inactive.
                        </div>
                    </div>
                </div>
            </div>

            <!-- Appearance Settings -->
            <div class="card mb-4">
                <div class="card-header">
                    <i class="fas fa-paint-brush"></i> Appearance Settings
                </div>
                <div class="card-body">
                    <div class="logo-upload-section mb-4">
                        <label for="logoUpload" class="form-label d-block" v-tooltip="'Upload a logo for the system'">
                            <strong>Location Logo</strong>
                        </label>
                        <div class="d-flex">
                            <div class="logo-preview-container d-flex flex-column align-items-center">
                                <location-logo v-if="logoPreview" :logo="logoPreview" name="Preview"
                                    :shape="config.logo?.shape || 'circle'" />
                                <location-logo v-else-if="config.logo && config?.logo?.url" :logo="config?.logo?.url"
                                    name="Preview" :shape="config?.logo?.shape || 'circle'" />
                            </div>
                            <div class="upload-controls-container d-flex flex-column flex-grow-1">
                                <div v-if="!logoPreview && !config.logo?.url" class="logo-upload d-flex flex-column">
                                    <input type="file" class="form-control mt-2" id="logoUpload"
                                        @change="handleLogoUpload" accept=".jpg,.jpeg,.png,.gif,.bmp" />
                                    <small class="form-text text-muted mt-2">
                                        Max file size: 5MB. Accepted formats: JPEG, PNG, GIF, BMP
                                    </small>
                                </div>
                                <div v-if="logoPreview || config.logo?.url" class="form-group mt-3">
                                    <label for="logoShape">Logo Shape</label>
                                    <select v-model="config.logo.shape" class="form-control" id="logoShape">
                                        <option value="circle">Circle</option>
                                        <option value="square">Square</option>
                                    </select>
                                </div>
                                <button v-if="logoPreview || config.logo?.url" type="button"
                                    class="btn btn-outline-danger btn-sm mt-3" @click="removeLogo">
                                    Delete Logo
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="brandColor" class="form-label" v-tooltip.bottom="'Select a brand color'">Brand
                            Color</label>
                        <div class="d-flex flex-wrap">
                            <div v-for="color in predefinedColors" :key="color.name" class="color-chip"
                                :style="{ backgroundColor: color.value }"
                                :class="{ 'selected': config.template.brandColor === color.value }"
                                @click="selectColor(color.value)" v-tooltip="color.name">
                            </div>
                        </div>
                        <br />
                        <input type="color" class="form-control form-control-color ml-2" id="brandColorPicker"
                            v-model="config.template.brandColor" @change="validateColor">
                    </div>
                    <!--
                    <div class="mb-3">
                        
                        <label for="templateStyle" class="form-label"
                            v-tooltip.bottom="'Select a template style'">Template
                            Style</label>
                        <select v-model="config.template.name" class="form-select" id="templateStyle">
                            <option value="default">Default</option>
                            <option value="sharp">Sharp Edges</option>
                        </select>
                    </div>
                    
                    <div class="form-check form-switch mb-3">
                        <input class="form-check-input" type="checkbox" v-model="config.darkMode" id="darkMode">
                        <label class="form-check-label" for="darkMode" v-tooltip="'Enable dark mode for the UI'">Dark
                            Mode</label>
                    </div>
                    -->
                    <div class="form-check form-switch mb-3">
                        <input class="form-check-input" type="checkbox" v-model="config.icons" id="icons">
                        <label class="form-check-label" for="icons" v-tooltip="'Use Icons in the system'">Use
                            Icons</label>
                    </div>
                </div>
            </div>

            <!-- Concepts Settings
            <div class="card mb-4">
                <div class="card-header">
                    <i class="fas fa-lightbulb"></i> Concepts
                </div>
                <div class="card-body">
                    <concepts-manager v-model="config.concepts" />
                </div>
            </div>
             -->

            <!-- Screensaver Settings -->
            <div class="card mb-4">
                <div class="card-header">
                    <i class="fas fa-tv"></i> Screensaver Settings
                </div>
                <div class="card-body">
                    <div class="form-check form-switch mb-3">
                        <input class="form-check-input" type="checkbox" v-model="config.screensaverEnabled"
                            id="screensaverEnabled">
                        <label class="form-check-label" for="screensaverEnabled"
                            v-tooltip="'Enable screensaver after inactivity'">Screensaver</label>
                    </div>
                    <div class="mb-3" v-if="config.screensaverEnabled">
                        <label for="screensaverBackgroundType" class="form-label"
                            v-tooltip="'Select the background type for the screensaver'">Screensaver Background
                            Type</label>
                        <select class="form-select" v-model="config.screensaverBackgroundType"
                            id="screensaverBackgroundType">
                            <option value="particles">Particles</option>
                            <option value="video">Video</option>
                        </select>
                    </div>
                    <div class="mb-3" v-if="config.screensaverEnabled && config.screensaverBackgroundType === 'video'">
                        <div v-if="isUploading" class="loading-indicator">
                            Uploading...
                        </div>
                        <div v-else>
                            <div v-if="config.screensaverVideoSource">
                                <video width="320" height="240" controls>
                                    <source :src="config.screensaverVideoSource" type="video/mp4">
                                    Your browser does not support the video tag.
                                </video>
                                <button class="btn btn-outline-danger btn-sm mt-2" @click="handleDeleteScreensaverVideo">Delete Video</button>
                            </div>
                            <div v-else>
                                <label for="screensaverVideoUpload" class="form-label">Upload Screensaver Video</label>
                                <input type="file" class="form-control" id="screensaverVideoUpload" @change="handleScreensaverVideoUpload" accept="video/mp4">
                                <small class="form-text text-muted">Max file size: 20MB. Accepted format: MP4</small>
                            </div>
                        </div>
                    </div>
                    <div class="form-check form-switch mb-3" v-if="config.screensaverEnabled">
                        <input class="form-check-input" type="checkbox" v-model="config.screensaverShowText"
                            id="screensaverShowText">
                        <label class="form-check-label" for="screensaverShowText"
                            v-tooltip="'Show text on screensaver'">Show Screensaver Text</label>
                    </div>
                </div>
            </div>

            <!-- Menu Settings 
            <div class="card mb-4">
                <div class="card-header">
                    <i class="fas fa-bars"></i> Menu Settings
                </div>
                <div class="card-body">
                    <div class="mb-3">
                        <label for="menu_cols" class="form-label"
                            v-tooltip="'Set the number of columns for menu display'">Menu Columns</label>
                        <input type="number" class="form-control" id="menu_cols" v-model.number="config.menu_cols">
                    </div>
                </div>
            </div>
            -->


            <!-- Language Support Settings -->
            <div class="card mb-4">
                <div class="card-header">
                    <i class="fas fa-language"></i> Language Settings
                </div>
                <div class="card-body">
                    <div class="form-check form-switch mb-3">
                        <input class="form-check-input" type="checkbox" v-model="config.languageSupport.enabled"
                            id="languageSupportEnabled">
                        <label class="form-check-label" for="languageSupportEnabled"
                            v-tooltip="'Toggle language support'">Enable Language Support</label>
                    </div>
                    <div v-if="config.languageSupport.enabled" class="mb-3">
                        <label class="form-label" v-tooltip="'Select supported languages'">Supported
                            Languages</label><br />
                        <div v-for="language in languageOptions" :key="language.key" class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" :id="language.key" :value="language.key"
                                v-model="config.languageSupport.supportedLanguages">
                            <label class="form-check-label" :for="language.key">{{ language.name }}</label>
                        </div>
                    </div>
                    <div v-if="config.languageSupport.enabled" class="mb-3">
                        <label for="defaultLanguage" class="form-label" v-tooltip="'Select default language'">Default Language</label>
                        <select id="defaultLanguage" class="form-select" v-model="config.languageSupport.defaultLanguage">
                            <option v-for="language in config.languageSupport.supportedLanguages" :key="language" :value="language">
                                {{ getLanguageName(language) }}
                            </option>
                        </select>
                    </div>
                    <div v-if="config.languageSupport.enabled && isAdmin" class="form-check form-switch mb-3">
                        <input class="form-check-input" type="checkbox" v-model="config.languageSupport.translateMenu"
                            id="translateMenu">
                        <label class="form-check-label" for="translateMenu"
                            v-tooltip="'Toggle menu translation'">Translate Menus</label>
                    </div>
                </div>
            </div>


            <!-- Order Settings -->
            <div class="card mb-4">
                <div class="card-header">
                    <i class="fas fa-receipt"></i> Order Settings
                </div>
                <div class="card-body">
                    <!-- Timeout Settings -->
                    <div class="mb-3">
                        <label for="idleTimeoutSeconds" class="form-label"
                            v-tooltip="'Set the idle timeout in seconds'">Idle Timeout (seconds)</label>
                        <div class="input-group">
                            <button class="btn btn-outline-secondary" type="button"
                                @click="changeTimeout(-10)">-</button>
                            <input type="number" class="form-control text-center"
                                v-model.number="config.idleTimeoutSeconds">
                            <button class="btn btn-outline-secondary" type="button"
                                @click="changeTimeout(10)">+</button>
                        </div>
                    </div>
                    <div class="form-check form-switch mb-3">
                        <input class="form-check-input" type="checkbox" v-model="config.allowSpecialRequests"
                            id="allowSpecialRequests">
                        <label class="form-check-label" for="allowSpecialRequests"
                            v-tooltip="'Allow customers to make special requests on items'">Allow Special
                            Requests</label>
                    </div>
                    <div class="form-check form-switch mb-3">
                        <input class="form-check-input" type="checkbox" v-model="config.orderSummaryShowModifiers"
                            id="orderSummaryShowModifiers">
                        <label class="form-check-label" for="orderSummaryShowModifiers"
                            v-tooltip="'Show modifiers in order summary'">Show Modifiers in Order Summary</label>
                    </div>
                    <div class="form-check form-switch mb-3">
                        <input class="form-check-input" type="checkbox" v-model="config.ratingPromptEnabled" id="ratingPromptEnabled">
                        <label class="form-check-label" for="ratingPromptEnabled" v-tooltip="'Enable rating prompt after order completion'">Ask Users to Rate Their Experience</label>
                    </div>
                    <div>
                        <!--
                        <div class="form-check form-switch mb-3">
                            <input class="form-check-input" type="checkbox" v-model="config.phoneLogin" id="phoneLogin">
                            <label class="form-check-label" for="phoneLogin"
                                v-tooltip="'Allow users to sign in with their phone number'">
                                Phone Login
                            </label>
                        </div>
                        <p class="text-muted">
                            When enabled, users will be able to sign in using their phone number. The system will
                            remember
                            their name, email, and previously ordered items for a more personalized experience.
                        </p>
-->
                    </div>
                </div>
            </div>

            <!-- Text Settings -->
            <div class="card mb-4">
                <div class="card-header">
                    <i class="fas fa-font"></i> Text Settings
                </div>
                <div class="card-body">

                    <div class="mb-3" v-for="(value, key) in config.text" :key="key">
                        <label :for="key" class="form-label">{{ toTitleCase(key.split(/(?=[A-Z])/).join(' ')) }}</label>
                        <input type="text" class="form-control" :id="key" v-model="config.text[key]"
                            @input="validateText(key)">
                        <div v-if="textErrors[key]" class="text-danger">{{ textErrors[key] }}</div>
                    </div>

                </div>
            </div>

        </form>
        <!-- Save Button -->
        <save-button :isDirty="isSettingsDirty" @save="saveSettings" :stickyBottom="true"
            :serverDone="serverDone"></save-button>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import SaveButton from '@/components/features/admin/SaveButton.vue';
import LocationLogo from '@/components/global/LocationLogo.vue';
import { toTitleCase } from '@/utils/helpers';
import { Tooltip } from 'bootstrap';
import tooltipDirective from '@/directives/tooltip';
//import ConceptsManager from './ConceptsComponent.vue';

export default {
    directives: {
        tooltip: tooltipDirective
    },
    components: {
        SaveButton,
        LocationLogo,
        //ConceptsManager,
    },
    computed: {
        ...mapState('admin', ['config']),
        ...mapGetters('admin', ['isAdmin']),
        isSettingsDirty() {
            return JSON.stringify(this.config) !== JSON.stringify(this.originalSettings) || this.logoPreview != null;
        },
        isDeactivationMessageInvalid() {
            return !this.config.active && !this.config.text.deactivatedMessage;
        }
    },
    data() {
        return {
            originalSettings: null,
            isDirty: false,
            logoPreview: null,
            logoUrl: '',
            logo: {
                url: null,
                shape: "circle"
            },
            predefinedColors: [
                { name: 'Black', value: '#232221' },
                { name: 'Shift4 Blue', value: '#0E5BF3' },
            ],
            languageSupport: {
                enabled: false,
                translateMenu: false,
                defaultLanguage: 'en',
                supportedLanguages: ['en', 'es', 'it', 'ko', 'fr', 'mr', 'zh', 'lt']
            },
            textErrors: {},
            serverDone: false,
            isUploading: false,
            languageOptions: [
                { key: 'en', name: 'English' },
                { key: 'es', name: 'Spanish' },
                { key: 'it', name: 'Italian' },
                { key: 'ko', name: 'Korean' },
                { key: 'fr', name: 'French' },
                { key: 'zh', name: 'Chinese' },
                { key: 'lt', name: 'Lithuanian' },
                { key: 'ja', name: 'Japanese' }
            ],
        };
    },
    methods: {
        ...mapActions('admin', ['fetchConfig', 'saveConfig', 'uploadLogo', 'deleteLogo', 'restartTerminals']),
        toTitleCase,
        hasValidationErrors() {
            return Object.values(this.textErrors).some(error => error);
        },
        validateText(key) {
            if (this.config.text[key].length > 200) {
                this.textErrors[key] = 'Text cannot exceed 200 characters.';
            } else {
                this.textErrors[key] = '';
            }
        },
        handleLogoUpload(event) {
            const file = event.target.files[0];
            if (file) {
                if (file.size > 5 * 1024 * 1024) {
                    alert('File size exceeds the 5MB limit. Please choose a smaller file.');
                    event.target.value = ''; // Clear the file input
                    return;
                }
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.logoPreview = e.target.result;
                    this.isSettingsDirty = true;
                };
                reader.readAsDataURL(file);
            }
        },
        async saveSettings() {
            // Validate text fields
            for (let key in this.config.text) {
                this.validateText(key);
            }

            // Check for validation errors
            if (this.hasValidationErrors() || this.isDeactivationMessageInvalid) {
                this.serverDone = true;
                alert("Please correct the validation errors before saving.");
                return;
            }

            // Upload the logo if needed
            this.serverDone = false;
            if (this.logoPreview && this.logoPreview.startsWith('data:')) {
                const base64Data = this.logoPreview.split(',')[1];
                const byteString = atob(base64Data);
                const arrayBuffer = new ArrayBuffer(byteString.length);
                const uint8Array = new Uint8Array(arrayBuffer);
                for (let i = 0; i < byteString.length; i++) {
                    uint8Array[i] = byteString.charCodeAt(i);
                }
                const blob = new Blob([uint8Array], { type: 'image/png' });
                const formData = new FormData();
                formData.append('file', blob, 'logo.png');

                try {
                    const response = await this.uploadLogo(formData);
                    this.config.logo.url = response.url;
                } catch (error) {
                    console.error("Failed to upload logo:", error);
                    this.serverDone = true;
                    alert("Failed to upload logo. Please try again.");
                    return;
                }
            } else if (this.logoUrl) {
                this.config.logo.url = this.logoUrl;
            }

            try {
                await this.saveConfig(this.config);
                this.originalSettings = JSON.parse(JSON.stringify(this.config));
                this.logoPreview = null;
                this.isSettingsDirty = false;
            } catch (error) {
                console.error("Failed to save settings:", error);
                alert("Failed to save settings. Please try again.");
            }

            this.serverDone = true;
        },
        applyLogoUrl() {
            this.logoPreview = this.logoUrl;
        },
        removeLogo() {
            this.logoPreview = null;
            this.config.logo.url = null;
            this.logoUrl = '';
        },
        selectColor(color) {
            this.config.template.brandColor = color;
            this.validateColor();
        },
        validateColor() {
            const isValid = checkContrast(this.config.template.brandColor);
            if (!isValid) {
                alert('Selected color does not meet ADA contrast guidelines against white. Please choose another color.');
            }
        },
        changeTimeout(val) {
            let newTimeout = this.config.idleTimeoutSeconds + val;
            if (newTimeout >= 0) {
                this.config.idleTimeoutSeconds = newTimeout;
            }
        },
        refreshTooltips() {
            const tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            tooltips.forEach(el => {
                const tooltipInstance = Tooltip.getInstance(el);
                if (tooltipInstance) {
                    tooltipInstance.dispose();
                }
                new Tooltip(el);
            });
        },
        handleRestartTerminals() {
            this.restartTerminals()
                .then(() => {
                    alert("Restart command sent to terminals successfully.");
                })
                .catch((error) => {
                    console.error("Failed to send restart command:", error);
                    alert("Failed to send restart command. Please try again.");
                });
        },
        async handleScreensaverVideoUpload(event) {
            const file = event.target.files[0];
            if (file) {
                if (file.size > 20 * 1024 * 1024) {
                    alert('File size exceeds the 20MB limit. Please choose a smaller file.');
                    event.target.value = ''; // Clear the file input
                    return;
                }
                const formData = new FormData();
                formData.append('file', file);

                this.isUploading = true;
                try {
                    const response = await this.$store.dispatch('admin/uploadVideo', formData);
                    this.config.screensaverVideoSource = response.url;
                    alert('Screensaver video uploaded successfully.');
                } catch (error) {
                    alert('Failed to upload screensaver video. Please try again.');
                } finally {
                    this.isUploading = false;
                }
            }
        },
        async handleDeleteScreensaverVideo() {
            if (this.config.screensaverVideoSource) {
                try {
                    await this.$store.dispatch('admin/deleteLogo', { url: this.config.screensaverVideoSource });
                    this.config.screensaverVideoSource = null;
                    alert('Screensaver video deleted successfully.');
                } catch (error) {
                    alert('Failed to delete screensaver video. Please try again.');
                }
            }
        },
        getLanguageName(languageKey) {
            const language = this.languageOptions.find(lang => lang.key === languageKey);
            return language ? language.name : languageKey;
        }
    },
    mounted() {
        const tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        tooltips.forEach(tooltip => {
            new Tooltip(tooltip);
        });
    },
    async created() {
        await this.fetchConfig();
        if (!this.config.logo) {
            this.config.logo = {
                url: null,
                shape: "circle"
            };
        }
        this.originalSettings = JSON.parse(JSON.stringify(this.config));
    },
    watch: {
        'config': {
            handler: 'refreshTooltips',
            deep: true,
            immediate: true
        }
    }
};

function hexToRgb(hex) {
    // Convert hex color to RGB
    let r = parseInt(hex.slice(1, 3), 16);
    let g = parseInt(hex.slice(3, 5), 16);
    let b = parseInt(hex.slice(5, 7), 16);
    return [r, g, b];
}

function sRGB(colorComponent) {
    // Convert color component to sRGB
    const component = colorComponent / 255;
    return component <= 0.03928 ? component / 12.92 : Math.pow((component + 0.055) / 1.055, 2.4);
}

function luminance(r, g, b) {
    // Calculate the relative luminance
    return 0.2126 * sRGB(r) + 0.7152 * sRGB(g) + 0.0722 * sRGB(b);
}

function checkContrast(colorHex, baseHex = '#FFFFFF') {
    const [r1, g1, b1] = hexToRgb(colorHex);
    const [r2, g2, b2] = hexToRgb(baseHex);

    const L1 = luminance(r1, g1, b1);
    const L2 = luminance(r2, g2, b2);

    const ratio = L1 > L2 ? (L1 + 0.05) / (L2 + 0.05) : (L2 + 0.05) / (L1 + 0.05);
    return ratio >= 4.5; // Correct ratio comparison for ADA compliance
}
</script>

<style scoped>
#receipt {
    max-width: 100% !important;
}

.logo-container {
    width: 100%;
    min-width: 20rem;
    min-height: 20rem;
    margin-right: 1rem;
}

.preview-button {
    border: none;
    border-radius: 100px;
    /* Rounded corners */
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

.color-chip {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 5px;
    cursor: pointer;
    border: 2px solid #fff;
}

.terminal-status {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
}

.connected {
    background-color: green;
}

.disconnected {
    background-color: grey;
}

.color-chip.selected {
    border: 3px solid white;
    box-shadow: 0 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}

.sticky-bar {
    position: sticky;
    bottom: 2rem;
    background-color: white;
    padding: 1rem;
    border-top: 1px solid #dee2e6;
    display: flex;
    justify-content: flex-end;
}

.loading-indicator {
    font-size: 16px;
    color: #007bff;
}
</style>





